define('coach-central/components/comment-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Component.extend({
    text: null, // the text of the comment
    originalText: null, // original text passed in, used to reset field on cancel
    save: null, // closure save action passed in from route/controller
    minlength: 2,
    maxlength: 2000,
    required: false,
    showCancelBtn: true,
    isShowing: true, // the form is visible by default
    comment: null, // its a new comment if no value is passed
    parent: null, // send a parent comment if its a new reply
    label: "",

    didInsertElement: function didInsertElement() {
      set(this, 'originalText', get(this, 'text'));
    },


    actions: {
      saveComment: function saveComment() {
        var text = get(this, 'text');
        if (text) {
          text = text.replace(/<\/?[^>]+(>|$)/g, ""); // strip html tags if present
        }
        if (!text) {
          return;
        }

        var parent = get(this, 'parent'); // a reply will have a parent comment

        // implement the action that saves
        if (get(this, 'save')) {
          var promise = get(this, 'save')(text, parent);
          promise.then(function () {}); // TODO: ?
          this.toggleProperty('isShowing');
        }
      },
      cancel: function cancel() {
        //this.toggleProperty('hidden');
        if (get(this, 'cancel')) {
          get(this, 'cancel')();
        }
        set(this, 'text', get(this, 'originalText'));
        this.toggleProperty('isShowing');
      }
    }
  });
});