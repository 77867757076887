define('coach-central/components/wellness-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({

    chartOptions: {
      responsive: true,
      title: {
        display: true,
        text: 'Wellness History'
      },
      tooltips: {
        mode: 'index',
        intersect: false
      },
      hover: {
        mode: 'nearest',
        intersect: true
      },
      scales: {
        xAxes: [{
          display: true,
          time: {
            unit: 'month'
          }
        }],
        yAxes: [{
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Value'
          }
        }]
      }
    },

    chartData: computed('data', function () {
      var data = this.get('data') || [];
      if (data.length) {
        var fill = false;

        return {
          labels: data.mapBy('date'),
          datasets: [{
            label: 'Sleep',
            backgroundColor: '#ff6384',
            borderColor: '#ff6384',
            fill: fill,
            data: data.mapBy('sleepQuality')
          }, {
            label: 'Energy',
            backgroundColor: '#00ff00',
            borderColor: '#00ff00',
            fill: fill,
            data: data.mapBy('fatigueLevel')
          }, {
            label: 'Muscle Soreness',
            backgroundColor: '#eeccdd',
            borderColor: '#eeccdd',
            fill: fill,
            data: data.mapBy('muscleSoreness')
          }, {
            label: 'Stress',
            backgroundColor: '#36a2eb',
            borderColor: '#36a2eb',
            fill: fill,
            data: data.mapBy('stressLevel')
          }] };
      }
      return false;
    })

  });
});