define('coach-central/instance-initializers/session-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance) {
    var applicationRoute = appInstance.lookup('route:application');
    var session = appInstance.lookup('service:session');

    session.on('authenticationSucceeded', function () {
      applicationRoute.refresh();
    });
  }

  exports.default = {
    name: 'session-events',
    after: 'ember-simple-auth',
    initialize: initialize
  };
});