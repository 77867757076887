define('coach-central/routes/admin/groups/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend({
    currentUser: service(),

    model: function model() {
      return get(this, 'currentUser.user.currentTeam');
      // return get(this, 'currentUser.user.currentTeam.client').then(client => {
      //   // previously we just did return get(client,'teams'); here
      //   // but now that we are looking up teams via client, a newly created team
      //   // is not found in list because it has no reference back to client
      //   // so I am hard reloading everything
      //   return get(this,'store').findRecord('client',client.id, {include: 'teams'})
      //     .then((client)=> {
      //       return get(client,'teams').then(teams => teams.sortBy('name'));
      //     });
      // });
    }
  });
});