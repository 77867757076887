define('coach-central/mirage/factories/team-group', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.random.word();
    },
    description: function description() {
      return _emberCliMirage.faker.lorem.sentence(5);
    }
  });
});