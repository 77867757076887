define('coach-central/components/form-team-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({
    editImage: false,
    cropImage: false,

    sportSelected: computed('changeset.sport.id', function () {
      return get(this, 'changeset.sport.id') ? true : false;
    }),
    noSportSelected: computed.not('sportSelected'),

    //model.saving seems to have no effect?
    saveDisabled: computed('changeset.isValid', 'changeset.isDirty', 'isSaving', 'model.isSaving', function () {
      if (get(this, 'changeset.isValid') && get(this, 'changeset.isDirty') && get(this, 'sportSelected') && !get(this, 'model.isSaving') && !get(this, 'isSaving')) {
        return false;
      }
      return true;
    }),

    actions: {
      toggleImage: function toggleImage() {
        this.toggleProperty('editImage');
        if (get(this, 'editImage')) {
          set(this, 'cropImage', false);
        }
      },
      toggleCrop: function toggleCrop() {
        this.toggleProperty('cropImage');
        if (get(this, 'cropImage')) {
          set(this, 'editImage', false);
          this.notifyPropertyChange('editImage');
        }
      }
    }
  });
});