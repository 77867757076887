define('coach-central/mirage/factories/redox-alert-report', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    stressStatus: function stressStatus() {
      var colors = ['grey', 'green', 'orange', 'red'];
      return colors[(0, _utils.randomInt)(1, 4) - 1];
    },
    defenceStatus: function defenceStatus() {
      var colors = ['grey', 'green', 'orange', 'red'];
      return colors[(0, _utils.randomInt)(1, 4) - 1];
    },


    //https://stackoverflow.com/questions/2390789/how-to-replace-all-dots-in-a-string-using-javascript
    //the backend returns one large string separated by :: (colon colon)
    //here we generate sentences then add the semi-colon to replicate the backend
    result: function result() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    },
    potentialOutcomes: function potentialOutcomes() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    },
    actions: function actions() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    },
    trainingLoadAdvice: function trainingLoadAdvice() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    },
    dietaryAdvice: function dietaryAdvice() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    },
    sleepAdvice: function sleepAdvice() {
      return _emberCliMirage.faker.lorem.sentences((0, _utils.randomInt)(1, 4)).replace(/\./g, '.::');
    }
  });
});