define('coach-central/components/user-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['squad_card', 'show_attribute'],
    classNameBindings: ['isSelected:selected'],

    user: null, // the athlete this card relates too

    // used in cases where cards can be selected in list
    isSelected: computed('user.selected', function () {
      return get(this, 'user.selected') || false;
    }),

    isCompleted: false // display tick mark instead of photo

  });
});