define('coach-central/mirage/models/redox-comment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    replies: (0, _emberCliMirage.hasMany)('redoxComment', { inverse: 'parent' }),
    parent: (0, _emberCliMirage.belongsTo)('redoxComment', { inverse: 'replies' }),
    //athlete: belongsTo('athlete'),
    redoxTest: (0, _emberCliMirage.belongsTo)('redoxTest'),
    commentBy: (0, _emberCliMirage.belongsTo)('user', { inverse: 'comments' })
  });
});