define('coach-central/mirage/factories/comment', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    /*----------- Associations --------------*/
    //athlete: association(),
    //user: association(),

    /*------------- Properties --------------*/
    text: function text() {
      return _emberCliMirage.faker.lorem.sentence(20);
    },
    createdAt: function createdAt() {
      return _emberCliMirage.faker.date.between('1/1/2017', '6/30/2017');
    },
    updatedAt: function updatedAt() {
      return _emberCliMirage.faker.date.between('1/1/2017', '6/30/2017');
    },
    isRemoved: function isRemoved() {
      return false;
    },
    commentByDisplayName: function commentByDisplayName() {
      return "";
    },


    /*-------------- Traits  ----------------*/
    //Creates Reflexive Relationship Data
    withReplies: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(comment, server) {
        server.createList('comment', 1, { 'parentId': comment.id });
      }
    })
  });
});