define('coach-central/components/redox-list-mode-chooser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed;
  exports.default = Component.extend({
    listMode: false, // field that controls the toggleProperty

    normalMode: computed.not('listMode'),

    actions: {
      handleClick: function handleClick() {
        this.toggleProperty('listMode');
      }
    }
  });
});