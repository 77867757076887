define('coach-central/utils/transition-helpers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.animationFor = animationFor;
  function animationFor(context, transition) {
    var name, args, func;
    if (Ember.isArray(transition)) {
      name = transition[0];
      args = transition.slice(1);
    } else {
      name = transition;
      args = [];
    }
    if (typeof name === 'function') {
      func = name;
    } else {
      func = context.lookup(name);
    }
    return function () {
      return func.apply(context, args);
    };
  }
});