define('coach-central/controllers/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Controller = Ember.Controller,
      service = Ember.inject.service;
  exports.default = Controller.extend({

    currentUser: service(),
    router: service(),
    currentRouteName: computed.reads('router.currentRouteName')

  });
});