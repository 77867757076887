define("coach-central/mirage/utils", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.randomNumber = randomNumber;
  exports.randomInt = randomInt;
  exports.randomBool = randomBool;
  exports.randomDate = randomDate;
  exports.randomElement = randomElement;
  /**
   * Returns a random number between min (inclusive) and max (exclusive)
   */
  function randomNumber(min, max) {
    return Math.random() * (max - min) + min;
  }

  /**
   * Returns a random integer between min (inclusive) and max (inclusive)
   * Using Math.round() will give you a non-uniform distribution!
   */
  function randomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  /**
   * Returns a random boolean
   */
  function randomBool() {
    return Math.random() >= 0.5;
  }

  /**
   * Returns a random date between start and end dates
   * Using Math.round() will give you a non-uniform distribution!
   *   e.g. randomDate(new Date(2012, 0, 1), new Date())
   */
  function randomDate(start, end) {
    var range = end.getTime() - start.getTime();
    return new Date(start.getTime() + Math.random() * range);
  }

  /**
   * Returns a random element from an array of values
   */
  function randomElement(list) {
    if (list.length) {
      return list[Math.floor(Math.random() * list.length)];
    }
    return null;
  }
});