define('coach-central/models/redox-test', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    //ATTRS
    date: attr('date'),
    createdAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    stress: attr('number'),
    stressThreshold: attr('number'),
    defence: attr('number'),
    defenceThreshold: attr('number'),
    timeKey: attr('number'),

    stressStatus: attr('string'),
    defenceStatus: attr('string'),

    //crp
    crp: attr('number'),
    crpThreshold: attr('number'),
    crpStatus: attr('string'),
    crpLowerThreshold: attr('number'),
    crpUpperThreshold: attr('number'),

    //ck
    ck: attr('number'),
    ckThreshold: attr('number'),
    ckStatus: attr('string'),
    ckLowerThreshold: attr('number'),
    ckUpperThreshold: attr('number'),

    includeInCdt: attr('boolean'),
    //isComplete: attr('boolean', { defaultValue() { return false; } }),

    //RELATIONSHIPS
    athlete: belongsTo('athlete'),
    comments: hasMany('comment'),
    redoxHealthSurvey: belongsTo('redoxHealthSurvey'), // TODO: This needs to change to some polymorphic event
    redoxCase: belongsTo('redoxCase'),
    redoxAlertReport: belongsTo('redoxAlertReport'),

    //COMPUTED
    hasBeenEdited: computed('createdAt', 'updatedAt', function () {
      return (0, _moment.default)(get(this, 'updatedAt')).isAfter(get(this, 'createdAt'), 'second');
    }),

    isComplete: computed('stress', 'defence', function () {
      var stress = get(this, 'stress');
      var defence = get(this, 'defence');
      if ((stress || defence) && !get(this, 'hasDirtyAttributes')) {
        return true;
      } else {
        return false;
      }
    })

  });
});