define('coach-central/components/nav-athlete-list', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['flex', 'layout-column', 'layout-align-center-center'],
    classNameBindings: ['active'],

    active: computed('currentAthlete', function () {
      return get(this, 'athlete.id') === get(this, 'currentAthlete.id');
    })
  });
});