define('coach-central/helpers/show-height', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.showHeight = showHeight;
  function showHeight(params, hash) {
    var height = params[0] || 'NaN';
    var units = hash.units || 'cm';
    var response = '',
        conversion = 0;

    if (isNaN(height)) {
      response = 'Unknown';
    } else {
      switch (units) {
        case 'cm':
          response = Math.round(height) + ' CM';
          break;
        case 'in':
          conversion = Math.round(parseInt(height) * 0.393700);
          response = conversion + '"';
          break;
        case 'ft':
          conversion = Math.round(parseInt(height) * 0.393700);
          response = Math.floor(conversion / 12) + '\' ' + conversion % 12 + '"';
          break;
        default:
          response = height + ' ' + units;
      }
    }
    return response;
  }

  exports.default = Ember.Helper.helper(showHeight);
});