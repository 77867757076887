define('coach-central/components/redox-data-form', ['exports', 'moment', 'coach-central/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var set = Ember.set,
      get = Ember.get,
      Component = Ember.Component,
      computed = Ember.computed,
      service = Ember.inject.service,
      $ = Ember.$;
  exports.default = Component.extend({

    currentUser: service(),

    HOUR_ARRAY: _constants.HOUR_ARRAY,
    MIN_ARRAY: _constants.MIN_ARRAY,

    isChangingDate: false,
    carotIcon: "keyboard-arrow-down",

    commentToDisplay: computed('comments', function () {
      return get(this, 'comments').get('firstObject');
    }),

    selectedHour: computed('', function () {
      return get(this, 'redoxTest.date').getUTCHours().toString();
    }),

    selectedMin: computed('', function () {
      return get(this, 'redoxTest.date').getUTCMinutes().toString();
    }),

    redoxTestDate: computed('', function () {
      return _moment.default.utc(get(this, 'redoxTest.date')).format('DD MMM YYYY');
    }),

    hasDateChanged: false,

    classNames: ['layout-column'],
    redoxTest: null, // redox test for the component
    isSavingTest: false, // boolean to show loading spinner

    canSave: computed('redoxTest.hasDirtyAttributes', 'redoxTest.isSaving', 'redoxTest.stress', 'redoxTest.defence', 'redoxTest.crp', 'redoxTest.ck', 'selectedMin', 'selectedHour', function () {
      return get(this, 'redoxTest.hasDirtyAttributes') && (get(this, 'redoxTest.stress') || get(this, 'redoxTest.defence') || get(this, 'redoxTest.crp') || get(this, 'redoxTest.ck')) && !get(this, 'redoxTest').get('isSaving');
    }),

    refreshAthlete: function refreshAthlete(redoxTest) {
      redoxTest.get('athlete').then(function (athlete) {
        return athlete.reload();
      });
    },
    refreshTests: function refreshTests(redoxTest) {
      //Get the athlete of the redox test patched and refresh his tests
      redoxTest.get('athlete').then(function (athlete) {
        var redoxTests = athlete.hasMany('redoxTests');
        redoxTests.reload();
      });
    },
    refreshRedoxCase: function refreshRedoxCase(redoxCase) {
      redoxCase.reload();
    },
    getRandomInt: function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      set(this, 'savedComment', null);
    },


    actions: {
      save: function save(redoxTest, redoxCase) {
        var _this = this;

        try {
          set(redoxTest, 'updatedAt', new Date());

          if (get(this, 'hasDateChanged')) {
            get(redoxTest, 'date').setUTCHours(get(this, 'selectedHour'));
            get(redoxTest, 'date').setUTCMinutes(get(this, 'selectedMin'));
            get(redoxTest, 'date').setUTCSeconds(this.getRandomInt(60));
            get(redoxTest, 'date').setUTCMilliseconds(this.getRandomInt(1000));
          }

          set(this, 'isSavingAnyTest', true);
          set(this, 'isSavingTest', true);
          redoxTest.save().then(function (redoxTest) {
            set(_this, 'isSavingAnyTest', false);
            set(_this, 'isSavingTest', false);
            _this.refreshAthlete(redoxTest);
            _this.refreshTests(redoxTest);
            _this.refreshRedoxCase(redoxCase);
            var msg = 'Update successful';
            _this.sendAction('showApplicationToast', msg);
          }).catch(function () {
            set(_this, 'isSavingAnyTest', false);
            set(_this, 'isSavingTest', false);
            var msg = 'Update failed! The test has not been saved.';
            _this.sendAction('showApplicationToast', msg);
          });

          //not needed
        } catch (error) {
          this.displayErrorPage(error);
        }
      },
      backToTests: function backToTests() {
        set(this, 'showNewCommentForm', false);
        set(this, 'savedComment', null);
      },
      toggleIsChangingDate: function toggleIsChangingDate() {
        this.toggleProperty('isChangingDate');
        if (get(this, 'isChangingDate')) {
          set(this, 'carotIcon', 'keyboard-arrow-up');
        } else {
          set(this, 'carotIcon', 'keyboard-arrow-down');
        }
      },
      updateRedoxTestDate: function updateRedoxTestDate(selectedDate) {
        // NOTE: there is an issue with paper-pikaday when using utc times so
        // I used 'redoxTestDate' to display the date and 'redoxTest.date' when saving the date
        set(this, 'redoxTestDate', _moment.default.utc(selectedDate).format('DD MMM YYYY')); //set date to display
        set(this, 'redoxTest.date', _moment.default.utc(selectedDate).toDate()); // set date on redoxTest
        set(this, 'hasDateChanged', true);
      },
      updateHourHandler: function updateHourHandler(hour) {
        set(this, 'selectedHour', hour);
        var updatedDate = _moment.default.utc(get(this, 'redoxTest.date').setUTCHours(hour)).toDate();
        set(this, 'redoxTest.date', updatedDate);
        set(this, 'hasDateChanged', true);
      },
      updateMinHandler: function updateMinHandler(min) {
        set(this, 'selectedMin', min);
        var updatedDate = _moment.default.utc(get(this, 'redoxTest.date').setUTCMinutes(min)).toDate();
        set(this, 'redoxTest.date', updatedDate);
        set(this, 'hasDateChanged', true);
      }
    }
  });
});