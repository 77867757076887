define('coach-central/mirage/models/team', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    sport: (0, _emberCliMirage.belongsTo)('sport'),
    users: (0, _emberCliMirage.hasMany)('user', { inverse: 'teams' }),
    athletes: (0, _emberCliMirage.hasMany)('athlete'),
    teamGroups: (0, _emberCliMirage.hasMany)('teamGroup', { inverse: 'team' }),
    redoxCases: (0, _emberCliMirage.hasMany)('redoxCase'),
    client: (0, _emberCliMirage.belongsTo)('client')
  });
});