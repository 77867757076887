define('coach-central/components/bar-athlete-header-details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$,
      run = Ember.run;
  exports.default = Ember.Component.extend({
    elementId: 'bar_athlete_header_details',
    classNameBindings: ['fixed'],
    fixed: false,
    topPos: 0,

    onWindowScroll: function onWindowScroll(e) {
      var scroll = $(e.currentTarget).scrollTop();
      var topPos = this.get('topPos');
      if (this.get('layoutInvalidated') || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      if (scroll > topPos) {
        this.set('fixed', true);
      } else {
        this.set('fixed', false);
      }
      if (scroll === 0) {
        this.set('fixed', false);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('layoutInvalidated') || this.get('isDestroyed') || this.get('isDestroying')) {
        return;
      }
      this._windowScroll = run.bind(this, 'onWindowScroll');
      $(window).on('scroll', this._windowScroll);
      this.set('topPos', this.$('.sticky-header').offset().top);
    },
    willRemoveElement: function willRemoveElement() {
      $(window).off('scroll', this._windowScroll);
      this._super.apply(this, arguments);
    }
  });
});