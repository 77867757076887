define('coach-central/mirage/factories/query', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    page: function page(i) {
      return i;
    },
    queryText: function queryText() {
      return _emberCliMirage.faker.lorem.sentences(1);
    },
    relatedHeading: function relatedHeading() {
      return _emberCliMirage.faker.lorem.sentences(1);
    }
  });
});