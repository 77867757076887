define('coach-central/controllers/admin/groups/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set;
  exports.default = Controller.extend({
    currentUser: service(),

    isSaving: false,

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        set(this, 'isSaving', true);
        return changeset.save().then(function () {
          set(_this, 'isSaving', false);
          _this.send('showApplicationToast', "Group created");
          _this.transitionToRoute('admin.groups.index');
        }).catch(function () {
          set(_this, 'isSaving', false);
          get(_this, 'model.errors').forEach(function (_ref) {
            var attribute = _ref.attribute,
                message = _ref.message;

            changeset.pushErrors(attribute, message);
          });
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.send('showApplicationToast', "Changes have been undone.");
        return true;
      },
      cancel: function cancel() {
        this.send('showApplicationToast', "Creation has been cancelled.");
        this.transitionToRoute('admin.groups.index');
      }
    }
  });
});