define('coach-central/mirage/factories/client', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    organisation: function organisation() {
      _emberCliMirage.faker.company.companyName();
    },
    name: function name() {
      _emberCliMirage.faker.company.companyName();
    },
    clientCode: function clientCode() {
      _emberCliMirage.faker.company.companySuffix();
    }
  });
});