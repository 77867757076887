define('coach-central/components/photo-cropper', ['exports', 'ember-cli-image-cropper/components/image-cropper'], function (exports, _imageCropper) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _imageCropper.default.extend({
    //override default options of cropper
    aspectRatio: 1,
    minCropBoxWidth: 100,
    minCropBoxHeight: 100,
    cropperContainer: '.cropper-container > img',
    previewClass: '.img-preview',
    croppedphoto: null,
    checkCrossOrigin: true,
    crossOrigin: 'anonymous',

    actions: {
      getCroppedphoto: function getCroppedphoto() {
        var container = this.$(this.get('cropperContainer'));
        var croppedImage = container.cropper('getCroppedCanvas', {
          fillColor: 'white' });
        this.set('croppedphoto', croppedImage);

        var save = this.get('save');

        croppedImage.toBlob(function (blob) {
          //A Blob() is almost a File() - it's just missing the
          // two properties below which we will add
          blob.lastModifiedDate = new Date();
          blob.name = 'upload.png';
          save(blob);
        });
      }
    }
  });
});