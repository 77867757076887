define('coach-central/models/redox-comment', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Model.extend({
    //ATTRS
    createdAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    isRemoved: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    text: attr('string'),

    //RELATIONSHIPS
    commentBy: belongsTo('user'),
    //athlete: belongsTo('athlete'),
    redoxTest: belongsTo('redoxTest'),
    parent: belongsTo('redoxComment', { inverse: 'replies' }),
    replies: hasMany('redoxComment', { inverse: 'parent' }),

    //COMPUTED
    hasBeenEdited: computed('createdAt', 'updatedAt', function () {
      return (0, _moment.default)(get(this, 'updatedAt')).isAfter(get(this, 'createdAt'), 'second');
    }),

    isTopLevelComment: computed('parent', function () {
      return get(this, 'parent.id') === undefined ? true : false;
    }),

    numReplies: computed('replies', function () {
      return get(this, 'replies.length');
    })
  });
});