define('coach-central/controllers/dashboard/team', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      Controller = Ember.Controller,
      _Ember$inject = Ember.inject,
      service = _Ember$inject.service,
      controller = _Ember$inject.controller;
  exports.default = Controller.extend({
    router: service(),
    application: controller(),
    currentUser: service(),
    currentRoute: computed.alias('router.currentRouteName'),
    leftSideBarOpen: computed.reads('application.leftSideBarOpen'),
    searchedAthletes: null,

    //close athlete-sidebar when application-sidebar is open
    // or when in athlete index route (ie. team dashboard)
    athleteSideBarLockedOpen: computed('leftSideBarOpen', 'currentRoute', function () {
      var currentRoute = get(this, 'currentRoute');
      var leftSideBarOpen = get(this, 'leftSideBarOpen');
      var isTeamIndexRoute = void 0;
      if (currentRoute === 'dashboard.team.index' || currentRoute === 'dashboard.team.loading') {
        isTeamIndexRoute = true;
      }
      return !leftSideBarOpen && !isTeamIndexRoute;
    }),

    // currently selected team in filter - stored in currentUser service
    teamGroup: computed.alias('currentUser.teamGroup'),

    // teamController: controller('dashboard.team'),
    // team: computed.alias('teamController.model'),

    // list of team categories
    teamGroupList: computed.alias('model.teamGroups'),

    athletes: computed('teamGroup', 'model.athletes', function () {
      var teamGroup = get(this, 'teamGroup');
      var athletes = get(this, 'model.athletes');
      var teamGroupList = get(this, 'teamGroupList').mapBy('name');

      if (teamGroupList.includes(teamGroup)) {
        athletes = get(this, 'model.teamGroups').filterBy('name', teamGroup).get('firstObject').get('athletes');
      }

      switch (teamGroup) {
        case 'Injured':
          athletes = athletes.filterBy('isInjured', true);
          break;
      }
      return athletes;
    }),

    //sortProps: ['latestTestComplete:desc', 'latestRedoxRiskGroup:asc'],
    sortProps: ['firstName:asc', 'lastName:asc'],
    sortedAthletes: computed.sort('athletes', 'sortProps'),

    filteredAthletes: computed('sortedAthletes', 'searchedAthletes', function () {
      return get(this, 'athleteString') ? get(this, 'searchedAthletes') : get(this, 'sortedAthletes');
    }),

    actions: {
      searchAthlete: function searchAthlete(athleteString) {
        var searchedAthletes = [];
        var currentAthlete = void 0,
            currentAthletePosition = void 0;

        if (athleteString) {
          //to prevent firing on enter press
          athleteString = athleteString.toLowerCase();
          var athletes = this.get('sortedAthletes');
          athletes.forEach(function (athlete) {
            var fName = athlete.get('firstName').toLowerCase();
            var lName = athlete.get('lastName').toLowerCase();
            //full name doesnt always exist
            var name = fName + " " + lName;
            var position = name.search(athleteString);
            if (position !== -1 && athleteString) {
              searchedAthletes.push(athlete);

              if (!Number.isInteger(currentAthletePosition) || position < currentAthletePosition) {
                currentAthlete = athlete;
                currentAthletePosition = position;
              }
            }
          });
        }
        this.set('currentSearchAthlete', currentAthlete);
        this.set('searchedAthletes', searchedAthletes);
      },
      selectSearchedAthlete: function selectSearchedAthlete() {
        var athlete = get(this, 'currentSearchAthlete');
        if (athlete) {
          this.send('showAthlete', athlete);
        }
      },
      selectAthlete: function selectAthlete(athlete) {
        if (athlete) {
          this.send('showAthlete', athlete);
        }
      }
    }

  });
});