define('coach-central/routes/dashboard/team/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service,
      RSVP = Ember.RSVP,
      get = Ember.get;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {

    currentUser: service(),

    model: function model() {
      return RSVP.hash({
        team: get(this, 'currentUser.user.currentTeam'),
        teamGroups: get(this, 'currentUser.user.currentTeam.teamGroups'),
        athletes: get(this, 'currentUser.user.currentTeam.athletes'),
        redoxCases: get(this, 'currentUser.user.currentTeam.redoxCases'),
        comments: get(this, 'store').findAll('comment', { reload: true })
      });
    },
    afterModel: function afterModel() {
      //load all of an athletes tests
      get(this, 'store').findAll('redoxTest', { reload: true });
    },


    actions: {
      goToCommentTest: function goToCommentTest(team, comment) {
        var athlete = comment.get('athlete');
        var redoxTest = comment.get('redoxTest');
        this.transitionTo('dashboard.team.athlete.redox', team, athlete, {
          queryParams: {
            test_id: redoxTest.get('id')
          }
        });
      }
    }
  });
});