define('coach-central/helpers/display-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Helper = Ember.Helper,
      service = Ember.inject.service;
  exports.default = Helper.extend({
    moment: service(),

    compute: function compute(params, hash) {
      // if the date is undefined set it to null
      // isValid() trtuens true when date is undefined for some reason
      var date = params[0] === undefined ? null : params[0];
      var moment = this.get('moment');
      if (moment.moment(date, moment.ISO_8601).isValid()) {
        return '' + moment.moment(date).format(moment.defaultFormat);
      }
      if (hash.noDateText) {
        return '' + hash.noDateText;
      }
      return 'No Date';
    }
  });
});