define('coach-central/mirage/factories/athlete', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    dob: function dob() {
      return _emberCliMirage.faker.date.between('1/1/1980', '1/1/1990');
    },
    photo: function photo() {
      return _emberCliMirage.faker.internet.avatar();
    },
    isInjured: function isInjured() {
      return Math.random() >= 0.7;
    },
    weight: function weight() {
      // between 70 and 110 KG
      return (0, _utils.randomNumber)(70, 110);
    },
    height: function height() {
      // random number between 170 and 210CM
      return (0, _utils.randomNumber)(170, 210);
    },
    trackorAthleteCode: function trackorAthleteCode() {
      return 'TOR-001';
    },


    //latest values
    latestRedoxRiskGroup: function latestRedoxRiskGroup() {
      //number between 1 & 4 inclusive
      return (0, _utils.randomInt)(1, 4);
    },
    latestDefenceStatus: function latestDefenceStatus() {
      var colors = ['GREEN', 'AMBER', 'RED'];
      return colors[(0, _utils.randomInt)(0, 2)];
    },
    latestStressStatus: function latestStressStatus() {
      var colors = ['GREEN', 'AMBER', 'RED'];
      return colors[(0, _utils.randomInt)(0, 2)];
    },
    latestTestComplete: function latestTestComplete() {
      return _emberCliMirage.faker.date.between('1/1/2016', '1/1/2018');
    },


    withPosition: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(athlete, server) {
        server.create('position', { athlete: athlete });
      }
    }),

    withRedoxTests: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(athlete, server) {
        server.createList('redoxTest', 10, 'withHealthSurvey', { athlete: athlete });
      }
    }),

    withHooperSurveys: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(athlete, server) {
        server.createList('hooperSurvey', 10, { athlete: athlete });
      }
    })

  });
});