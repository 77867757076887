define('coach-central/models/redox-alert-report', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    //ATTRS
    stressStatus: attr('string'),
    defenceStatus: attr('string'),
    result: attr('stringArray'),
    potentialOutcomes: attr('stringArray'),
    actions: attr('stringArray'),
    trainingLoadAdvice: attr('stringArray'),
    dietaryAdvice: attr('stringArray'),
    sleepAdvice: attr('stringArray'),

    //RELATIONSHIPS
    redoxTest: belongsTo('redoxTest')

    //COMPUTED

  });
});