define('coach-central/components/search-watson', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Component = Ember.Component,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    watsonQuery: service(),
    watsonQuestion: "",

    isLoading: false,
    query: null,

    categories: computed('model', function () {
      var queries = get(this, 'model');
      return queries.mapBy('related-heading').uniq().map(function (query) {
        return { 'name': query, data: queries.filterBy('related-heading', query) };
      });
    }),

    actions: {
      queryWatson: function queryWatson(query) {
        var _this = this;

        get(this, 'watsonQuery').queryWatson(query).then(function (response) {
          set(_this, 'watsonQuestion', query);
          set(_this, 'watsonResponse', response);
        });
      }
    }
  });
});