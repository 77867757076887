define('coach-central/helpers/is-active', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isActive = isActive;
  var Helper = Ember.Helper;
  function isActive(params /*, hash*/) {
    var routeName = params[0];
    var activeRoute = params[1];
    return activeRoute === routeName;
  }

  exports.default = Helper.helper(isActive);
});