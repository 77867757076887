define('coach-central/components/status-redox-pill', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    tagName: 'span',
    classNames: ['status_redox'],
    classNameBindings: ['big', 'paperList:paper-list-status-pill'],
    big: false,
    paperList: false,
    stress: null,
    defence: null,

    _getColour: function _getColour(val) {
      val = val.toLowerCase();
      var acceptableColor = ['red', 'orange', 'green'];

      if (val === 'red+') {
        return 'redPlus';
      };
      if (val === 'amber') {
        return 'orange';
      } //amber is returned by the backend but orange is the color
      return acceptableColor.includes(val) ? val : "unknown";
    },


    crpVal: computed('crp', function () {
      return this._getColour(get(this, 'crp') || 'unknown');
    }),

    ckVal: computed('ck', function () {
      return this._getColour(get(this, 'ck') || 'unknown');
    }),

    stressVal: computed('stress', function () {
      return this._getColour(get(this, 'stress') || 'unknown');
    }),

    defenceVal: computed('defence', function () {
      return this._getColour(get(this, 'defence') || 'unknown');
    })
  });
});