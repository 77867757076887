define('coach-central/components/chartjs-donut', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Ember.Component.extend({

    options: {
      cutoutPercentage: 65,
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: true,
        position: 'top',
        labels: {
          usePointStyle: true,
          fontColor: '#000',
          fontSize: 14
        }
      },
      elements: {
        arc: {
          borderWidth: 8
        }
      },
      tooltips: {
        enabled: false,
        callbacks: {
          title: function title(tooltipItem, data) {
            return data['labels'][tooltipItem[0]['index']];
          },
          label: function label(tooltipItem, data) {
            return data.players[tooltipItem.index];
          }
        },
        backgroundColor: '#e4f0ff',
        titleFontSize: 16,
        titleFontColor: '#002047',
        bodyFontColor: '#002047',
        bodyFontSize: 14,
        displayColors: false,

        //create custom tooltip
        custom: function custom(tooltipModel) {
          var tooltipEl = document.getElementById('chartjs-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }
          // Set Text
          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);
            var innerHtml = '<thead>';

            titleLines.forEach(function (title) {
              innerHtml += "<span class='donut-tooltip-title'>" + title + '</span><br>';
            });

            var players = bodyLines[0];
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
              for (var _iterator = players[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                var player = _step.value;

                innerHtml += player + '<br>';
              }
            } catch (err) {
              _didIteratorError = true;
              _iteratorError = err;
            } finally {
              try {
                if (!_iteratorNormalCompletion && _iterator.return) {
                  _iterator.return();
                }
              } finally {
                if (_didIteratorError) {
                  throw _iteratorError;
                }
              }
            }

            var tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();
          // Display, position, and set styles for font
          var leftOffset = window.outerWidth < 959 ? 180 : 250;
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + leftOffset + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + 15 + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      }
    },

    doughnutData: computed('currentDoughnutView', 'model.team.athletes.@each.latestRedoxRiskGroup', function () {
      var biomarker = get(this, 'biomarker');
      var athletes = get(this, 'model.team.athletes');
      var isRedox = get(this, 'isRedox');
      var date = get(this, 'date');
      var dateCk = get(this, 'dateCk');

      var counts = [];
      var goodCount = 0,
          monitorCount = 0,
          priorityCount = 0;
      var goodList = [],
          monitorList = [],
          priorityList = [];

      var countsCk = [];
      var goodCountCk = 0,
          monitorCountCk = 0,
          priorityCountCk = 0;
      var goodListCk = [],
          monitorListCk = [],
          priorityListCk = [];

      //also filter by the date
      athletes.forEach(function (athlete) {
        var latestDate = isRedox ? athlete.get('latestTestComplete') : athlete.get('latestCrpTestComplete');
        var latestDateCk = athlete.get('latestCkTestComplete');
        if (latestDate && date && moment(latestDate).isSame(moment(date), 'day')) {
          var group = isRedox ? athlete.get('latestRedoxRiskGroup') : athlete.get('latestCrpStatus');
          var groupCk = athlete.get('latestCkStatus');
          var name = athlete.get('fullName');

          if (group === 1 || group === 'RED') {
            priorityCount++;
            priorityList.push(name);
          } else if (group === 2 || group === 'AMBER') {
            monitorCount++;
            monitorList.push(name);
          } else if (group === 3 || group === 'GREEN') {
            goodCount++;
            goodList.push(name);
          }
        }

        if (latestDateCk && dateCk && moment(latestDateCk).isSame(moment(dateCk), 'day')) {

          var _groupCk = athlete.get('latestCkStatus');
          var nameCk = athlete.get('fullName');

          if (_groupCk === 1 || _groupCk === 'RED' || _groupCk === 'RED+') {
            priorityCountCk++;
            priorityListCk.push(nameCk);
          } else if (_groupCk === 2 || _groupCk === 'AMBER') {
            monitorCountCk++;
            monitorListCk.push(nameCk);
          } else if (_groupCk === 3 || _groupCk === 'GREEN') {
            goodCountCk++;
            goodListCk.push(nameCk);
          }
        }
      });

      var finalData = biomarker === "CRP" || isRedox ? {
        count: goodCount + monitorCount + priorityCount,
        players: [goodList, monitorList, priorityList],
        labels: ['Good', 'Monitor', 'Priority'],
        datasets: [{
          data: [goodCount, monitorCount, priorityCount],
          backgroundColor: ["#41d691", "#ff9933", "#ef3340"],
          hoverBackgroundColor: ["#41d691", "#ff9933", "#ef3340"],
          hoverBorderWidth: 0
        }]
      } : {
        count: goodCountCk + monitorCountCk + priorityCountCk,
        players: [goodListCk, monitorListCk, priorityListCk],
        labels: ['Good', 'Monitor', 'Priority'],
        datasets: [{
          data: [goodCountCk, monitorCountCk, priorityCountCk],
          backgroundColor: ["#41d691", "#ff9933", "#ef3340"],
          hoverBackgroundColor: ["#41d691", "#ff9933", "#ef3340"],
          hoverBorderWidth: 0
        }]
      };

      return finalData;
    }),

    //////////////////////////
    // computed properties //
    ////////////////////////

    dateString: computed('date', function () {
      return moment(get(this, 'date')).format('DD MMM YYYY');
    }),

    dateStringCk: computed('dateCk', function () {
      return moment(get(this, 'dateCk')).format('DD MMM YYYY');
    }),

    showData: computed('doughnutData', function () {
      var doughnutData = get(this, 'doughnutData');
      return doughnutData.count ? true : false;
    }),

    //////////////////////
    // destroy tooltip //
    ////////////////////

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $('#chartjs-tooltip').remove(); //when using side scroll - could remain on page transitioned to
    }
  });
});