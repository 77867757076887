define('coach-central/components/form-athlete-edit', ['exports', 'coach-central/utils/conversions', 'coach-central/utils/constants'], function (exports, _conversions, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      getWithDefault = Ember.getWithDefault;
  exports.default = Component.extend({

    GENDERS: _constants.GENDER_LIST,
    ETHNICS: _constants.ETHNIC_LIST,

    editImage: false,
    cropImage: false,

    //tracks if teams have been added/removed
    isRelationshipChanged: false,

    //no. of currently selected teams
    numOfTeams: computed.alias('changeset.teams.length'),

    // the unit label to display when showing height field
    heightLabel: computed('usersettings.heightUnitPref', function () {
      var pref = getWithDefault(this, 'usersettings.heightUnitPref', '');
      if (pref.toLowerCase() === 'cm') {
        return 'Height (CM)';
      }
      return 'Height (inches)';
    }),

    height: computed('changeset.height', 'usersettings.heightUnitPref', function () {
      var height = getWithDefault(this, 'changeset.height', 0);
      var unit = getWithDefault(this, 'usersettings.heightUnitPref', 'cm');
      set(this, 'heightChanged', true); // set here to dirty changeset
      if (unit === 'cm') {
        return height;
      }
      return (0, _conversions.cmToIn)(height);
    }),

    weight: computed('changeset.weight', 'usersettings.weightUnitPref', function () {
      var weight = getWithDefault(this, 'changeset.weight', 0);
      var unit = getWithDefault(this, 'usersettings.weightUnitPref', 'kg');
      set(this, 'weightChanged', true); // set here to dirty changeset
      if (unit === 'kg') {
        return weight;
      }
      return (0, _conversions.kgToLbs)(weight);
    }),

    weightLabel: computed('usersettings.weightUnitPref', function () {
      var pref = getWithDefault(this, 'usersettings.weightUnitPref', '');
      if (pref.toLowerCase() === 'kg') {
        return 'Weight (KG)';
      }
      return 'Weight in pounds (lbs)';
    }),
    // disable the Save button until valid changes have been made
    // or while a save is in progress
    saveDisabled: computed('changeset.isValid', 'changeset.isDirty', 'heightChanged', 'weightChanged', 'model.athlete.isSaving', 'isRelationshipChanged', 'numOfTeams', 'isSaving', function () {
      if (get(this, 'changeset.isValid') && (get(this, 'changeset.isDirty') || get(this, 'isRelationshipChanged') || get(this, 'heightChanged') || get(this, 'weightChanged')) && !get(this, 'model.athlete.isSaving') && get(this, 'numOfTeams') > 0 && !get(this, 'isSaving')) {
        return false;
      }
      return true;
    }),

    actions: {
      updateSelection: function updateSelection() {
        this.set('isRelationshipChanged', true);
      },

      updateDob: function updateDob(dobString) {
        var dobObj = new Date(dobString);
        set(this, 'changeset.dob', dobObj);
      },
      toggleImage: function toggleImage() {
        this.toggleProperty('editImage');
        if (get(this, 'editImage')) {
          set(this, 'cropImage', false);
        }
      },
      toggleCrop: function toggleCrop() {
        this.toggleProperty('cropImage');
        if (get(this, 'cropImage')) {
          set(this, 'editImage', false);
          this.notifyPropertyChange('editImage');
        }
      },
      saveAthlete: function saveAthlete() {}
    }
  });
});