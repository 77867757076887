define('coach-central/controllers/dashboard/team/athlete', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      Controller = Ember.Controller,
      _Ember$inject = Ember.inject,
      service = _Ember$inject.service,
      controller = _Ember$inject.controller,
      get = Ember.get;
  exports.default = Controller.extend({

    router: service(),
    currentUser: service(),
    team: controller('dashboard.team'),
    athletes: computed.alias('team.athletes'),

    currentRouteName: computed('router.currentRouteName', function () {
      var currentRoute = get(this, 'router.currentRouteName');
      // help paper-tabs in athlete.hbs to highlight current tab.
      if (currentRoute.indexOf('dashboard.team.athlete.redox') != -1) {
        return 'athlete.redox';
      }
      return currentRoute;
    }),

    actions: {
      previousAthlete: function previousAthlete() {
        return this.advanceAthlete(-1);
      },

      nextAthlete: function nextAthlete() {
        return this.advanceAthlete(1);
      },

      // remove tooltip when scrolling
      removeTooltip: function removeTooltip() {
        var tooltipEl = document.getElementById('d3-tip');
        if (tooltipEl && tooltipEl.style.opacity !== 0) {
          tooltipEl.style.opacity = 0;
        }
      }
    },

    advanceAthlete: function advanceAthlete(delta) {
      var athletes = this.get('athletes');
      var length = athletes.get('length');
      var index = (athletes.indexOf(this.get('model')) + delta + length) % length;
      return this.send('showAthlete', athletes.objectAt(index));
    }
  });
});