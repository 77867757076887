define('coach-central/mirage/factories/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    firstName: function firstName() {
      return _emberCliMirage.faker.name.firstName();
    },
    lastName: function lastName() {
      return _emberCliMirage.faker.name.lastName();
    },
    displayName: function displayName() {
      return this.firstName + ' ' + this.lastName;
    },
    email: function email() {
      return _emberCliMirage.faker.internet.email();
    },
    secondaryEmail: function secondaryEmail() {
      return _emberCliMirage.faker.internet.email();
    },
    avatar: function avatar() {
      return _emberCliMirage.faker.internet.avatar();
    },
    isVendor: function isVendor() {
      return false;
    },
    currentClient: function currentClient() {
      _emberCliMirage.faker.company.companyName();
    },
    fullName: function fullName() {
      return this.firstName + " " + this.lastName;
    },


    withUserSettings: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(user, server) {
        server.create('userSetting', { user: user });
      }
    })
  });
});