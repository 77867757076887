define('coach-central/components/ember-chart-extended', ['exports', 'ember-cli-chart/components/ember-chart'], function (exports, _emberChart) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberChart.default.extend({
        //  click(e) {
        //let activeSlice = this.get('chart').getElementAtEvent(e) || [];
        //let groupLabel = activeSlice[0]._model.label;
        //if (activeSlice.length) {
        //Uncomment the following line when the chart filters athletes again.
        //this.updateSquadView(groupLabel);
        //}
        //  }
    });
});