define('coach-central/controllers/redox-cases/redox-case/redox-test', ['exports', 'coach-central/validations/health-survey'], function (exports, _healthSurvey) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set;
  exports.default = Ember.Controller.extend({
    HealthSurveyValidations: _healthSurvey.default,

    isSaving: false,

    refreshRedoxCase: function refreshRedoxCase(redoxTest) {
      redoxTest.get('redoxCase').then(function (redoxCase) {
        return redoxCase.reload();
      });
    },


    actions: {
      save: function save(changeset, redoxTest) {
        var _this = this;

        var snapshot = changeset.snapshot();
        return changeset
        //.cast(keys(schema))
        .validate().then(function () {
          if (get(changeset, 'isValid')) {
            set(_this, 'isSaving', true);
            set(changeset, 'isComplete', true);
            changeset.save().then(function () {
              set(_this, 'isSaving', false);
              _this.refreshRedoxCase(redoxTest);
              _this.transitionToRoute('redox-cases.redox-case', _this.get('model.redoxCase.id'));
            }).catch(function () {
              set(_this, 'isSaving', false);
            });
          }
        }).catch(function () {
          changeset.restore(snapshot);
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.transitionToRoute('redox-cases.redox-case', this.get('model.redoxCase'));
      }
    }
  });
});