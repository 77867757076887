define('coach-central/mirage/models/user', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    comments: (0, _emberCliMirage.hasMany)('comment'),
    userSettings: (0, _emberCliMirage.belongsTo)('userSetting'),
    role: (0, _emberCliMirage.belongsTo)('role'),
    teams: (0, _emberCliMirage.hasMany)('team', { inverse: 'users' }),
    currentTeam: (0, _emberCliMirage.belongsTo)('team', { inverse: null })
  });
});