define('coach-central/routes/redox-cases', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUser: service(),

    model: function model() {
      return this.get('currentUser.user.currentTeam.redoxCases');
    }
  });
});