define('coach-central/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      computed = Ember.computed,
      set = Ember.set;
  exports.default = Ember.Controller.extend({
    // < bootstrap settings>
    fluid: true,
    inverse: true,
    position: 'fixed-top',
    // </ bootstrap settings>

    //simple auth session
    session: service(),
    currentUser: service(),
    watsonQuery: service(),

    watsonQuestion: null,
    watsonResponse: null,

    init: function init() {
      this._super.apply(this, arguments);
      // var date = new Date();
      // window.cordova.plugins.notification.local.schedule({
      //   id: 1,
      //   title: "Message Title",
      //   message: "Message Text",
      //   firstAt: date, // firstAt and at properties must be an IETF-compliant RFC 2822 timestamp
      //   every: 1, // this also could be minutes i.e. 25 (int)
      //   icon: "http://icons.com/?cal_id=1",
      //   data: { meetingId:"123#fg8" }
      // });
    },


    hideNavRoutes: ['login', 'forgot-password', 'reset-password'],
    // property to hide navbar in login route
    hideNav: computed('currentRouteName', function () {
      var currentRouteName = get(this, 'currentRouteName');
      var hideNavRoutes = get(this, 'hideNavRoutes');
      return hideNavRoutes.includes(currentRouteName) ? true : false;
    }),

    actions: {
      changeQuery: function changeQuery(query) {
        var _this = this;

        if (query) {
          get(this, 'watsonQuery').queryWatson(query).then(function (response) {
            set(_this, 'watsonQuestion', query);
            set(_this, 'watsonResponse', response);
            set(_this, 'rightSideBarOpen', true);
          });
        } else {
          set(this, 'rightSideBarOpen', true);
        }
      },
      showWatson: function showWatson() {
        set(this, 'rightSideBarOpen', true);
      },
      invalidateSession: function invalidateSession() {
        if (get(this, 'session.isAuthenticated')) {
          this.get('session').invalidate();
        }
      }
    }
  });
});