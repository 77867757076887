define('coach-central/components/comment-reply-widget', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    comment: null,
    showReplies: true,

    icon: computed('showReplies', function () {
      var showReplies = get(this, 'showReplies');
      if (showReplies) {
        return 'keyboard-arrow-up';
      }
      return 'keyboard-arrow-down';
    }),

    text: computed('comment.numReplies', 'showReplies', function () {
      var numReplies = get(this, 'comment.numReplies') || 0;
      var showReplies = get(this, 'showReplies');
      var text = '';

      text = showReplies ? 'Hide ' : 'Show ';
      text += numReplies;
      text += numReplies > 1 ? ' replies' : ' reply';

      return text;
    }),

    actions: {
      toggleShowReplies: function toggleShowReplies() {
        this.toggleProperty('showReplies');
      }
    }

  });
});