define('coach-central/models/team', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    //ATTRS
    name: attr('string'),
    homeVenue: attr('string'),
    logo: attr('string'),

    //RELATIONSHIPS
    sport: belongsTo('sport'),
    users: hasMany('user', { inverse: 'teams' }),
    athletes: hasMany('athlete'),
    teamGroups: hasMany('teamGroup', { inverse: 'team' }),
    redoxCases: hasMany('redoxCase'),
    client: belongsTo('client')

    //COMPUTED

  });
});