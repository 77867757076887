define('coach-central/transitions/concurrent', ['exports', 'liquid-fire', 'coach-central/utils/transition-helpers'], function (exports, _liquidFire, _transitionHelpers) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = concurrent;


  // `concurrent` is not, by itself, an animation. It exists to run two or more
  // transitions concurrently.

  function concurrent() {
    var _this = this;

    for (var _len = arguments.length, transitions = Array(_len), _key = 0; _key < _len; _key++) {
      transitions[_key] = arguments[_key];
    }

    return _liquidFire.Promise.all(transitions.map(function (transition) {
      return runAnimation(_this, transition);
    }));
  }

  function runAnimation(context, transition) {
    return (0, _transitionHelpers.animationFor)(context, transition).call();
  }
});