define('coach-central/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/athlete.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/athlete.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/hooper-survey.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/hooper-survey.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/position.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/position.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/query.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/query.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-alert-report.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-alert-report.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-case.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-case.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-health-survey.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-health-survey.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-risk-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-risk-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/redox-test.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/redox-test.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sport.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sport.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/team-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/team-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/team.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/athlete.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/athlete.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/client.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/hooper-survey.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/hooper-survey.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/position.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/position.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/query.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/query.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-alert-report.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-alert-report.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-case.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-case.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-comment.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-comment.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-health-survey.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-health-survey.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-risk-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-risk-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/redox-test.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/redox-test.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/role.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/role.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sport.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sport.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/team-group.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/team-group.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/team.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user-setting.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user-setting.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/query.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/query.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/utils.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/utils.js should pass ESLint\n\n');
  });
});