define('coach-central/utils/constants', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GENDER_LIST = exports.GENDER_LIST = ['Male', 'Female', 'Not Specified'];

  var ETHNIC_LIST = exports.ETHNIC_LIST = ['Not Specified'];

  // Leave the 0 as a string - the dropdown position is all off otherwise
  var HOUR_ARRAY = exports.HOUR_ARRAY = ["0", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  var MIN_ARRAY = exports.MIN_ARRAY = ["0", 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];
});