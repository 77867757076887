define('coach-central/controllers/dashboard/team/index', ['exports', 'ember-data', 'coach-central/config/environment', 'moment'], function (exports, _emberData, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      Controller = Ember.Controller,
      service = Ember.inject.service;
  exports.default = Controller.extend({

    // toggle to show the mirage database in the comments
    DEBUG: _environment.default.debug,
    currentDoughnutView: 'priority',
    currentUser: service(),

    showRedoxWidget: computed('currentRedoxDate', function () {
      return get(this, 'currentRedoxDate') ? true : false;
    }),
    hasCRP: computed('currentCrpDate', function () {
      return get(this, 'currentCrpDate') ? true : false;
    }),
    hasCK: computed('currentCrpDate', function () {
      return get(this, 'currentCkDate') ? true : false;
    }),
    showCkCrpWidget: computed('hasCRP', 'hasCK', function () {
      var hasCK = get(this, 'hasCK');
      var hasCRP = get(this, 'hasCRP');
      return hasCK || hasCRP;
    }),
    showCommentWidget: computed('filteredComments', function () {
      return get(this, 'filteredComments').filter(function (d) {
        return !d.get('isRemoved');
      }).length === 0 ? false : true;
    }),

    // currently selected team in filter - stored in currentUser service
    teamGroup: computed.alias('currentUser.teamGroup'),

    // list of team categories
    teamGroupList: computed.alias('model.team.teamGroups'),

    anyInjuredPlayers: computed('model.team.athletes', function () {
      return get(this, 'model.team.athletes').isAny('isInjured', true);
    }),

    anyGroups: computed('model.teamGroups.@each.athletes', 'filteredGroups', function () {
      var teamGroups = get(this, 'model.team.teamGroups');
      var groupHasAthletes = get(this, 'filteredGroups').length;
      return groupHasAthletes || get(this, 'anyInjuredPlayers') ? true : false;
    }),

    filteredGroups: computed('model.teamGroups.@each.athletes', 'anyGroups', function () {
      var teamGroups = get(this, 'model.teamGroups');
      var filteredGroups = [];
      teamGroups.forEach(function (g) {
        if (g.get('athletes.firstObject.id')) {
          filteredGroups.push(g);
        }
      });
      return filteredGroups;
    }),

    filteredComments: computed('model.@each.athletes', 'model.comments', function () {
      var currentTeamAthletes = get(this, 'model.athletes').mapBy('id');
      var comments = get(this, 'model.comments');
      return comments.filter(function (comment) {
        return currentTeamAthletes.includes(get(comment, 'athlete.id'));
      }).sortBy('updatedAt').reverse().filterBy('isRemoved', false).slice(0, 10);
    }),

    athletes: computed('teamGroup', 'model.team.athletes', function () {
      var teamGroup = get(this, 'teamGroup');
      var athletes = get(this, 'model.team.athletes');
      var teamGroupList = get(this, 'teamGroupList').mapBy('name');

      if (teamGroupList.includes(teamGroup)) {
        athletes = get(this, 'model.team.teamGroups').filterBy('name', teamGroup).get('firstObject').get('athletes');
      }

      switch (teamGroup) {
        case 'Injured':
          athletes = athletes.filterBy('isInjured', true);
          break;
      }
      return athletes;
    }),

    sortProps: ['latestTestComplete:desc', 'latestRedoxRiskGroup:asc'],
    sortedAthletes: computed.sort('athletes', 'sortProps'),

    atRiskAthletes: computed('model.team.athletes.@each.latestRedoxRiskGroup', 'model.team.athletes.@each.latestTestComplete', function () {
      var athletes = get(this, 'model.team.athletes');
      var latestTestDate = athletes.sortBy('latestTestComplete').get('lastObject.latestTestComplete');
      var rangeDate = (0, _moment.default)(latestTestDate).subtract(30, 'days');

      var athletesTestedInRange = athletes.filter(function (athlete) {
        var athletesLastTest = (0, _moment.default)(athlete.get('latestTestComplete'));
        return athletesLastTest.isAfter(rangeDate);
      });

      return athletesTestedInRange.sortBy('latestRedoxRiskGroup', 'firstName', 'lastName').slice(0, 5);
    }),

    /*---------------------- Healthcheck Donut Dates ---------------------------------*/

    selectedBasicTab: 0,

    //should have date and string version?
    currentRedoxDate: computed('model', function () {
      var athletes = get(this, 'model.athletes');
      var dates = athletes.mapBy('latestTestComplete').filter(function (d) {
        return d;
      });
      var mostRecent = dates.sort(function (a, b) {
        return (0, _moment.default)(b) - (0, _moment.default)(a);
      })[0];
      return mostRecent; //moment(mostRecent).format('DD MMM YYYY');
    }),

    currentCrpDate: computed('model', function () {
      var athletes = get(this, 'model.athletes');
      var dates = athletes.mapBy('latestCrpTestComplete').filter(function (d) {
        return d;
      });
      var mostRecent = dates.sort(function (a, b) {
        return (0, _moment.default)(b) - (0, _moment.default)(a);
      })[0];
      return mostRecent;
    }),

    currentCkDate: computed('model', function () {
      var athletes = get(this, 'model.athletes');
      var dates = athletes.mapBy('latestCkTestComplete').filter(function (d) {
        return d;
      });
      var mostRecent = dates.sort(function (a, b) {
        return (0, _moment.default)(b) - (0, _moment.default)(a);
      })[0];
      return mostRecent; //moment(mostRecent).format('DD MMM YYYY');
    }),

    /*----------------------------- Actions ----------------------------------*/
    actions: {
      updateCategory: function updateCategory(newCategory) {
        newCategory === get(this, 'teamGroup') ? set(this, 'teamGroup', 'All') : set(this, 'teamGroup', newCategory);
      },
      removeTooltip: function removeTooltip() {
        var tooltipEl = document.getElementById('chartjs-tooltip');
        if (tooltipEl && tooltipEl.style.opacity !== 0) {
          tooltipEl.style.opacity = 0;
        }
      }
    }

  });
});