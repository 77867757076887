define('coach-central/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'coach-central/config/environment'], function (exports, _applicationRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service,
      Logger = Ember.Logger,
      $ = Ember.$;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    currentUser: service(),
    memoryStorage: service(),
    paperToaster: service(),
    session: service(),

    // NB: Do not remove commented line below, its used in cordova builds.
    // splashScreenService: service('ember-cordova/splash'),

    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    model: function model() {
      var token = get(this, 'session.data.authenticated.access_token');
      if (token) {
        var API_URL = [_environment.default.JSONAPIAdapter.host, '/', _environment.default.JSONAPIAdapter.namespace, '/queries'].join('');

        var queryData = $.ajax(API_URL, {
          method: "GET",
          headers: {
            'Authorization': 'Bearer ' + token
          },
          contentType: 'application/json',
          success: function success() {},
          error: function error(e) {
            // TODO: handle error
            Logger.log('Upload error', e);
          }
        });

        return queryData.then(function (response) {
          return response;
        });
      } else {
        return [];
      }
    },
    afterModel: function afterModel() {
      // NB: Do not remove line below
      //this.get('splashScreenService').hide();
    },


    // see https://github.com/simplabs/ember-simple-auth/blob/master/guides/managing-current-user.md
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return get(_this, 'session').invalidate();
      });
    },


    actions: {
      back: function back() {
        history.back();
      },


      error: function error(_error, transition) {
        if (_error.isAdapterError) {

          if (_error.message === "The adapter operation was aborted") {
            console.log('adapter error - offline');
            this.send('showApplicationToast', "An error occurred - most likely you are offline.", 5000);
            this.replaceWith('errorPage');
            return false;
          }

          // this is a workaround for server not setting CORS
          // header when returning a 401 on an adapter operation.
          // triggered by user logging out of a different session
          // and then trying an adapter operation in the current session.
          console.log('adapter error - login');
          this.send('showApplicationToast', "An error occurred - most likely you have logged out elsewhere.", 5000);
          this.replaceWith('login');
          return false;
        } else {
          console.log('other error');
          transition.abort(); //abort transition, so it can be retried later

          //save transition to storage
          this.get('memoryStorage').set('failedTransition', transition);
          return true; //return true to display an error page that we created
        }
      },

      showApplicationToast: function showApplicationToast(msg, duration) {
        duration = duration || 2000; //default 2s
        this.get('paperToaster').show(msg, { duration: duration });
      }
    }

  });
});