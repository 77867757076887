define('coach-central/helpers/show-weight', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.showWeight = showWeight;
  function showWeight(params, hash) {

    var weight = params[0] || 'NaN';
    var units = hash.units || 'kilograms';
    var response = '';
    var conversion = 0;

    if (isNaN(weight)) {
      response = 'Unknown';
    } else {
      switch (units) {
        case 'kg':
          response = Math.round(weight) + ' KG';
          break;
        case 'lb':
          conversion = Math.round(parseInt(weight) * 2.2046);
          response = conversion + ' lbs';
          break;
        case 'st':
          conversion = Math.round(parseInt(weight) * 2.2046);
          response = Math.floor(conversion / 14) + 'st ' + conversion % 14 + 'lbs';
          break;
        default:
          response = weight + ' ' + units;
      }
    }
    return response;
  }

  exports.default = Ember.Helper.helper(showWeight);
});