define('coach-central/routes/admin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUser: service(),

    beforeModel: function beforeModel(transition) {
      // this route is only for admins
      this._super.apply(this, arguments);
      if (!get(this, 'currentUser.isClientAdmin')) {
        transition.abort();
      }
    }
  });
});