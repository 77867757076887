define('coach-central/models/athlete', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  exports.default = Model.extend({
    //ATTRS
    firstName: attr('string'),
    lastName: attr('string'),
    dob: attr('date'),
    photo: attr('string'),
    ethnicity: attr('string'),
    gender: attr('string'),

    isInjured: attr('boolean'),
    isIll: attr('boolean'),
    weight: attr('number'),
    height: attr('number'),
    trackorAthleteCode: attr('string'),

    // latest values
    latestRedoxRiskGroup: attr('number'),
    latestDefenceStatus: attr('string'),
    latestStressStatus: attr('string'),
    latestTestComplete: attr('date'),

    //add crp
    latestCrpStatus: attr('string'),
    latestCrpTestComplete: attr('date'),
    //  latestCrp

    //CK
    latestCkStatus: attr('string'),
    latestCkTestComplete: attr('date'),

    //RELATIONSHIPS
    teams: hasMany('team'),
    position: belongsTo('position'),
    teamGroups: hasMany('teamGroup'),
    redoxTests: hasMany('redoxTest'),
    hooperSurveys: hasMany('hooperSurvey'),
    comments: hasMany('comment'),

    //COMPUTED
    fullName: computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName');
      var lastName = this.get('lastName');

      return firstName + ' ' + lastName;
    })

  });
});