define('coach-central/controllers/redox-cases/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    // Newest cases should appear at the top
    sortedCases: computed('model.[]', 'model.@each.redoxCaseDate', function () {
      return get(this, 'model').toArray().sortBy('redoxCaseDate').reverse();
    })
  });
});