define('coach-central/components/mirage-db', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed;
  exports.default = Ember.Component.extend({
    showDb: false,
    db: computed(function () {
      var dump = window.server.schema.db.dump();
      return JSON.stringify(dump, null, 2);
    }),

    actions: {
      toggleDb: function toggleDb() {
        this.toggleProperty('showDb');
      }
    }
  });
});