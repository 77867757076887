define('coach-central/components/paper-link', ['exports', 'ember-paper-link/components/paper-link'], function (exports, _paperLink) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperLink.default;
    }
  });
});