define('coach-central/transforms/string-array', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized.split('::').filter(function (el) {
        return el.length != 0;
      });
    },


    //No need to serialize as the the redoxAlertReports attrs are read only.
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});