define('coach-central/mirage/serializers/query', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    serialize: function serialize() {
      // return an array [...] and not an object { data: [...]} for eatson queries
      // as the backend doesn't follow json api spec with this call
      var json = _emberCliMirage.Serializer.prototype.serialize.apply(this, arguments);
      return json.data;
    }
  });
});