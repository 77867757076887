define('coach-central/routes/redox-cases/redox-case', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get;
  exports.default = Route.extend({
    model: function model(params) {
      return get(this, 'store').findRecord('redoxCase', params.redox_case_id);
    },
    afterModel: function afterModel(model /*,transition*/) {
      if (!model) {
        this.transitionTo('redox-cases');
      }
    },


    actions: {
      showRedoxTest: function showRedoxTest(test_id) {
        this.transitionTo('redox-cases.redox-case.redox-test', test_id);
      }
    }
  });
});