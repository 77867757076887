define("coach-central/components/password-strength-meter", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var computed = Ember.computed;
    exports.default = Ember.Component.extend({
        barColour: function barColour(passwordScore) {
            if (passwordScore < 2) {
                return "#ef3340";
            } else if (passwordScore === 2) {
                return "#ff9933";
            } else if (passwordScore === 3) {
                return '#98FB98';
            } else if (passwordScore === 4) {
                return "#41d691";
            }
        },


        chartData: computed('passwordScore', function () {
            var passwordScore = this.get('passwordScore');
            if (passwordScore === 0) {
                passwordScore = 1;
            }
            var barColour = this.barColour(passwordScore);
            return {
                labels: ['Password'],

                datasets: [{
                    label: 'Strength',
                    backgroundColor: barColour,
                    data: [passwordScore]
                }]
            };
        }),

        chartOptions: computed('passwordScore', function () {
            return {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                },
                scales: {
                    yAxes: [{
                        display: false,
                        gridLines: {
                            drawBorder: false
                        }
                    }],
                    xAxes: [{
                        display: false,
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            beginAtZero: true,
                            stepValue: 1,
                            max: 4
                        }
                    }]
                },

                animation: {
                    duration: 2000,
                    easing: 'linear'
                }
            };
        })
    });
});