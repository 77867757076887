define('coach-central/components/health-survey-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['layout-row', 'flex-100'],
    iconSize: 2,

    didRender: function didRender() {
      this._super.apply(this, arguments);
      window.location.hash = '#top-of-page';
    },


    actions: {
      rollback: function rollback() {
        this.attrs.rollback(this.get('changeset'));
      },
      save: function save() {
        this.attrs.save(this.get('changeset'), this.get('redoxTest'));
      },
      increaseFont: function increaseFont(size) {
        var iconSize = this.get('iconSize');
        iconSize += size;
        if (iconSize < 1) {
          iconSize = 1;
        }
        if (iconSize > 5) {
          iconSize = 5;
        }
        this.set('iconSize', iconSize);
      }
    }

  });
});