define('coach-central/models/comment', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      attr = _emberData.default.attr;
  exports.default = _emberData.default.Model.extend({
    //ATTRS
    createdAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    isRemoved: attr('boolean', {
      defaultValue: function defaultValue() {
        return false;
      }
    }),
    text: attr('string'),
    commentByDisplayName: attr('string'),
    //RELATIONSHIPS
    commentBy: belongsTo('user'),
    athlete: belongsTo('athlete'),
    redoxTest: belongsTo('redoxTest' /*,{async: false}*/),
    parent: belongsTo('comment', { inverse: 'replies' }),
    replies: hasMany('comment', { inverse: 'parent' }),

    //COMPUTED
    hasBeenEdited: Ember.computed('createdAt', 'updatedAt', function () {
      return (0, _moment.default)(Ember.get(this, 'updatedAt')).isAfter(Ember.get(this, 'createdAt'), 'second');
    }),

    isTopLevelComment: Ember.computed('parent', function () {
      return Ember.get(this, 'parent.id') === undefined ? true : false;
    }),

    numReplies: Ember.computed('replies', function () {
      return Ember.get(this, 'replies.length');
    })
  });
});