define('coach-central/routes/redox-cases/redox-case/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Ember.Route.extend({

    currentUser: service(),

    model: function model() {
      return RSVP.hash({
        case: this.modelFor('redoxCases.redoxCase'),
        athletes: get(this, 'currentUser.user.currentTeam.athletes')
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('submitRedoxCaseEdit.isRunning')) {
          this.controller.set('preventLeavingMsg', 'Please wait while the case is saving.');
          transition.abort();
        } else {
          return true;
        }
      }
    }
  });
});