define('coach-central/controllers/dashboard/team/athlete/redox/test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      _Ember$inject = Ember.inject,
      service = _Ember$inject.service,
      controller = _Ember$inject.controller;
  exports.default = Controller.extend({
    currentUser: service(),
    athlete: controller('dashboard.team.athlete'),
    showNewCommentForm: false, // toggles "New Comment" Button/Form
    disableComments: false,
    isSavingComment: false,

    actions: {
      createComment: function createComment(text, parent) {
        var _this = this;

        //set(this, 'isSavingComment', true);
        var comment = get(this, 'store').createRecord('comment', {
          commentBy: get(this, 'currentUser.user'),
          athlete: get(this, 'athlete.model'),
          redoxTest: get(this, 'model'),
          text: text,
          parent: parent
        });

        return comment.save()
        // .then(() => {
        //   set(this, 'isSavingComment', false);
        //   this.send('showApplicationToast', 'Comment created')
        // })
        .catch(function () {
          //set(this, 'isSavingComment', false);
          _this.send('showApplicationToast', 'Failed to create comment!');
        });
      }
    }
  });
});