define('coach-central/mirage/factories/redox-health-survey', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    date: function date() {
      return new Date();
    },
    isComplete: function isComplete() {
      return (0, _utils.randomBool)();
    },
    exercised: function exercised() {
      return (0, _utils.randomBool)();
    },
    eaten: function eaten() {
      return (0, _utils.randomBool)();
    },
    prevExerciseGym: function prevExerciseGym() {
      return (0, _utils.randomBool)();
    },
    prevExerciseTraining: function prevExerciseTraining() {
      return (0, _utils.randomBool)();
    },
    prevExerciseGame: function prevExerciseGame() {
      return (0, _utils.randomBool)();
    },
    prevExerciseNone: function prevExerciseNone() {
      return (0, _utils.randomBool)();
    },
    prevExerciseOther: function prevExerciseOther() {
      return '';
    },
    energyLevel: function energyLevel() {
      return (0, _utils.randomNumber)(1, 5);
    },
    muscleSoreness: function muscleSoreness() {
      return (0, _utils.randomNumber)(1, 5);
    },
    feverToday: function feverToday() {
      return (0, _utils.randomBool)();
    },
    feverPrev: function feverPrev() {
      return (0, _utils.randomBool)();
    },
    soreThroatToday: function soreThroatToday() {
      return (0, _utils.randomBool)();
    },
    soreThroatPrev: function soreThroatPrev() {
      return (0, _utils.randomBool)();
    },
    headacheToday: function headacheToday() {
      return (0, _utils.randomBool)();
    },
    headachePrev: function headachePrev() {
      return (0, _utils.randomBool)();
    },
    acheToday: function acheToday() {
      return (0, _utils.randomBool)();
    },
    achePrev: function achePrev() {
      return (0, _utils.randomBool)();
    },
    diarrheaToday: function diarrheaToday() {
      return (0, _utils.randomBool)();
    },
    diarrheaPrev: function diarrheaPrev() {
      return (0, _utils.randomBool)();
    },
    otherSymptom: function otherSymptom() {
      return '';
    }
  });
});