define('coach-central/routes/admin/groups/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP,
      service = Ember.inject.service;
  exports.default = Route.extend({
    currentUser: service(),

    model: function model() {
      var _this = this;

      var store = get(this, 'store');
      return RSVP.hash({
        teamGroup: store.createRecord('teamGroup', {
          team: get(this, 'currentUser.user.currentTeam') }),
        // teams: get(this, 'currentUser.user.currentTeam.client').then(client => {
        //   return get(client,'teams');
        // })
        teams: get(this, 'currentUser.user.currentTeam.client').then(function (client) {
          // previously we just did return get(client,'teams'); here
          // but now that we are looking up teams via client, a newly created team
          // is not found in list because it has no reference back to client
          // so I am hard reloading everything
          return get(_this, 'store').findRecord('client', client.id, { include: 'teams' }).then(function (client) {
            return get(client, 'teams');
          });
        })
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('model.teamGroup.isNew') && !confirm('Are you sure you want to abandon progress?')) {
          transition.abort();
        } else {
          if (this.controller.get('model.teamGroup.isNew') && !this.controller.get('model.teamGroup.isSaving')) {
            this.controller.get('model.teamGroup').deleteRecord();
          }
          // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
      }
    }
  });
});