define('coach-central/routes/admin/teams/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {

    currentUser: service(),

    beforeModel: function beforeModel(transition) {
      if (!get(this, 'currentUser.isTeamAdmin')) {
        transition.abort();
      }
    },
    model: function model() {
      var _this = this;

      return get(this, 'currentUser.user.currentTeam.client').then(function (client) {
        // previously we just did return get(client,'teams'); here
        // but now that we are looking up teams via client, a newly created team
        // is not found in list because it has no reference back to client
        // so I am hard reloading everything
        return get(_this, 'store').findRecord('client', client.id, { include: 'teams' }).then(function (client) {
          return get(client, 'teams').then(function (teams) {
            return teams.sortBy('name');
          });
        });
      });
    }
  });
});