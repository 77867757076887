define('coach-central/routes/admin/users/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {

    currentUser: service(),

    model: function model() {
      return RSVP.hash({
        user: get(this, 'store').createRecord('user'),
        roles: get(this, 'store').findAll('role'),
        teams: get(this, 'currentUser.user.currentTeam').get('client').then(function (client) {
          return client.get('teams');
        })
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('model.user.isNew') && !confirm('Are you sure you want to abandon progress?')) {
          transition.abort();
        } else {
          if (this.controller.get('model.user.isNew') && !this.controller.get('model.user.isSaving')) {
            this.controller.get('model.user').deleteRecord();
          } // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
      }
    }
  });
});