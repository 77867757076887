define('coach-central/helpers/moment-utc', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.momentUtc = momentUtc;
  function momentUtc(params /*, hash*/) {
    if (!params[0]) {
      return;
    }
    var date = params[0];
    return _moment.default.utc(date);
  }

  exports.default = Ember.Helper.helper(momentUtc);
});