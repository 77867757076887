define('coach-central/mirage/models/athlete', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    redoxTests: (0, _emberCliMirage.hasMany)('redoxTest'),
    hooperSurveys: (0, _emberCliMirage.hasMany)('hooperSurvey'),
    comments: (0, _emberCliMirage.hasMany)('comment'),
    teams: (0, _emberCliMirage.hasMany)('team'),
    teamGroups: (0, _emberCliMirage.hasMany)('teamGroup'),
    position: (0, _emberCliMirage.belongsTo)('position')
  });
});