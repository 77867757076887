define('coach-central/components/comment-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    comment: null, // the comment being processed
    isEditing: false, // comment is being edited
    isReplying: false, // a reply is being added to the comment
    isRemoved: false,
    showReplyBtn: true,
    showCancelBtn: true,
    showReplies: true,
    showDeleteCommentDialog: false,
    shortForm: false,

    currentUser: null, //the currentUser service passed in

    canEdit: computed('comment', 'comment.text', 'comment.isRemoved', function () {
      if (get(this, 'comment.isRemoved')) {
        return false;
      }
      if (get(this, 'currentUser')) {
        return get(this, 'comment.commentBy.id') === get(this, 'currentUser.user.id');
      }
      return false;
    }),

    doubleClick: function doubleClick() {
      if (get(this, 'canEdit')) {
        this.set('isEditing', true);
      }
    },


    /*-------------------------------- Actions ---------------------------------*/
    actions: {
      // TODO: these actions should probably bubble up to controller so the
      // model.save functionality is processed there, to make component
      // more generic. Not a priority

      removeComment: function removeComment(comment) {
        set(comment, 'isRemoved', true);
        set(comment, 'text', 'Comment is removed.');
        comment.save();
        this.sendAction('showApplicationToast', 'Comment removed');
        set(this, 'showDeleteCommentDialog', false);
      },
      saveComment: function saveComment(comment) {
        var _this = this;

        set(comment, 'updatedAt', new Date());
        return comment.save().catch(function () {
          _this.sendAction('showApplicationToast', 'Failed to update comment!');
        });
      },


      /*------------------------------- Dialog ---------------------------------*/

      openDeleteCommentDialog: function openDeleteCommentDialog(comment) {
        set(this, 'commentToRemove', comment);
        this.set('showDeleteCommentDialog', true);
      },
      closeDeleteCommentDialog: function closeDeleteCommentDialog() {
        set(this, 'showDeleteCommentDialog', false);
      }
    }
  });
});