define('coach-central/router', ['exports', 'coach-central/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      run = Ember.run,
      set = Ember.set;


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: service(),
    currentUser: service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },
    _trackPage: function _trackPage() {
      var _this = this;

      run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        set(_this, 'metrics.context.userId', _this.getWithDefault('currentUser.user.id', 'Anonymous'));
        var title = _this.getWithDefault('currentRouteName', 'Unknown');

        Ember.get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('login');
    this.route('dashboard', function () {
      this.route('team', { path: '/team/:team_id' }, function () {
        this.route('athlete', { path: '/athlete/:athlete_id' }, function () {
          this.route('redox', function () {
            this.route('no-tests', { path: '/test' });
            this.route('test', { path: '/test/:test_id' });
          });
          //this.route('gps');
        });
      });
    });

    this.route('redox-cases', function () {
      this.route('new');
      this.route('redox-case', { path: ':redox_case_id' }, function () {
        this.route('edit');
        this.route('redox-test', { path: '/redox/:redox_test_id' });
      });
    });

    this.route('settings', function () {
      this.route('preferences');
      this.route('test');
    });

    this.route('forgot-password');
    this.route('reset-password');
    this.route('error');
    this.route('errorPage');

    this.route('admin', function () {
      this.route('users', function () {
        this.route('new');
        this.route('edit', { path: '/:user_id/edit' });
      });
      this.route('athletes', function () {
        this.route('edit', { path: '/:athlete_id/edit' });
        this.route('new');
      });
      this.route('groups', function () {
        this.route('new');
        this.route('edit', { path: '/:group_id/edit' });
      });
      this.route('teams', function () {
        this.route('new');
        this.route('edit', { path: '/:team_id/edit' });
      });
    });

    this.route('not-found', { path: '/*path' });
    this.route('privacy');
    this.route('terms');
  });

  exports.default = Router;
});