define('coach-central/routes/admin/teams/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model(params) {
      var store = get(this, 'store');
      return RSVP.hash({
        team: store.findRecord('team', params.team_id),
        sports: store.findAll('sport')
      });
    }
  });
});