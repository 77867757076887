define('coach-central/routes/dashboard/team', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model(params) {
      return get(this, 'store').findRecord('team', params.team_id); //{include: 'team-groups'});
    },


    actions: {
      willTransition: function willTransition(transition) {
        //if transitioning away from route - reset search variables
        this.controller.set('athleteString', null);
        this.controller.set('searchedAthletes', null);
        this.controller.set('currentSearchAthlete', null);
      }
    }

  });
});