define('coach-central/mirage/factories/position', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var POSITIONS = ['Defender', 'Midfielder', 'Running Back', 'Forward', 'Point Guard', 'Quarterback', 'Left Back', 'Right Back'];

  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return (0, _utils.randomElement)(POSITIONS);
    }
  });
});