define('coach-central/components/panel-widget-medium', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['widget'],
    widgetOptions: [],
    actions: {
      toggleDropdown: function toggleDropdown() {
        this.toggleProperty('showDropdown');
      },
      updateD3Data: function updateD3Data(param) {
        this.toggleProperty('showDropdown');
        this.updateD3Donut(param);
      }
    }
  });
});