define("coach-central/helpers/truncate-chars", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.truncateChars = truncateChars;
  function truncateChars(params /*, hash*/) {
    var truncateToSize = params[0];
    var text = params[1];
    if (!text) {
      return;
    }

    text = text.length <= truncateToSize ? text : text.slice(0, truncateToSize) + "..";
    return text;
  }

  exports.default = Ember.Helper.helper(truncateChars);
});