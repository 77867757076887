define('coach-central/controllers/forgot-password', ['exports', 'coach-central/config/environment', 'ember-ajax/errors'], function (exports, _environment, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      computed = Ember.computed;


  var unexpectedErrorMsg = "An unexpected error has occured.";

  exports.default = Ember.Controller.extend({
    /* ---------------------------- Services -----------------------------------*/
    ajax: service(),

    /* --------------------------- Properties ----------------------------------*/

    emailToReset: null,
    emailInput: null,
    errorMessage: null,
    showEmailConfirmation: false,
    showLoading: false,
    confirmLink: document.location.origin + '/reset-password',

    validEmail: computed('emailInput', function () {
      var email = get(this, 'emailInput');
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }),

    emailIconColor: computed('validEmail', function () {
      return get(this, 'validEmail') ? 'password-green' : null;
    }),

    isSubmitDisabled: computed('validEmail', function () {
      return get(this, 'validEmail') ? false : true;
    }),

    /* ---------------------------- Actions ------------------------------------*/

    actions: {
      resetPassword: function resetPassword() {
        this.setEmailToReset();
        this.postEmail();
        this.clearInput();
      },
      updateEmail: function updateEmail(email) {
        set(this, 'emailInput', email);
      }
    },

    /* ---------------------------- Functions ----------------------------------*/

    setEmailToReset: function setEmailToReset() {
      var emailToReset = get(this, 'emailInput');
      set(this, 'emailToReset', emailToReset);
    },
    postEmail: function postEmail() {
      var _this = this;

      this.showLoadingIcon();

      var email = get(this, 'emailToReset');
      var confirmLink = get(this, 'confirmLink');
      var oauthHeader = 'Basic ' + btoa(_environment.default.oauth.clientId + ':');

      get(this, 'ajax').post(_environment.default.JSONAPIAdapter.host + '/reset', {
        contentType: 'application/x-www-form-urlencoded',
        data: {
          'email': email,
          'confirm-link': confirmLink
        },
        headers: {
          'authorization': oauthHeader
        }
      }).then(function (response) {
        _this.handleSuccessResponse(response);
      }).catch(function (error) {
        _this.handleErrorResponse(error);
      });
    },
    handleSuccessResponse: function handleSuccessResponse(response) {
      this.removeLoadingIcon();

      if (response.message) {
        this.showConfirmation();
      }
    },
    handleErrorResponse: function handleErrorResponse(error) {
      this.removeLoadingIcon();
      if ((0, _errors.isAjaxError)(error)) {
        // handle all errors
        this.showErrorMsg(unexpectedErrorMsg);
        return;
      }
    },
    showLoadingIcon: function showLoadingIcon() {
      set(this, 'showLoading', true);
    },
    removeLoadingIcon: function removeLoadingIcon() {
      set(this, 'showLoading', false);
    },
    clearInput: function clearInput() {
      set(this, 'emailInput', null);
    },
    showConfirmation: function showConfirmation() {
      set(this, 'showEmailConfirmation', true);
    },
    showErrorMsg: function showErrorMsg(errorMessage) {
      set(this, 'errorMessage', errorMessage);
    }
  });
});