define('coach-central/models/user-setting', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    //ATTRS
    teamGroup: 'All', // filter athletes in app
    weightUnitPref: attr('string'),
    heightUnitPref: attr('string')

    //RELATIONSHIPS

    //COMPUTED

  });
});