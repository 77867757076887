define('coach-central/mirage/serializers/application', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.JSONAPISerializer.extend({
    serialize: function serialize() /*object, request*/{
      // This is how to call super, as Mirage borrows [Backbone's implementation of extend](http://backbonejs.org/#Model-extend)
      var json = _emberCliMirage.Serializer.prototype.serialize.apply(this, arguments);

      // Add metadata, sort parts of the response, etc.
      //console.log("serialize:",json);
      return json;
    }
  });
});