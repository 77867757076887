define('coach-central/routes/login', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {

    session: service(),

    activate: function activate() {
      this._super.apply(this, arguments);
      Ember.$('body').addClass('login');
    }
  });
});