define('coach-central/controllers/admin/users/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set;
  exports.default = Controller.extend({

    isSaving: false,

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        set(this, 'isSaving', true);
        if (get(changeset, 'role.name') === 'ADMIN') {
          set(changeset, 'teams', get(this, 'model.teams'));
        }
        set(changeset, 'currentTeam', get(changeset, 'teams.firstObject'));
        return changeset.save().then(function () {
          set(_this, 'isSaving', false);
          _this.send('showApplicationToast', "New User successfully created. An image can now be added for this user.", 4000);
          _this.transitionToRoute('admin.users.edit', get(_this, 'model.user.id'));
        }).catch(function (e) {
          set(_this, 'isSaving', false);
          Ember.Logger.log(e);
          if (e.errors[0].status === "500") {
            _this.send('showApplicationToast', "There was a problem creating this user, most likely a user with this Email address already exists", 5000);
          } else {
            _this.send('showApplicationToast', e.errors[0].detail, 5000);
          }
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.send('showApplicationToast', "Changes since last save have been undone.");
        return true;
      },
      cancel: function cancel() {
        this.send('showApplicationToast', "Creation has been cancelled.");
        this.transitionToRoute('admin.users.index');
      }
    }
  });
});