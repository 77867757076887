define('coach-central/components/athlete-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    inSurvey: false,
    hasAthleteNav: false, // widget buttons for prev/next athlete
    athlete: null, // athlete model
    currentUser: null // currentuser service
  });
});