define("coach-central/mirage/factories/user-setting", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    weightUnitPref: function weightUnitPref() {
      return "kilograms";
    },
    heightUnitPref: function heightUnitPref() {
      return "centimeters";
    }
  });
});