define('coach-central/mirage/models/redox-case', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    redoxTests: (0, _emberCliMirage.hasMany)('redoxTest'),
    team: (0, _emberCliMirage.belongsTo)('team')
  });
});