define('coach-central/components/wellness-summary', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;


  function getIllnessText(today, lastweek) {
    var illnessRange = { today: 'Today', week: 'In the Last Week' };
    if (today && lastweek) {
      return illnessRange.today + ', ' + illnessRange.week;
    } else if (today) {
      return illnessRange.today;
    } else if (lastweek) {
      return illnessRange.week;
    }
    return null;
  }

  exports.default = Ember.Component.extend({

    fever: computed('survey.feverToday', 'survey.feverPrev', function () {
      return getIllnessText(get(this, 'survey.feverToday'), get(this, 'survey.feverPrev'));
    }),

    soreThroat: computed('survey.soreThroatToday', 'survey.soreThroatPrev', function () {
      return getIllnessText(get(this, 'survey.soreThroatToday'), get(this, 'survey.soreThroatPrev'));
    }),
    headache: computed('survey.headacheToday', 'survey.headachePrev', function () {
      return getIllnessText(get(this, 'survey.headacheToday'), get(this, 'survey.headachePrev'));
    }),
    ache: computed('survey.jointMuscleAcheToday', 'survey.jointMuscleAchePrev', function () {
      return getIllnessText(get(this, 'survey.jointMuscleAcheToday'), get(this, 'survey.jointMuscleAchePrev'));
    }),
    diarrhea: computed('survey.diarrheaToday', 'survey.diarrheaPrev', function () {
      return getIllnessText(get(this, 'survey.diarrheaToday'), get(this, 'survey.diarrheaPrev'));
    }),

    hasNoSymptoms: computed('survey.feverToday', 'survey.feverPrev', 'survey.soreThroatToday', 'survey.soreThroatPrev', 'survey.headachePrev', 'survey.headacheToday', 'survey.jointMuscleAcheToday', 'survey.jointMuscleAchePrev', 'survey.diarrheaToday', 'survey.diarrheaPrev', function () {
      return !(get(this, 'survey.feverToday') || get(this, 'survey.feverPrev') || get(this, 'survey.soreThroatToday') || get(this, 'survey.soreThroatPrev') || get(this, 'survey.headacheToday') || get(this, 'survey.headachePrev') || get(this, 'survey.jointMuscleAcheToday') || get(this, 'survey.jointMuscleAchePrev') || get(this, 'survey.diarrheaToday') || get(this, 'survey.diarrheaPrev'));
    }),

    energyLevelText: computed('survey.energyLevel', function () {
      var text = ['Unknown', 'Very Low', 'Low', 'OK', 'Good', 'High'];
      var val = parseInt(Math.round(get(this, 'survey.energyLevel')));
      val = isNaN(val) || val < 1 || val > 5 ? 0 : val;
      return text[val];
    }),

    energyLevelWarn: computed('survey.energyLevel', function () {
      var val = parseInt(Math.round(get(this, 'survey.energyLevel')));
      val = isNaN(val) || val < 1 || val > 5 ? 0 : val;
      return val > 0 && val < 3;
    }),

    muscleSorenessText: computed('survey.muscleSoreness', function () {
      var text = ['Unknown', 'Very Sore', 'Sore', 'Average', 'Not Sore', 'Feel Great'];
      var val = parseInt(Math.round(get(this, 'survey.muscleSoreness')));
      val = isNaN(val) || val < 1 || val > 5 ? 0 : val;
      return text[val];
    }),

    muscleSorenessWarn: computed('survey.muscleSoreness', function () {
      var val = parseInt(Math.round(get(this, 'survey.muscleSoreness')));
      val = isNaN(val) || val < 1 || val > 5 ? 0 : val;
      return val > 0 && val < 3;
    }),

    previousActivityList: computed('survey.prevExerciseGym', 'survey.prevExerciseTraining', 'survey.prevExerciseGame', 'survey.prevExerciseOther', 'survey.prevExerciseNone', function () {
      if (!get(this, 'survey.isComplete')) {
        return "Unknown";
      }
      var items = [];
      if (get(this, 'survey.prevExerciseNone')) {
        items.push('Rest');
      }
      if (get(this, 'survey.prevExerciseGym')) {
        items.push('Gym');
      }
      if (get(this, 'survey.prevExerciseTraining')) {
        items.push('Training');
      }
      if (get(this, 'survey.prevExerciseGame')) {
        items.push('Game');
      }
      if (get(this, 'survey.prevExerciseOther')) {
        items.push(get(this, 'survey.prevExerciseOther'));
      }
      var list = items.join(', ');
      return list;
    })
  });
});