define('coach-central/routes/dashboard/team/athlete/redox/test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return get(this, 'store').findRecord('redoxTest', params.test_id);
    },


    //if you reload the page,  this hightlights the correct circle in the graph
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('dashboard.team.athlete.redox').set('currentTest', model);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('isSavingComment')) {
          this.send('showApplicationToast', 'Please wait while the comment is saving.');
          transition.abort();
        } else {
          return true;
        }
      }
    }

  });
});