define('coach-central/routes/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUser: service(),

    actions: {
      showAthlete: function showAthlete(athlete) {
        if (athlete) {
          var currentAthlete = get(this, 'currentUser').get('currentAthlete');
          get(this, 'currentUser').set('currentAthlete', athlete);
          var currentRouteName = this.get('router.currentRouteName');
          if (currentRouteName.includes('dashboard.team.athlete.redox')) {
            //Don't not transition if on the graph page and you click on the same player. #127
            if (Ember.isEqual(athlete, currentAthlete)) {
              return;
            }
            this.transitionTo('dashboard.team.athlete.redox', athlete);
          } else if (currentRouteName.includes('athlete.gps')) {
            this.transitionTo('dashboard.team.athlete.gps', athlete);
          } else {
            //DEFAULT
            this.transitionTo('dashboard.team.athlete.redox', athlete);
          }
        }
      }
    }

  });
});