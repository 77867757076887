define('coach-central/mirage/factories/role', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.random.arrayElement(['Admin', 'Athlete', 'Tester', 'Coach']);
    },
    description: function description() {
      return "[Mirage description]";
    }
  });
});