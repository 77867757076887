define('coach-central/mirage/factories/hooper-survey', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    date: function date() {
      return (0, _utils.randomDate)(new Date(2016, 0, 1), new Date());
    },
    fatigueLevel: function fatigueLevel() {
      return (0, _utils.randomInt)(5, 10);
    },
    stressLevel: function stressLevel() {
      return (0, _utils.randomInt)(5, 10);
    },
    muscleSoreness: function muscleSoreness() {
      return (0, _utils.randomInt)(3, 7);
    },
    sleepQuality: function sleepQuality() {
      return (0, _utils.randomInt)(1, 8);
    },
    isComplete: function isComplete() {
      return true;
    }
  });
});