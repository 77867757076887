define('coach-central/models/redox-risk-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  var get = Ember.get,
      computed = Ember.computed;
  exports.default = _emberData.default.Model.extend({
    //ATTRS
    name: attr('string'),
    priority: attr('number'),
    athleteCount: attr('number'),

    //RELATIONSHIPS

    //COMPUTED
    backgroundColor: computed('priority', function () {
      var backgroundColors = ['#F7464A', '#FDB45C', '#46BFBD', '#BDC3C7'];
      var i = get(this, 'priority') - 1;
      return backgroundColors[i];
    }),

    backgroundHoverColor: computed('priority', function () {
      //Uncomment to show different colors when hovering.
      //let backgroundHoverColors = ['#FF5A5E', '#FFC870', '#5AD3D1', '#C8CDD0'];
      var backgroundHoverColors = ['#F7464A', '#FDB45C', '#46BFBD', '#BDC3C7'];
      var i = get(this, 'priority') - 1;
      return backgroundHoverColors[i];
    })
  });
});