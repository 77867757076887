define('coach-central/mirage/factories/team', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var names = ['Liverpool FC', 'Manchester Utd', 'Chelsea FC', 'Tottenham Hotspur', 'Everton FC'];

  var cities = ['Liverpool', 'Manchester', 'London', 'London', 'Liverpool'];

  var logos = ['https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/my-context/0001/40/995ad3ada2f1482b0cd6804fe36480ebf866954d.jpeg', 'http://d10bo0mevst0d3.cloudfront.net/media/catalog/product/1/-/1-manchester-united-door-name-plate-900x900.jpg', 'https://seeklogo.com/images/C/chelsea-f-c-logo-C1CB09D36E-seeklogo.com.png', 'https://i1.wp.com/dlscenter.com/wp-content/uploads/2017/06/tottenham-logo.png?resize=296%2C296', 'https://www.seeklogo.net/wp-content/uploads/2016/11/everton-football-club-logo-preview.png'];

  exports.default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      return names[i] || _emberCliMirage.faker.lorem.words(2);
    },
    homeVenue: function homeVenue(i) {
      return cities[i] || _emberCliMirage.faker.address.city();
    },
    logo: function logo(i) {
      return logos[i] || _emberCliMirage.faker.image.imageUrl();
    }
  });
});