define('coach-central/services/memory-storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service;
  exports.default = Service.extend({
    getAndRemove: function getAndRemove(key) {
      var obj = this.get(key);
      if (obj !== undefined) {
        this.set(key, undefined);
      }
      return obj;
    }
  });
});