define('coach-central/routes/dashboard/team/athlete', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    router: service(),
    currentUser: service(),

    model: function model(params) {
      return get(this, 'store').findRecord('athlete', params.athlete_id, { include: 'position' });
    },
    afterModel: function afterModel(model) {
      get(this, 'currentUser').set('currentAthlete', model);
    },


    actions: {}
  });
});