define("coach-central/utils/conversions", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inToCm = inToCm;
  exports.lbsToKg = lbsToKg;
  exports.cmToIn = cmToIn;
  exports.kgToLbs = kgToLbs;

  var INCHES_TO_CM = 2.54;
  var LBS_TO_KG = 0.453592;
  var CM_TO_INCHES = 0.393701;
  var KG_TO_LBS = 2.20462;

  function inToCm(height) {
    if (height && height > 0) {
      return Math.round(parseInt(height) * INCHES_TO_CM);
    }
    return "0";
  }

  function lbsToKg(weight) {
    if (weight && weight > 0) {
      return Math.round(parseInt(weight) * LBS_TO_KG);
    }
    return "0";
  }

  function cmToIn(height) {
    if (height && height > 0) {
      return Math.round(parseInt(height) * CM_TO_INCHES);
    }
    return "0";
  }

  function kgToLbs(weight) {
    if (weight && weight > 0) {
      return Math.round(parseInt(weight) * KG_TO_LBS);
    }
    return "0";
  }
});