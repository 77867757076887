define('coach-central/mirage/models/redox-test', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    athlete: (0, _emberCliMirage.belongsTo)('athlete'),
    comments: (0, _emberCliMirage.hasMany)('redox-comment'),
    redoxHealthSurvey: (0, _emberCliMirage.belongsTo)('redoxHealthSurvey'),
    redoxCase: (0, _emberCliMirage.belongsTo)('redoxCase'),
    redoxAlertReport: (0, _emberCliMirage.belongsTo)('redoxAlertReport')
  });
});