define('coach-central/controllers/redox-cases/redox-case/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Controller.extend({
    showRedoxEntry: false, // show redox entry form for each athlete's test
    currentUser: service(),
    listMode: false,
    isSavingComment: false,
    isSavingAnyTest: false,

    actions: {
      toggleRedoxEntry: function toggleRedoxEntry() {
        this.toggleProperty('showRedoxEntry');
      },
      toggleRedoxListMode: function toggleRedoxListMode() {
        this.toggleProperty('listMode');
      },
      setCurrentTest: function setCurrentTest(id) {
        var currentTest = this.getWithDefault('currentTest', 0);
        if (currentTest === id) {
          set(this, 'currentTest', 0);
        } else {
          set(this, 'currentTest', id);
        }
      },
      createComment: function createComment(redoxTest, text, parent) {
        var _this = this;

        set(this, 'isSavingComment', true);

        var comment = get(this, 'store').createRecord('comment', {
          commentBy: get(this, 'currentUser.user'),
          athlete: redoxTest.get('athlete'),
          redoxTest: redoxTest,
          text: text,
          parent: parent
        });

        return comment.save().then(function () {
          set(_this, 'isSavingComment', false);
          _this.send('showApplicationToast', 'Comment created');
        }).catch(function () {
          set(_this, 'isSavingComment', false);
          _this.send('showApplicationToast', 'Failed to create comment!');
        });
      },
      displayErrorPage: function displayErrorPage(error) {
        this.transitionToRoute('errorPage');
      }
    }
  });
});