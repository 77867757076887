define('coach-central/mirage/models/team-group', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Model.extend({
    athletes: (0, _emberCliMirage.hasMany)('athlete', { inverse: 'teamGroups' }),
    team: (0, _emberCliMirage.belongsTo)('team')
  });
});