define('coach-central/models/redox-case', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _emberData.default.Model.extend({
    //ATTRS
    redoxCaseName: attr('string'),
    redoxCaseDate: attr('date'),
    athleteCount: attr('number'),
    numRedoxTestsComplete: attr('number'),
    numSurveysComplete: attr('number'),
    numCrpTestsComplete: attr('number'),
    numCkTestsComplete: attr('number'),

    //RELATIONSHIPS
    redoxTests: hasMany('redoxTest'),
    team: belongsTo('team')

    //COMPUTED
  });
});