define('coach-central/models/hooper-survey', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    //ATTRS
    date: attr('date'),
    createdAt: attr('date', {
      defaultvalue: function defaultvalue() {
        return new Date();
      }
    }),
    updatedAt: attr('date', {
      defaultvalue: function defaultvalue() {
        return new Date();
      }
    }),
    fatigueLevel: attr('number'),
    stressLevel: attr('number'),
    muscleSoreness: attr('number'),
    sleepQuality: attr('number'),
    isComplete: attr('boolean'),

    //RELATIONSHIPS
    athlete: belongsTo('athlete')

    //COMPUTED

  });
});