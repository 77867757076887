define('coach-central/components/paper-pikaday', ['exports', 'paper-pikaday/components/paper-pikaday'], function (exports, _paperPikaday) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _paperPikaday.default;
    }
  });
});