define('coach-central/models/team-group', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany,
      Model = _emberData.default.Model;
  exports.default = Model.extend({
    //ATTRS
    name: attr('string'),

    //RELATIONSHIPS
    athletes: hasMany('athlete', { inverse: 'teamGroups' }),
    team: belongsTo('team')

    //COMPUTED

  });
});