define('coach-central/models/client', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    organisation: attr('string'),
    name: attr('string'),
    clientCode: attr('string'),
    teams: hasMany('team')
  });
});