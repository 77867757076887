define('coach-central/models/redox-health-survey', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Model.extend({
    //ATTRS
    date: attr('date'),
    createdAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),

    isComplete: attr('boolean', { defaultValue: false }),
    inProgress: attr('boolean'),
    exercised: attr(), //if you change these to booleans, the changeset validation doesn't fire
    eaten: attr(), //because for some reason they get set to false by default - the radio button still sets these to booleans
    prevExerciseGym: attr('boolean'),
    prevExerciseTraining: attr('boolean'),
    prevExerciseGame: attr('boolean'),
    prevExerciseNone: attr('boolean'),
    prevExerciseOther: attr('string'),
    energyLevel: attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    muscleSoreness: attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    feverToday: attr('boolean'),
    feverPrev: attr('boolean'),
    soreThroatToday: attr('boolean'),
    soreThroatPrev: attr('boolean'),
    headacheToday: attr('boolean'),
    headachePrev: attr('boolean'),
    jointMuscleAcheToday: attr('boolean'),
    jointMuscleAchePrev: attr('boolean'),
    diarrheaToday: attr('boolean'),
    diarrheaPrev: attr('boolean'),
    otherSymptom: attr('string'),

    //RELATIONSHIPS
    //athlete: 					    belongsTo('athlete'),
    redoxTest: belongsTo('redoxTest'),

    //COMPUTED
    hasBeenEdited: computed('createdAt', 'updatedAt', function () {
      return (0, _moment.default)(get(this, 'updatedAt')).isAfter(get(this, 'createdAt'), 'second');
    })

  });
});