define('coach-central/transitions/scroll-into-view', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {
		var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

		Ember.assert("The argument to scroll-to-top is passed to Velocity's scroll function and must be an object", 'object' === (typeof options === 'undefined' ? 'undefined' : _typeof(options)));
		options = Ember.merge({ duration: 500, offset: 0 }, options);
		return window.$.Velocity(this.newElement, 'scroll', options);
	};

	var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
		return typeof obj;
	} : function (obj) {
		return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
	};
});