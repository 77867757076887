define('coach-central/components/athlete-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['squad_card', 'show_attribute'],
    classNameBindings: ['isSelected:selected'],

    athlete: null, // the athlete this card relates too
    showRedox: true, // displays latest redox information for this athlete

    // used in cases where cards can be selected in list
    isSelected: computed('athlete.selected', function () {
      return get(this, 'athlete.selected') || false;
    }),

    //show circle Bool
    //Check if the test was performed on the same day as last test
    showCrpCircle: computed('athlete.latestCrpTestComplete', function () {
      var latestCrp = get(this, 'athlete.latestCrpTestComplete') ? moment(get(this, 'athlete.latestCrpTestComplete')) : null;
      var lastTestDate = get(this, 'lastTestDate');

      if (latestCrp !== null && lastTestDate !== null) {
        return latestCrp.isSame(lastTestDate, 'day');
      } else {
        return false;
      }
    }),

    showRedoxCircle: computed('athlete.latestTestComplete', function () {
      var lastTestDate = get(this, 'lastTestDate');
      var latestRedox = get(this, 'athlete.latestTestComplete') ? moment(get(this, 'athlete.latestTestComplete')) : null;

      if (latestRedox !== null && lastTestDate !== null) {
        return latestRedox.isSame(lastTestDate, 'day');
      } else {
        return false;
      }
    }),

    showCkCircle: computed('athlete.latestCkTestComplete', function () {
      var lastTestDate = get(this, 'lastTestDate');
      var latestCk = get(this, 'athlete.latestCkTestComplete') ? moment(get(this, 'athlete.latestCkTestComplete')) : null;

      if (latestCk !== null && lastTestDate !== null) {
        return latestCk.isSame(lastTestDate, 'day');
      } else {
        return false;
      }
    }),

    lastTestDate: computed('showRedoxCircle', 'showCrpCircle', function () {
      var latestCrp = get(this, 'athlete.latestCrpTestComplete') ? moment(get(this, 'athlete.latestCrpTestComplete')) : null;
      var latestRedox = get(this, 'athlete.latestTestComplete') ? moment(get(this, 'athlete.latestTestComplete')) : null;
      var latestCk = get(this, 'athlete.latestCkTestComplete') ? moment(get(this, 'athlete.latestCkTestComplete')) : null;

      var tests = [latestCrp, latestRedox, latestCk].filter(function (val) {
        return val !== null;
      });

      if (!Array.isArray(tests) || !tests.length) {
        return null;
      } else {

        var recentTests = tests.map(function (d) {
          return moment(d);
        }),
            testDate = moment.max(recentTests);

        return testDate;
      }
    }),

    isCompleted: false // display tick mark instead of photo
  });
});