define('coach-central/components/redox-case-athlete-select', ['exports', 'moment', 'coach-central/utils/constants'], function (exports, _moment, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['flex'],

    HOUR_ARRAY: _constants.HOUR_ARRAY,
    MIN_ARRAY: _constants.MIN_ARRAY,

    // Using string instead of ints for the dropdown as an int of 0 doesn't show up.
    // But an string of 0 does.
    selectedHour: computed('', function () {
      if (get(this, 'isEditing')) {
        return get(this, 'redoxCase.redoxCaseDate').getUTCHours().toString();
      }
      return new Date().getHours().toString();
    }),

    selectedMin: computed('', function () {
      if (get(this, 'isEditing')) {
        return get(this, 'redoxCase.redoxCaseDate').getUTCMinutes().toString();
      }
      return new Date().getMinutes().toString();
    }),

    athletesAlreadyInCase: [],
    athletesToRemove: [],

    showConfirmDelete: false,

    toggleAllAthletes: false, // toggle all athletes on/off
    redoxCaseDate: computed('redoxCase.redoxCaseDate', function () {
      var redoxCaseDate = get(this, 'redoxCase.redoxCaseDate');
      if (!redoxCaseDate) {
        redoxCaseDate = new Date();
      }
      return redoxCaseDate;
    }),
    redoxCase: null,

    displayName: computed('redoxCase.redoxCaseName', 'redoxCaseDate', function () {
      var caseName = get(this, 'redoxCase.redoxCaseName');
      var caseDate = get(this, 'redoxCaseDate');
      if (caseName) {
        return caseName;
      } else {
        return "Test on " + _moment.default.utc(caseDate).format('DD MMM YYYY');
      }
    }),

    // athletes in this case that have redox tests created
    selectedAthletes: computed('redoxCase.@each.redoxTests', function () {
      var _this = this;

      var allAthletes = get(this, 'athletes');
      this.clearArrays();

      get(this, 'redoxCase.redoxTests').then(function (selectedTests) {
        if (selectedTests.length) {
          selectedTests.forEach(function (test) {
            test.get('athlete').then(function (athlete) {
              var alreadyInCaseAthlete = allAthletes.findBy('id', athlete.get('id'));
              alreadyInCaseAthlete.set('selected', true);
              get(_this, 'athletesAlreadyInCase').pushObject(alreadyInCaseAthlete);
            });
          });
        }
      });

      return allAthletes.sortBy('firstName', 'lastName');
    }),

    clearArrays: function clearArrays() {
      set(this, 'athletesAlreadyInCase', []);
      set(this, 'athletesToRemove', []);
    },


    numAthletesSelected: computed('athletes.@each.selected', function () {
      return this.get('athletes').filterBy('selected', true).length;
    }),

    isAthleteSelected: computed('numAthletesSelected', function () {
      // is there at least 1 athlete selected ?
      return this.get('numAthletesSelected') > 0;
    }),

    actions: {
      cancel: function cancel() {
        this.get('cancel')(this.get('redoxCase'), this.get('athletes'));
      },
      updateHourHandler: function updateHourHandler(hour) {
        set(this, 'selectedHour', hour);
      },
      updateMinHandler: function updateMinHandler(min) {
        set(this, 'selectedMin', min);
      },
      checkForRemovals: function checkForRemovals() {
        var _this2 = this;

        var aaic = get(this, 'athletesAlreadyInCase');
        var as = get(this, 'athletes').filterBy('selected', true);
        aaic.forEach(function (athlete) {
          if (!as.includes(athlete)) {
            get(_this2, 'athletesToRemove').pushObject(athlete);
          }
        });

        // call submit right away if there are no athletes to remove
        if (!get(this, 'athletesToRemove.length')) {
          this.send('submit');
        } else {
          set(this, 'showConfirmDelete', true);
        }
      },
      closeDialog: function closeDialog() {
        set(this, 'athletesToRemove', []);
        set(this, 'showConfirmDelete', false);
      },
      submit: function submit() {
        set(this, 'showConfirmDelete', false);
        if (!get(this, 'redoxCase.redoxCaseName')) {
          get(this, 'redoxCase').set('redoxCaseName', get(this, 'displayName'));
        }

        var redoxCase = get(this, 'redoxCase');
        get(redoxCase, 'redoxCaseDate').setUTCHours(get(this, 'selectedHour'));
        get(redoxCase, 'redoxCaseDate').setUTCMinutes(get(this, 'selectedMin'));

        this.get('submit')(this.get('redoxCase'), this.get('athletes'));
      },
      selectAthlete: function selectAthlete(athlete) {
        athlete.toggleProperty('selected');
        this.notifyPropertyChange('athletes');
      },
      updateRedoxCaseDate: function updateRedoxCaseDate(selectedDate) {
        set(this, 'redoxCaseDate', _moment.default.utc(selectedDate).toDate());
        set(this, 'redoxCase.redoxCaseDate', _moment.default.utc(selectedDate).toDate());
      },
      selectAllAthletes: function selectAllAthletes() {
        var selectOn = this.toggleProperty('toggleAllAthletes');

        this.get('athletes').forEach(function (athlete) {
          athlete.set('selected', selectOn);
        });
        this.notifyPropertyChange('athletes');
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('athletes').forEach(function (athlete) {
        athlete.set('selected', false);
      });
    }
  });
});