define('coach-central/controllers/admin/teams/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service,
      get = Ember.get,
      set = Ember.set;
  exports.default = Controller.extend({
    currentUser: service(),

    isSaving: false,

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        set(this, 'isSaving', true);
        changeset.get('users').pushObject(get(this, 'currentUser.user'));
        return changeset.save().then(function (team) {
          set(_this, 'isSaving', false);
          _this.send('showApplicationToast', "Team created. An image can now be added for this team.", 4000);
          _this.transitionToRoute('admin.teams.edit', team.id);
        }).catch(function (e) {
          set(_this, 'isSaving', false);
          _this.send('showApplicationToast', e.errors[0].detail, 5000);
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.send('showApplicationToast', "Changes have been cancelled.");
        return true;
      },
      cancel: function cancel() {
        this.send('showApplicationToast', "Creation has been cancelled.");
        this.transitionToRoute('admin.teams.index');
      }
    }
  });
});