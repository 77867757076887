define('coach-central/mirage/scenarios/default', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {

    /*
    Seed your development database using your factories.
    This data will not be loaded in your tests.
     Make sure to define a factory for each model you want to create.
    */
    var NUM_TEAMS = 2;

    var NUM_ATHLETES_TEAM = 5;

    var NUM_TEAM_GROUPS = 5;

    var role = server.create('role', {
      name: 'Client-Admin'
    });

    var client = server.create('client', {
      organisation: "test organisation 1",
      name: "test client 1",
      clientCode: "tcc1"
    });

    server.createList('query', 4);

    var sport = server.create('sport');
    Ember.Logger.debug('created sport');

    server.createList('redoxRiskGroup', 4);
    Ember.Logger.debug('created redoxRiskGroups');

    // create some teams
    var teams = server.createList('team', NUM_TEAMS, {
      client: client,
      sport: sport
    });
    Ember.Logger.debug('created teams');

    // add groups and athletes to the teams
    teams.forEach(function (team) {
      var groups = server.createList('teamGroup', NUM_TEAM_GROUPS, { team: team });
      var athletes = server.createList('athlete', NUM_ATHLETES_TEAM, 'withPosition', 'withRedoxTests');
      athletes.forEach(function (athlete) {
        athlete.teamIds = [team.id];
        athlete.save();
      });
      // randomly assign athletes to groups
      groups.forEach(function (group) {
        var numRandomathletes = Math.floor(Math.random() * athletes.length);
        var randomathletes = athletes.sort(function () {
          return .5 - Math.random();
        }).slice(0, numRandomathletes);
        group.athletes = randomathletes;
        group.save();
      });
    });
    Ember.Logger.debug('created athletes');

    // create user settings for default user and set default teamGroup
    var userSettings = server.create('userSettings', {
      weightUnitPref: 'kg',
      heightUnitPref: 'cm'
    });
    Ember.Logger.debug('created settings');

    // create a user for the system
    server.create('user', {
      firstName: 'Demo',
      lastname: 'User',
      email: 'demo@orreco.com',
      avatar: _emberCliMirage.faker.internet.avatar(),
      role: role,
      currentTeam: teams[0],
      teams: teams,
      userSettings: userSettings /*, 'withUserSettings')*/ });
    Ember.Logger.debug('created user');

    // create a athlete with no redox to render different athlete cards etc
    server.create('athlete', {
      latestStressColour: 'pink',
      latestDefenceColour: '',
      photo: null,
      latestTest: null
    });
    Ember.Logger.debug('created blank athlete');

    // create some roles
    server.create('role', {
      name: 'Admin',
      description: 'administers the system for everyone'
    });
    server.create('role', {
      name: 'Tester',
      description: 'Conducts redox tests and other stuff'
    });
    server.create('role', {
      name: 'Sports Scientist',
      description: 'Does Sport scientisty things'
    });
    server.create('role', {
      name: 'Coach',
      description: 'The coach/mamanger type thing'
    });
    server.create('role', {
      name: 'Athlete',
      description: 'An Athlete who uses the product'
    });
  };
});