define('coach-central/routes/admin/users/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {

    currentUser: service(),

    model: function model(params) {
      var store = get(this, 'store');
      return RSVP.hash({
        user: store.findRecord('user', params.user_id, { include: 'role' }),
        roles: store.findAll('role'),
        teams: get(this, 'currentUser.user.currentTeam').get('client').then(function (client) {
          return client.get('teams');
        })
      });
    }
  });
});