define('coach-central/services/watson-query', ['exports', 'coach-central/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = Ember.Service,
      get = Ember.get,
      service = Ember.inject.service,
      RSVP = Ember.RSVP,
      $ = Ember.$;
  exports.default = Service.extend({

    session: service(),

    queryWatson: function queryWatson(query) {
      var token = get(this, 'session.data.authenticated.access_token');
      var API_URL = [_environment.default.JSONAPIAdapter.host, '/', _environment.default.JSONAPIAdapter.namespace, '/askWatson'].join('');

      if (query) {
        return new RSVP.Promise(function (resolve, reject) {
          $.ajax(API_URL, {
            method: "GET",
            data: { 'query': query },
            headers: {
              'Authorization': 'Bearer ' + token
            },
            contentType: 'application/json'
          }).then(function (response) {
            resolve(response);
          }, reject);
        });
      } else {
        return [];
      }
    }
  });
});