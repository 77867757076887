define('coach-central/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    session: service(),

    errorMessage: null,
    email: null,
    incorrectPasswordMessage: null,
    isSigningIn: false,
    currentYear: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('currentYear', moment().format('YYYY'));
    },


    /* --------------------------- Properties ----------------------------------*/

    validEmail: computed('email', function () {
      var email = get(this, 'email');
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    }),

    emailIconColor: computed('validEmail', function () {
      return get(this, 'validEmail') ? 'password-green' : null;
    }),

    passwordIconColor: computed('password', function () {
      if (get(this, 'password')) {
        return get(this, 'password').length > 2 ? 'password-green' : null;
      }
    }),

    isSubmitDisabled: computed('validEmail', 'password', 'isSigningIn', function () {
      return !get(this, 'isSigningIn') && get(this, 'validEmail') && get(this, 'password') && get(this, 'password').length > 2 ? false : true;
    }),

    /* --------------------------- Actions ----------------------------------*/

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        set(this, 'isSigningIn', true);

        var _getProperties = this.getProperties('email', 'password'),
            email = _getProperties.email,
            password = _getProperties.password;

        get(this, 'session').authenticate('authenticator:oauth2', email, password).catch(function (reason) {
          set(_this, 'isSigningIn', false);
          if (reason) {
            if (reason.error === 'invalid_grant' && reason.error_description === 'Bad credentials') {
              set(_this, 'incorrectPasswordMessage', "Wrong email or password. Try again or click Forgot Password.");
              //this.send('showApplicationToast', 'Invalid Username or Password.');
            } else if (reason.error === 'invalid_grant' && reason.error_description === 'User is disabled') {
              set(_this, 'incorrectPasswordMessage', "This account has been disabled. Please contact your administrator.");
              //this.send('showApplicationToast', 'This account has been disabled. Please contact your administrator.');
            } else if (reason.error === 'unauthorized') {
              set(_this, 'incorrectPasswordMessage', "Wrong email or password. Try again or click Forgot Password.");
              //this.send('showApplicationToast', 'Invalid Username or Password.');
            } else if (reason.error_description === 'Bad credentials') {
              set(_this, 'incorrectPasswordMessage', "Wrong email or password. Try again or click Forgot Password.");
            } else {
              //set(this, 'incorrectPasswordMessage', "Wrong email or password. Try again or click Forgot Password.");
              _this.send('showApplicationToast', "An unexpected error has occurred.");
            }
          } else {
            _this.send('showApplicationToast', "An unexpected error has occurred.");
          }
        });
      },
      updateEmail: function updateEmail(email) {
        set(this, 'email', email);
      },
      updatePassword: function updatePassword(password) {
        set(this, 'password', password);
      }
    },

    resetLoginInputs: function resetLoginInputs() {
      set(this, 'email', null);
      set(this, 'password', null);
    }
  });
});