define('coach-central/controllers/admin/athletes/edit', ['exports', 'coach-central/utils/conversions'], function (exports, _conversions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Controller.extend({

    currentUser: service(),

    isSaving: false,

    actions: {
      submit: function submit(changeset, height, weight) {
        var _this = this;

        set(this, 'isSaving', true);
        var heightUnitPref = get(this, 'currentUser.user.userSettings.heightUnitPref');
        if (heightUnitPref === 'in' || heightUnitPref === 'ft') {
          set(changeset, 'height', (0, _conversions.inToCm)(height));
        } else {
          set(changeset, 'height', height);
        }

        var weightUnitPref = get(this, 'currentUser.user.userSettings.weightUnitPref');
        if (weightUnitPref === 'lb' || weightUnitPref === 'st') {
          set(changeset, 'weight', (0, _conversions.lbsToKg)(weight));
        } else {
          set(changeset, 'weight', weight);
        }
        return changeset.save().then(function () {
          set(_this, 'isSaving', false);
          _this.send('showApplicationToast', 'Update successful');
          _this.transitionToRoute('admin.athletes.index');
        }).catch(function () {
          set(_this, 'isSaving', false);
          get(_this, 'model.errors').forEach(function (_ref) {
            var attribute = _ref.attribute,
                message = _ref.message;

            changeset.pushErrors(attribute, message);
          });
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.send('showApplicationToast', "Changes since last save have been undone.");
        return true;
      },
      cancel: function cancel() /*model*/{
        this.send('showApplicationToast', "Edit has been cancelled.");
        this.transitionToRoute('admin.athletes.index');
      }
    }
  });
});