define('coach-central/routes/admin/athletes/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP,
      service = Ember.inject.service;
  exports.default = Route.extend({
    currentUser: service(),

    model: function model(params) {
      var _this = this;

      var store = get(this, 'store');

      return RSVP.hash({
        athlete: store.findRecord('athlete', params.athlete_id),
        // teams: store.findAll('team').then((teams)=>{
        //   return teams.filterBy('client',currentClient);
        // }),
        teams: get(this, 'currentUser.user.currentTeam.client').then(function (client) {
          // previously we just did return get(client,'teams'); here
          // but now that we are looking up teams via client, a newly created team
          // is not found in list because it has no reference back to client
          // so I am hard reloading everything
          return get(_this, 'store').findRecord('client', client.id, { include: 'teams' }).then(function (client) {
            return get(client, 'teams');
          });
        }),
        positions: store.findAll('position')
        //groups: store.findAll('teamGroup')
      });
    }
  });
});