define('coach-central/components/form-user-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      computed = Ember.computed;
  exports.default = Component.extend({

    editImage: false,
    cropImage: false,

    //tracks if teams have been added/removed
    isRelationshipChanged: false,

    //tracks whether to disable checkoboxes if role is admin
    isNewUserAdmin: computed('changeset.role', function () {
      return get(this, 'changeset.role.name') === 'ADMIN' ? true : false;
    }),

    //no. of currently selected teams
    numOfTeams: computed.alias('changeset.teams.length'),

    // disable the Save button until valid changes have been made
    // or while a save is in progress
    saveDisabled: computed('changeset.isValid', 'changeset.isDirty', 'model.user.isSaving', 'isRelationshipChanged', 'numOfTeams', 'isSaving', function () {
      if (!get(this, 'isSaving') && get(this, 'changeset.isValid') && (get(this, 'changeset.isDirty') || get(this, 'isRelationshipChanged')) && !get(this, 'model.user.isSaving') && (get(this, 'numOfTeams') > 0 || get(this, 'isNewUserAdmin'))) {
        return false;
      }
      return true;
    }),

    rolesList: computed('model.roles', function () {
      //console.log('roles', get(this,'model.roles'));
      var roles = get(this, 'model.roles');
      return roles.rejectBy('id', 1);
    }),

    actions: {
      updateSelection: function updateSelection() {
        this.set('isRelationshipChanged', true);
      },

      toggleImage: function toggleImage() {
        this.toggleProperty('editImage');
        if (get(this, 'editImage')) {
          set(this, 'cropImage', false);
        }
      },
      toggleCrop: function toggleCrop() {
        this.toggleProperty('cropImage');
        if (get(this, 'cropImage')) {
          set(this, 'editImage', false);
          this.notifyPropertyChange('editImage');
        }
      },
      saveUser: function saveUser() {}
    },

    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: function validate(inputValue) {
        if (inputValue) {
          var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          return emailPattern.test(inputValue);
        }
        return true;
      }
    }],

    roleValidation: [{
      message: 'Please select a role for this User.',
      validate: function validate(inputValue) {
        return inputValue && inputValue.get('id') ? true : false;
      }
    }]
  });
});