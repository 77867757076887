define('coach-central/mirage/factories/redox-test', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({

    //redoxHealthSurvey: association(),
    //redoxCase: association(),

    date: function date() {
      return (0, _utils.randomDate)(new Date(2016, 0, 1), new Date());
    },
    stress: function stress() {
      return (Math.round(Math.random() * 2) + 1).toFixed(2);
    },
    defence: function defence() {
      return (Math.round(Math.random() * 2) + 1).toFixed(2);
    },
    stressThreshold: function stressThreshold() {
      return this.stress - 0.2;
    },
    includeInCdt: function includeInCdt() {
      return Math.random() > 0.3 ? true : false;
    },
    defenceThreshold: function defenceThreshold() {
      return this.defence - 0.2;
    },
    isComplete: function isComplete() {
      return true;
    },
    stressStatus: function stressStatus() {
      var colors = ['green', 'amber', 'red'];
      return colors[(0, _utils.randomInt)(1, 3) - 1];
    },
    defenceStatus: function defenceStatus() {
      var colors = ['green', 'amber', 'red'];
      return colors[(0, _utils.randomInt)(1, 3) - 1];
    },
    afterCreate: function afterCreate(redoxTest, server) {
      server.create('redoxAlertReport', { redoxTest: redoxTest });
    },


    withHealthSurvey: (0, _emberCliMirage.trait)({
      afterCreate: function afterCreate(redoxTest, server) {
        var athlete = server.db.athletes.find(redoxTest.attrs.athleteId);
        server.create('redoxHealthSurvey', {
          redoxTest: redoxTest,
          athleteId: athlete.id });
      }
    })
  });
});