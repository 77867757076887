define('coach-central/controllers/dashboard/team/athlete/redox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      service = Ember.inject.service,
      Controller = Ember.Controller;
  exports.default = Controller.extend({

    /*-------------------------------  Services --------------------------------*/

    router: service(),
    currentUser: service(),

    // attr to be triggered in patchIncludeExclude action down below
    // it fires the athlete-redox-graph.js component didUpdateAttrs hook to redraw graph
    // for some reason redoxTestData won't fire it by itself
    willUpdateGraph: false,
    toggleShowBoolean: false,

    // boolean set to true when loading thresholds for graph to disable next/prev buttons
    isSavingThresholds: false,

    // data passed to athlete-redox-graph.js component
    redoxTestData: computed('model', function () {
      return get(this, 'model');
    }),

    hasRedoxTests: computed('model.length', function () {
      return get(this, 'model.length') === 0 ? false : true;
    }),

    hasStressDefenceValues: computed('redoxTestData', function () {
      var redoxTests = get(this, 'redoxTestData');
      return redoxTests.any(function (d) {
        return d.get('stress') || d.get('defence') || d.get('redoxHealthSurvey.isComplete');
      });
    }),

    hasCrpValues: computed('redoxTestData', function () {
      var redoxTests = get(this, 'redoxTestData');
      return redoxTests.any(function (d) {
        return d.get('crp');
      });
    }),

    hasCkValues: computed('redoxTestData', function () {
      var redoxTests = get(this, 'redoxTestData');
      return redoxTests.any(function (d) {
        return d.get('ck');
      });
    }),

    isShowingData: computed('model', 'toggleShowBoolean', function () {
      var showRedox = get(this, 'currentUser.showRedox');
      var showCRP = get(this, 'currentUser.showCRP');
      var showOSi = get(this, 'currentUser.showOSi');
      var showCK = get(this, 'currentUser.showCK');
      var hasStressDefenceValues = get(this, 'hasStressDefenceValues');
      var hasCrpValues = get(this, 'hasCrpValues');
      var hasCkValues = get(this, 'hasCkValues');

      if ((showRedox || showOSi) && !showCRP && !hasStressDefenceValues && !showCK) {
        return false;
      } else if (!showRedox && showCRP && !hasCrpValues) {
        return false;
      } else if (!showRedox && !showCRP && !showOSi && !showCK) {
        return false;
      } else if (!showRedox && showCK && !hasCkValues) {
        return false;
      } else {
        return true;
      }
    }),

    /*-------------------------- Computed Properties ---------------------------*/

    // UPDATE on 16/11/17 - All redox tests are to be graphed even if there is no data
    // Users can remove these days by editing the associated redox-case
    // At the moment there is no need to remove the non completed tests
    sortedTests: computed('model', 'toggleShowBoolean', function () {
      var showRedox = get(this, 'currentUser.showRedox');
      var showCRP = get(this, 'currentUser.showCRP');
      var showCK = get(this, 'currentUser.showCK');
      if (showRedox && !showCRP && !showCK) {
        return get(this, 'model').sortBy('date').filter(function (d) {
          return d.get('stress') || d.get('defence') || d.get('redoxHealthSurvey.isComplete');
        });
      } else if (!showRedox && showCRP && !showCK) {
        return get(this, 'model').sortBy('date').filter(function (d) {
          return d.get('crp');
        });
      } else if (!showRedox && !showCRP & showCK) {
        return get(this, 'model').sortBy('date').filter(function (d) {
          return d.get('ck');
        });
      } else if (!showRedox && !showCRP && !showCK) {
        return null;
      }
      return get(this, 'model').sortBy('date').filter(function (d) {
        return d.get('stress') || d.get('defence') || d.get('redoxHealthSurvey.isComplete') || d.get('crp') || d.get('ck');
      });
    }),

    currentTest: null, //set by redox route initially

    // the index of the currentTest in the sortedTests -> used to check whether or not to disable the next/previous buttons
    currentTestIndex: computed('currentTest', 'toggleShowBoolean', function () {
      var currentTest = get(this, 'currentTest');
      var sortedTests = get(this, 'sortedTests');

      return sortedTests.indexOf(currentTest);
    }),

    isNextDisabled: computed('currentTest', 'isSavingThresholds', 'toggleShowBoolean', function () {
      var showRedox = get(this, 'currentUser.showRedox');
      var showCRP = get(this, 'currentUser.showCRP');
      var showCK = get(this, 'currentUser.showCK');
      var showOSi = get(this, 'currentUser.showOSi');
      var hasStressDefenceValues = get(this, 'hasStressDefenceValues');
      var hasCrpValues = get(this, 'hasCrpValues');
      var hasCkValues = get(this, 'hasCkValues');

      //else if(!get(this, 'isShowingData')){return true;}
      if (get(this, 'isSavingThresholds')) {
        return true;
      } else if ((showRedox || showOSi) && !showCRP && !hasStressDefenceValues && !showCK) {
        return true;
      } else if (!showRedox && showCRP && !hasCrpValues && !showCK) {
        return true;
      } else if (!showRedox && !showCRP && showCK && !hasCkValues) {
        return true;
      } else if (!showRedox && !showCRP && !showCK) {
        return true;
      }

      var currentTestIndex = get(this, 'currentTestIndex');
      var endIndex = get(this, 'sortedTests.length') - 1;
      return currentTestIndex === endIndex;
    }),

    isPreviousDisabled: computed('currentTest', 'isSavingThresholds', 'toggleShowBoolean', function () {
      var showRedox = get(this, 'currentUser.showRedox');
      var showCRP = get(this, 'currentUser.showCRP');
      var showOSi = get(this, 'currentUser.showOSi');
      var showCK = get(this, 'currentUser.showCK');
      var hasStressDefenceValues = get(this, 'hasStressDefenceValues');
      var hasCrpValues = get(this, 'hasCrpValues');
      var hasCkValues = get(this, 'hasCkValues');

      //else if(!get(this, 'isShowingData')){return true;}
      if (get(this, 'isSavingThresholds')) {
        return true;
      } else if ((showRedox || showOSi) && !showCRP && !hasStressDefenceValues && !showCK) {
        return true;
      } else if (!showRedox && showCRP && !hasCrpValues && !showCK) {
        return true;
      } else if (!showRedox && !showCRP && showCK && !hasCkValues) {
        return true;
      } else if (!showRedox && !showCRP && !showCK) {
        return true;
      }

      var currentTestIndex = get(this, 'currentTestIndex');
      return currentTestIndex === 0;
    }),

    /*------------------------------- Actions ----------------------------------*/

    actions: {
      previousRedoxTest: function previousRedoxTest() {
        return this.advanceTest(-1);
      },

      nextRedoxTest: function nextRedoxTest() {
        return this.advanceTest(1);
      },

      //on any change of showRedox or showCRP this will trigger
      updateToggles: function updateToggles() {
        this.toggleProperty('toggleShowBoolean');
      },


      // called when you click the large circle at the top of the graph from athlete-redox-graph component
      updateCurrentTest: function updateCurrentTest(d3RedoxTest) {
        var currentUser = get(this, 'currentUser');
        var showRedox = currentUser.get('showRedox');
        var showCRP = currentUser.get('showCRP');
        var showCK = currentUser.get('showCK');

        //logic to handle switching toggle
        if (showRedox && !showCRP && !showCK && !d3RedoxTest.get('stress') && !d3RedoxTest.get('defence') && !d3RedoxTest.get('surveyComplete')) {
          d3RedoxTest = get(this, 'latestRedoxResult');
        } else if (!showRedox && !showCK && showCRP && !d3RedoxTest.get('crp')) {
          d3RedoxTest = get(this, 'latestCrpResult');
        } else if (showCK && !d3RedoxTest.get('ck') && !showRedox && !showCRP) {
          d3RedoxTest = get(this, 'latestCkTest');
        } else if (!showRedox && !showCRP && !showCK) {
          d3RedoxTest = null;
        }

        if (d3RedoxTest) {
          var redoxTest = get(this, 'store').peekRecord('redox-test', d3RedoxTest.id);
          this.set('currentTest', redoxTest);
          this.transitionToRoute('dashboard.team.athlete.redox.test', d3RedoxTest.get('id'));
        } else {
          this.transitionToRoute('dashboard.team.athlete.redox.no-tests');
        }
      },
      patchIncludeExclude: function patchIncludeExclude(redoxTestId) {
        var _this = this;

        var redoxTest = get(this, 'store').peekRecord('redox-test', redoxTestId);
        redoxTest.toggleProperty('includeInCdt');
        set(this, 'isSavingThresholds', true);

        redoxTest.save().then(function () {
          get(_this, 'model').reload().then(function () {
            _this.toggleProperty('willUpdateGraph');
            set(_this, 'isSavingThresholds', false);
          });
        });
      }
    },

    /*------------------------------- Functions --------------------------------*/

    advanceTest: function advanceTest(delta) {
      var sortedTests = get(this, 'sortedTests');
      var length = sortedTests.get('length');
      var currentTest = get(this, 'currentTest');
      var index = (sortedTests.indexOf(currentTest) + delta + length) % length;

      set(this, 'currentTest', sortedTests.objectAt(index));
      set(this, 'updateViaDateBtnBool', true);
      return this.transitionToRoute('dashboard.team.athlete.redox.test', sortedTests.objectAt(index).id);
    }

  });
});