define('coach-central/components/case-bar-chart', ['exports', 'd3-selection', 'd3-axis', 'd3-scale', 'd3-time', 'd3-brush', 'd3-zoom', 'd3-array', 'd3-shape', 'd3-ease', 'moment', 'npm:d3-tip'], function (exports, _d3Selection, _d3Axis, _d3Scale, _d3Time, _d3Brush, _d3Zoom, _d3Array, _d3Shape, _d3Ease, _moment, _npmD3Tip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      on = Ember.on,
      $ = Ember.$;
  exports.default = Ember.Component.extend({

    unstructureData: null,
    d3Data: null,
    hasData: false,

    //width:300,
    height: 250,
    margins: { top: 10, right: 10, bottom: 10, left: 10 },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var redoxTests = get(this, 'redoxTests');
      Promise.resolve(redoxTests).then(function (redoxTests) {
        var test = redoxTests.get('firstObject');
        if (test) {
          set(_this2, 'hasData', true);
          set(_this2, 'unstructureData', redoxTests);
          _this2.drawWithTests();
          _this2.positionBars();
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var _this3 = this;

      this._super.apply(this, arguments);
      var redoxTests = get(this, 'redoxTests');
      Promise.resolve(redoxTests).then(function (redoxTests) {
        var test = redoxTests.get('firstObject');
        if (test) {
          set(_this3, 'hasData', true);
          set(_this3, 'unstructureData', redoxTests);
          //this.drawWithTests();

          _this3.restructureData();
          _this3.positionBars();
        }
      });
    },
    drawWithTests: function drawWithTests() {
      this.initialDraw();
    },
    drawWithoutTests: function drawWithoutTests() {
      console.log('nooo');
    },
    initialDraw: function initialDraw() {
      this.restructureData();
      this.setGraphVariables();
      this.clearSVG();
      this.setupAxes();
      this.drawSVG();
      this.drawAxes();
    },
    clearSVG: function clearSVG() {
      (0, _d3Selection.select)('.chart-focus').remove();
    },
    restructureData: function restructureData() {
      var promiseList = [];
      var hasData = get(this, 'hasData');
      var selectedMeasure = get(this, 'selectedMeasure');
      var _this = this;
      //let d3TestData = Ember.A();

      if (hasData) {
        var unstructureData = get(this, 'unstructureData');

        var priority = 0,
            monitor = 0,
            good = 0;
        var crpPriority = 0,
            crpMonitor = 0,
            crpGood = 0;

        unstructureData.forEach(function (redoxTest) {
          var stress = redoxTest.get('stressStatus');
          var defence = redoxTest.get('defenceStatus');
          var crp = redoxTest.get('crpStatus');

          if (stress === 'RED' || defence === 'RED') {
            priority++;
          } else if (stress === 'AMBER' || defence === 'AMBER') {
            monitor++;
          } else if (stress === 'GREEN' || defence === 'GREEN') {
            good++;
          };

          if (crp === 'RED') crpPriority++;else if (crp === 'AMBER') crpMonitor++;else if (crp === 'GREEN') crpGood++;

          // if(selectedMeasure === "Redox"){
          //   let stress = redoxTest.get('stressStatus');
          //   let defence = redoxTest.get('defenceStatus');
          //
          //   if(stress === 'RED' || defence === 'RED'  ){
          //     priority ++
          //   }else if(stress === 'AMBER' || defence === 'AMBER'){
          //     monitor ++
          //   }else if(stress === 'GREEN' || defence === 'GREEN'){
          //     good ++
          //   };
          //
          //   // //delay doesnt matter
          //   // promiseList.push( redoxTest.get('athlete').then(athlete =>{
          //   //   let name = athlete.get('fullName');
          //   //   if(stress === 'RED' || defence === 'RED'  ){
          //   //     console.log(name)
          //   //     priorityList.push(name);
          //   //   }else if(stress === 'AMBER' || defence === 'AMBER'){
          //   //     monitorList.push(name);
          //   //   }else if(stress === 'GREEN' || defence === 'GREEN'){
          //   //     goodList.push(name);
          //   //   };
          //   // }) );
          //
          // }else{
          //   let crp = redoxTest.get('crpStatus');
          //   if(crp === 'RED') priority ++;
          //   else if(crp === 'AMBER') monitor ++;
          //   else if(crp === 'GREEN') good ++;
          // }
        });

        // //make function
        // Promise.all(promiseList).then(()=>{
        //
        //   console.log(priorityList)
        //   set(this, 'priorityList', priorityList);
        //   set(this, 'monitorList', monitorList);
        //   set(this, 'goodList', goodList);
        // });

        var d3TestData = Ember.A([{
          value: "Good",
          redox: good,
          crp: crpGood
        }, {
          value: "Monitor",
          redox: monitor,
          crp: crpMonitor
        }, {
          value: "Priority",
          redox: priority,
          crp: crpPriority
        }]);

        set(this, 'd3Data', d3TestData);
      }
    },
    setGraphVariables: function setGraphVariables() {
      var margins = get(this, 'margins');
      var width = $('.chart-container').width() - margins.left - margins.right;
      var height = get(this, 'height') - margins.top - margins.bottom;

      set(this, 'width', width);
      console.log(width);
      set(this, 'height', height);
    },
    setupAxes: function setupAxes() {
      var d3Data = get(this, 'd3Data');
      var margins = get(this, 'margins');
      var width = get(this, 'width');
      var height = get(this, 'height');

      var chartHeight = height - 30;

      var xScale = (0, _d3Scale.scaleBand)();
      var xScale1 = (0, _d3Scale.scaleBand)();

      var yScale = (0, _d3Scale.scaleLinear)();

      var values = d3Data.map(function (d) {
        return d.value;
      });

      set(this, 'xScale', xScale.rangeRound([20, width - 20]).paddingInner(0.2).domain(values));

      var measures = ["redox", 'crp'];
      set(this, 'xScale1', xScale1.padding(0.1).domain(measures).rangeRound([0, xScale.bandwidth()]));

      set(this, 'yScale', yScale.range([chartHeight, 50]).domain([0, (0, _d3Array.max)(d3Data, function (d) {
        return d.value > 10 ? d.value : 10;
      })]));

      set(this, 'xAxis', (0, _d3Axis.axisBottom)(get(this, 'xScale')).tickArguments([values]).tickSize(0, 0, 0));

      set(this, 'yAxis', (0, _d3Axis.axisLeft)(get(this, 'yScale')).tickArguments([3, "s"]).tickSize(0, 0, 0).tickPadding([-15]));
    },
    drawSVG: function drawSVG() {

      var width = get(this, 'width');
      var height = get(this, 'height');
      var margins = get(this, 'margins');
      var tooltip = get(this, 'tip');
      // Create the main SVG for the graph
      var svg = (0, _d3Selection.select)("#bar-svg").attr("width", width).attr("height", height);

      var chartFocus = svg.append("g").attr("class", "chart-focus").attr("transform", "translate(" + margins.left + "," + margins.top + ")");

      set(this, 'chartFocus', chartFocus);
    },
    drawAxes: function drawAxes() {

      var chartFocus = get(this, 'chartFocus');

      var xAxis = get(this, 'xAxis');
      var yAxis = get(this, 'yAxis');

      var height = get(this, 'height');
      var width = get(this, 'width');
      var chartHeight = height - 30;

      chartFocus.append("g").attr("transform", "translate(0," + 0 + ")").style('font-size', '14px').call(yAxis);

      chartFocus.append("g").attr("class", "axis").attr("transform", "translate(0," + chartHeight + ")").style('font-size', '14px').call(xAxis);

      //label y axisLeft
      chartFocus.append("text").attr("transform", "rotate(-90)").attr("y", 0).attr("x", (-chartHeight - 50) / 2).style("text-anchor", "middle").style('font-size', '12px').text("Player Count");

      chartFocus.selectAll(".tick").each(function (d) {
        if (d === 0) {
          this.remove();
        }
      });
    },
    positionBars: function positionBars() {

      var chartFocus = get(this, 'chartFocus');
      var d3Data = get(this, 'd3Data');

      var xScale = get(this, 'xScale');
      var xScale1 = get(this, 'xScale1');
      var yScale = get(this, 'yScale');

      var width = get(this, 'width');
      var height = get(this, 'height');
      var chartHeight = height - 30;

      //ADD NEW
      // chartFocus.selectAll('#bar')
      // .data(d3Data)
      // .enter().append('rect')
      // .attr("class", "bar-class")
      // .attr("id", "bar-point");


      chartFocus.selectAll('.bar').remove();
      chartFocus.selectAll('.chart-legend').remove();

      var keys = ['redox', 'crp'];

      var z = (0, _d3Scale.scaleOrdinal)().range(["#E91E63", "#536DFE"]);

      chartFocus.append("g").attr('class', 'bar').selectAll("g").data(d3Data).enter().append("g").attr("transform", function (d) {
        return "translate(" + xScale(d.value) + ",0)";
      }).selectAll("rect").data(function (d) {
        var newKeys = [];
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            newKeys.push({ key: key, value: d[key] });
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        console.log(newKeys);
        return newKeys;
      }).enter().append("rect").attr("x", function (d) {
        return xScale1(d.key);
      }).attr("y", chartHeight).attr("width", xScale1.bandwidth()).transition().attr("height", function (d) {
        return chartHeight - yScale(d.value);
      }).attr("y", function (d) {
        return yScale(d.value);
      }).attr("fill", function (d) {
        return z(d.key);
      });

      // chartFocus.selectAll('.bar')
      // .data(d3Data)
      // .enter().append("rect")
      // .attr("class", "bar")
      // .attr("y", height-30)
      // .attr("x", function(d) {
      //   return xScale(d.name)+20;
      // })
      // .transition()
      // .attr("height", function(d) { return height-30 - yScale(d.value); })
      // .attr("y", function(d) {
      //   return yScale(d.value);
      // })
      // .attr("width", xScale.bandwidth()-40)
      // .style("fill", function(d){
      //   if(d.name === "Good"){
      //     return '#46BFBD';
      //   }else if(d.name === "Monitor"){
      //     return '#FDB45C';
      //   }else{
      //     return '#F7464A';
      //   }
      // });


      //axis for now, after rect so line is visible
      chartFocus.append("line").style("stroke", "grey").attr("x1", 20).attr("y1", chartHeight).attr("x2", width - 20).attr("y2", chartHeight);

      chartFocus.append("line").style("stroke", "grey").attr("x1", 20).attr("y1", 50).attr("x2", 20) //
      .attr("y2", chartHeight);

      //.style("padding",40);

      var legend = chartFocus.append("g").attr('class', 'chart-legend').attr("font-family", "sans-serif").attr("font-size", 10).attr("text-anchor", "end").selectAll("g").data(keys.slice().reverse()).enter().append("g").attr("transform", function (d, i) {
        return "translate(0," + i * 20 + ")";
      });

      legend.append("rect").attr("x", width - 10 - 19).attr("width", 19).attr("height", 19).attr("fill", z);

      legend.append("text").attr("x", width - 10 - 24).attr("y", 9.5).attr("dy", "0.32em").text(function (d) {
        return d;
      });
    }
  });
});