define('coach-central/mirage/config', ['exports', 'ember-cli-mirage', 'ember-file-upload/mirage'], function (exports, _emberCliMirage, _mirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    window.server = this;
    // These comments are here to help you get started. Feel free to delete them.

    /*
    Config (with defaults).
     Note: these only affect routes defined *after* them!
    */

    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
    Shorthand cheatsheet:
     this.get('/posts');
    this.post('/posts');
    this.get('/posts/:id');
    this.put('/posts/:id'); // or this.patch
    this.del('/posts/:id');
     http://www.ember-cli-mirage.com/docs/v0.3.x/shorthands/
    */

    // NB: this route must be placed before call to this.namespace
    //   so that it is not scoped by api/v1 prefix
    this.post('/oauth/token', function (db, request) {
      var params = formEncodedToJson(request.requestBody);
      var user = db.users.findBy({ email: params.username }) || {};
      if (params.username === user.email && params.password === "demouser") {
        return {
          "access_token": "PA$$WORD",
          "token_type": "bearer",
          "user_id": user.id,
          "role": "ADMIN",
          "default_client": "demo",
          "team_id": 1
        };
      } else {
        var body = { error: 'invalid_grant', error_description: 'Bad credentials' };
        return new _emberCliMirage.default.Response(400, {}, body);
      }
    });

    this.post('/reset', function () {
      var body = '{ "message": "ok" }';
      return new _emberCliMirage.default.Response(200, {}, body);
    });

    this.post('/reset/token', function () {
      // var params = formEncodedToJson(request.requestBody);
      // let newPassword = params.password;

      var body = '{ "message": "ok" }';
      return new _emberCliMirage.default.Response(200, {}, body);
    });

    this.namespace = 'api/v1';

    this.timing = 400;

    this.get('/queries');
    this.get('/queries/:id');
    this.get('/positions');
    this.get('/positions/:id');
    this.get('/team-groups');
    this.get('/team-groups/:id');
    //this.get('/teams/:id/athletes');
    this.get('/teams');
    this.get('/teams/:id');
    this.get('/clients/:id');
    this.get('/athletes');
    this.get('/athletes/:id');
    //this.get('/redox-tests');

    this.get('/redox-tests', function (db, request) {
      var redoxTests = [];

      if (Object.keys(request.queryParams).length === 0) {
        redoxTests = db.redoxTests.all();
      } else {
        var filteredAthlete = request.queryParams['filter[athlete][id]'];
        var athlete = db.athletes.find(filteredAthlete);
        redoxTests = db.redoxTests.where(function (test) {
          return test.athleteId === athlete.id;
        });
      }
      return redoxTests;
    });

    this.get('/redox-tests/:id');
    this.get('/redox-health-surveys/:id');
    this.get('users');
    this.get('/users/:id');
    this.get('/redox-cases');
    this.get('/redox-cases/:id');
    this.get('hooper-surveys');
    this.get('hooper-surveys/:id');

    this.get('redox-alert-reports');
    this.get('redox-alert-reports/:id');
    this.get('redox-risk-groups');
    this.get('redox-risk-groups/:id');

    this.get('roles');
    this.get('roles/:id');

    this.get('comments');
    this.get('comments/:id');
    //this.get('redox-comments');
    this.get('/redox-comments', function (db, request) {
      var redoxComments = [];

      if (Object.keys(request.queryParams).length === 0) {
        redoxComments = db.redoxComments.all();
      } else {
        var filteredTest = request.queryParams['filter[redoxTest][id]'];
        if (filteredTest) {
          var test = db.redoxTests.find(filteredTest);
          redoxComments = db.redoxComments.where(function (comment) {
            return comment.redoxTestId === test.id;
          });
        } else {
          redoxComments = db.redoxComments.all();
        }
      }
      return redoxComments;
    });

    this.get('redox-comments/:id');

    this.get('user-settings');
    this.get('user-settings/:id');

    this.post('/redox-cases', function (db) {
      var attrs = this.normalizedRequestAttrs();
      attrs.athleteCount = 0;
      attrs.numRedoxTestsComplete = 0;
      attrs.numSurveysComplete = 0;
      return db.redoxCases.create(attrs);
    });

    this.post('/redox-tests', function (db) {
      // update athleteCount for redox-case when posting redox-tests
      var attrs = this.normalizedRequestAttrs();
      var redoxCase = db.redoxCases.find(attrs.redoxCaseId);
      var athleteCount = redoxCase.athleteCount || 0;
      athleteCount += 1;
      redoxCase.update('athleteCount', athleteCount);
      return db.redoxTests.create(attrs);
    });

    this.post('redox-health-surveys');
    this.post('/comments', function (db) {
      // set comments display name to users display name when creating a comment
      var attrs = this.normalizedRequestAttrs();
      attrs.commentByDisplayName = db.users.find(attrs.commentById).displayName;
      return db.comments.create(attrs);
    });

    this.post('redox-comments');
    this.post('players');
    this.post('users');
    this.post('athletes');
    this.post('teams');
    this.post('team-groups');

    this.patch('/redox-cases/:id');

    this.patch('redox-health-surveys/:id', function (db, request) {
      var id = request.params.id;
      var attrs = this.normalizedRequestAttrs();

      var redoxHealthSurvey = db.redoxHealthSurveys.find(id);
      var isSurveyNowComplete = !redoxHealthSurvey.isComplete ? true : false;
      if (isSurveyNowComplete) {
        var redoxTest = db.redoxTests.find(redoxHealthSurvey.redoxTestId);
        var redoxCase = db.redoxCases.find(redoxTest.redoxCaseId);
        var numSurveysComplete = redoxCase.numSurveysComplete || 0;
        numSurveysComplete += 1;
        redoxCase.update('numSurveysComplete', numSurveysComplete);
      }

      return db.redoxHealthSurveys.find(id).update(attrs);
    });

    this.patch('comments/:id');
    this.patch('user-settings/:id');
    this.patch('players/:id');
    this.patch('users/:id');
    this.patch('athletes/:id');
    this.patch('teams/:id');
    this.patch('team-groups/:id');

    this.del('redox-tests/:id', function (db, request) {
      // reduce athleteCount by 1 if removing a test
      var id = request.params.id;
      var redoxTest = db.redoxTests.find(id);
      var redoxCase = db.redoxCases.find(redoxTest.redoxCaseId);
      var athleteCount = redoxCase.athleteCount || 0;
      athleteCount -= 1;

      // update redox-case numRedoxTestsComplete if necessary
      var isTestComplete = redoxTest.stress || redoxTest.defence ? true : false;
      if (isTestComplete) {
        var numRedoxTestsComplete = redoxCase.numRedoxTestsComplete || 0;
        numRedoxTestsComplete -= 1;
        redoxCase.update('numRedoxTestsComplete', numRedoxTestsComplete);
      }

      // also delete the associated redox-health-survey
      var redoxHealthSurvey = db.redoxHealthSurveys.find(redoxTest.redoxHealthSurveyId);
      var isSurveyComplete = redoxHealthSurvey.isComplete ? true : false;
      if (isSurveyComplete) {
        var numSurveysComplete = redoxCase.numSurveysComplete || 0;
        numSurveysComplete -= 1;
        redoxCase.update('numSurveysComplete', numSurveysComplete);
      }
      db.redoxHealthSurveys.find(id).destroy();

      redoxCase.update('athleteCount', athleteCount);
      db.redoxTests.find(id).destroy();
    });

    this.patch('redox-tests/:id', function (db, request) {
      var id = request.params.id;
      var attrs = this.normalizedRequestAttrs();
      var redoxTestInDB = db.redoxTests.find(id);

      // update redox-case numRedoxTestsComplete if necessary
      var isTestComplete = redoxTestInDB.stress || redoxTestInDB.defence ? true : false;
      if (!isTestComplete) {
        var redoxCase = db.redoxCases.find(redoxTestInDB.redoxCaseId);
        var numRedoxTestsComplete = redoxCase.numRedoxTestsComplete || 0;
        numRedoxTestsComplete += 1;
        redoxCase.update('numRedoxTestsComplete', numRedoxTestsComplete);
      }

      // change all critical defence/stress threshold values to simulate the recalculating
      db.redoxTests.all().models.forEach(function (redoxTest) {
        redoxTest.update({ defenceThreshold: Math.random(), stressThreshold: Math.random() * 4 });
      });

      attrs.stressStatus = "GREEN";
      attrs.defenceStatus = "RED";
      attrs.defenceThreshold = 2.2;
      attrs.stressThreshold = 3.1;

      //return the one that was patched
      return db.redoxTests.find(id).update(attrs);
    });

    // this.get('comment-redoxes')
    // this.post('comment-redoxes');
    // this.patch('comment-redoxes/:id');

    // ignore any gets that might be made by image cropper on faker data
    this.passthrough('https://s3.amazonaws.com/**');
    this.passthrough('https://d3j2s6hdd6a7rg.cloudfront.net/**'); //liverpool logo
    this.passthrough('http://d10bo0mevst0d3.cloudfront.net/**'); //man u logo

    this.post('/images/upload', (0, _mirage.upload)(function (db, file) {
      // let { name: filename, size: filesize, url } = file;
      var url = file.url;
      // let photo = db.create('photo', {
      //   filename,
      //   filesize,
      //   url,
      //   uploadedAt: new Date()
      // });

      return url;
    }));

    function formEncodedToJson(encoded) {
      var result = {};
      encoded.split("&").forEach(function (part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    }
  };
});