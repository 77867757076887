define('coach-central/routes/settings/preferences', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      Route = Ember.Route,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Route.extend({

    currentUser: service(),

    model: function model() {
      //let store = get(this, 'store');
      return RSVP.hash({
        teams: get(this, 'currentUser.user.teams')
        //store.findAll('team', { include: 'client'}),
        //clients: store.findAll('client')
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.get('currentUser.user.isSaving')) {
          this.send('showApplicationToast', 'Please wait until Team change has finished.');
          transition.abort();
        }
      }
    }

  });
});