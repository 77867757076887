define('coach-central/mirage/factories/redox-case', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    redoxCaseName: function redoxCaseName(i) {
      return 'Test Case ' + i;
    },
    redoxCaseDate: function redoxCaseDate() {
      return _emberCliMirage.faker.date.past(1);
    }
  });
});