define('coach-central/controllers/settings/preferences', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      Logger = Ember.Logger,
      computed = Ember.computed;
  exports.default = Ember.Controller.extend({
    currentUser: service(),

    sortedTeams: computed('model.teams', function () {
      return get(this, 'model.teams').sortBy('name');
    }),

    actions: {
      changeClientTeam: function changeClientTeam(team) {
        var _this = this;

        var user = get(this, 'currentUser.user');

        set(user, 'currentTeam', team);

        try {
          user.save().then(function () {
            _this.send('showApplicationToast', 'Team has been Changed');
          }).catch(function () {
            _this.transitionToRoute('errorPage');
          });
        } catch (e) {
          Logger.log(e);
        }
      },
      updateWeightPreference: function updateWeightPreference(preference) {
        var settingsId = get(this, 'currentUser.user.userSettings.id');

        get(this, 'store').findRecord('user-setting', settingsId).then(function (userSetting) {
          userSetting.set('weightUnitPref', preference);
          userSetting.save();
        });
      },
      updateHeightPreference: function updateHeightPreference(preference) {
        var settingsId = get(this, 'currentUser.user.userSettings.id');

        get(this, 'store').findRecord('user-setting', settingsId).then(function (userSetting) {
          userSetting.set('heightUnitPref', preference);
          userSetting.save();
        });
      }
    }
  });
});