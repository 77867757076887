define('coach-central/routes/redox-cases/redox-case/redox-test', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var store = get(this, 'store');
      var peek = store.peekRecord('redoxTest', params.redox_test_id);
      return peek ? peek : store.findRecord('redoxTest', params.redox_test_id);
    },


    actions: {
      // willTransition() {
      //   console.log(this.controller.get('model.redoxCase'))
      //   this.controller.get('model.redoxCase').reload();
      // },

      error: function error(_error /*,transition*/) {
        if (_error.errors[0].status === '404') {
          this.transitionTo('redox-cases');
        } else {
          return true;
        }
      }
    }
  });
});