define('coach-central/controllers/reset-password', ['exports', 'coach-central/config/environment', 'ember-ajax/errors'], function (exports, _environment, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      service = Ember.inject.service;


  var unexpectedErrorMsg = "An unexpected error has occured.";
  var invalidTokenMsg = "Invalid token, please ensure the link is correct.";
  var expiredTokenMsg = "Your token has expired.";

  exports.default = Ember.Controller.extend({
    /* ---------------------------- Services -----------------------------------*/
    ajax: service(),
    passwordStrength: service(),

    /* --------------------------- Properties ----------------------------------*/
    minPasswordLength: 8,
    newPassword: null,
    confirmPassword: null,
    errorMessage: null,
    showPasswordConfirmation: false,
    queryParams: ['token'],

    passwordInfoVisible: false,
    isTokenPresent: computed.bool('token'),

    passwordsMatch: computed('newPassword', 'confirmPassword', function () {
      return get(this, 'newPassword') === get(this, 'confirmPassword');
    }),

    passwordObject: computed('newPassword', function () {
      var passwordStrength = get(this, 'passwordStrength');
      var password = get(this, 'newPassword');
      if (password) {
        var passwordObject = passwordStrength.strengthSync(password);
        return passwordObject;
      }
      return null;
    }),

    //suggestions & warning currently not used
    passwordSuggestions: computed('passwordObject', function () {
      var passwordSuggestions = this.get('passwordObject.feedback.suggestions');
      if (passwordSuggestions) {
        return passwordSuggestions.length > 0 ? passwordSuggestions : null;
      }
      return null;
    }),

    passwordSuggestion: computed('passwordObject', function () {
      var passwordObject = this.get('passwordObject');
      return passwordObject ? passwordObject.feedback.suggestions : null;
    }),

    passwordWarning: computed('passwordObject', function () {
      var passwordObject = this.get('passwordObject');
      return passwordObject ? passwordObject.feedback.warning : null;
    }),

    passwordScore: computed('passwordObject', function () {
      var passwordObject = get(this, 'passwordObject');
      return passwordObject ? passwordObject.score : null;
    }),

    tickColor: computed('passwordScore', function () {
      return get(this, 'passwordObject.score') > 2 ? 'password-green' : null;
    }),

    confirmTickColor: computed('passwordsMatch', function () {
      return get(this, 'passwordsMatch') && get(this, 'passwordObject.score') > 2 ? 'password-green' : null;
    }),

    //and length
    isSubmitDisabled: computed('passwordsMatch', 'passwordScore', function () {
      return get(this, 'passwordsMatch') && get(this, 'passwordObject.score') > 2 ? false : true;
    }),

    /* ---------------------------- Actions ------------------------------------*/

    actions: {
      resetPassword: function resetPassword() {
        var passwordsMatch = get(this, 'passwordsMatch');
        var passwordScore = get(this, 'passwordObject.score');

        if (passwordScore > 2) {
          passwordsMatch === true ? this.postNewPassword() : this.showErrorMsg('Passwords do not match');
        } else {
          this.showErrorMsg('Password is not strong enough');
        }
        this.clearPasswordInputs();
      },
      transitionToLogin: function transitionToLogin() {
        this.transitionToRoute('login');
      },


      //CHANGE THSE
      showPasswordInfo: function showPasswordInfo() {
        set(this, 'passwordInfoVisible', true);
      },
      hidePasswordInfo: function hidePasswordInfo() {
        set(this, 'passwordInfoVisible', false);
      },
      updateNewPassword: function updateNewPassword(password) {
        set(this, 'newPassword', password);
      },
      updateConfirmPassword: function updateConfirmPassword(password) {
        set(this, 'confirmPassword', password);
      }
    },

    /* ---------------------------- Functions ----------------------------------*/

    showErrorMsg: function showErrorMsg(errorMessage) {
      set(this, 'errorMessage', errorMessage);
    },
    postNewPassword: function postNewPassword() {
      var _this = this;

      this.showLoadingIcon();

      var _getProperties = this.getProperties('token', 'newPassword'),
          token = _getProperties.token,
          newPassword = _getProperties.newPassword;

      var oauthHeader = 'Basic ' + btoa(_environment.default.oauth.clientId + ':');

      get(this, 'ajax').post(_environment.default.JSONAPIAdapter.host + '/reset/token', {
        contentType: 'application/x-www-form-urlencoded',
        data: {
          'token': token,
          'password': newPassword
        },
        headers: {
          'authorization': oauthHeader
        }
      }).then(function (response) {
        _this.handleSuccessResponse(response);
      }).catch(function (error) {
        _this.handleErrorResponse(error);
      });
    },
    handleSuccessResponse: function handleSuccessResponse(response) {
      this.removeLoadingIcon();

      if (response.message) {
        this.showConfirmation();
      }
    },
    handleErrorResponse: function handleErrorResponse(error) {
      this.removeLoadingIcon();

      var errorMsg = error.payload.message;

      if ((0, _errors.isUnauthorizedError)(error)) {
        if (errorMsg === "Invalid token") {
          this.showErrorMsg(invalidTokenMsg);
        } else if (errorMsg === "Token expired") {
          this.showErrorMsg(expiredTokenMsg);
        } else if (errorMsg === "Invalid authorisation header") {
          this.showErrorMsg(unexpectedErrorMsg);
        }
        return;
      }

      if ((0, _errors.isAjaxError)(error)) {
        // handle all other errors
        this.showErrorMsg(unexpectedErrorMsg);
        return;
      }
    },
    clearPasswordInputs: function clearPasswordInputs() {
      set(this, 'newPassword', null);
      set(this, 'confirmPassword', null);
    },
    showConfirmation: function showConfirmation() {
      set(this, 'showPasswordConfirmation', true);
    },
    showLoadingIcon: function showLoadingIcon() {
      set(this, 'showLoading', true);
    },
    removeLoadingIcon: function removeLoadingIcon() {
      set(this, 'showLoading', false);
    }
  });
});