define('coach-central/controllers/admin/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      service = Ember.inject.service;
  exports.default = Controller.extend({

    currentUser: service(),

    actions: {
      editUser: function editUser(user) {
        var isMe = get(this, 'currentUser.user.id') === user.id ? true : false;
        var isEditable = get(user, 'isVendor') && !isMe && !get(this, 'currentUser.user.isVendor') ? false : true;
        if (isEditable) {
          this.transitionToRoute("admin.users.edit", user.id);
        } else {
          this.send('showApplicationToast', "You do not have permission to edit this user.");
        }
      }
    }
  });
});