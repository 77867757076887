define('coach-central/components/form-group-edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      computed = Ember.computed;
  exports.default = Component.extend({
    newGroup: false,

    saveDisabled: computed('changeset.isValid', 'changeset.isDirty', 'isSaving', 'model.isSaving', function () {
      if (get(this, 'changeset.isValid') && get(this, 'changeset.isDirty') &&
      // (get(this, 'changeset.team.id')) &&
      !get(this, 'model.isSaving') && !get(this, 'isSaving')) {
        return false;
      }
      return true;
    }),

    actions: {}
  });
});