define('coach-central/routes/redox-cases/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP,
      service = Ember.inject.service,
      get = Ember.get;
  exports.default = Ember.Route.extend({

    currentUser: service(),

    model: function model() {
      return RSVP.hash({
        newRedoxCase: this.get('store').createRecord('redoxCase', {
          redoxCaseDate: new Date(),
          team: get(this, 'currentUser.user.currentTeam')
        }),
        athletes: get(this, 'currentUser.user.currentTeam').get('athletes')
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('submitRedoxCaseCreate.isRunning')) {
          this.controller.set('preventLeavingMsg', 'Please wait while the case is saving.');
          transition.abort();
        } else {
          return true;
        }
      }
    }
  });
});