define('coach-central/controllers/settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = Ember.inject,
      computed = Ember.computed,
      Controller = Ember.Controller;
  exports.default = Controller.extend({

    router: inject.service(),
    currentRouteName: computed.reads('router.currentRouteName')

  });
});