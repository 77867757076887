define('coach-central/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    //transition between paper-tabs
    this.transition(this.toValue(function (toValue, fromValue) {
      return toValue.tab > fromValue.tab;
    }), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('admin.athletes.index'), this.toRoute('admin.athletes.edit'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('admin.athletes.index'), this.toRoute('admin.athletes.new'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('admin.users.index'), this.toRoute('admin.users.edit'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('admin.users.index'), this.toRoute('admin.users.new'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('athlete'), this.toRoute('athlete.redox.index'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('athlete'), this.toRoute('athlete.gps'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.withinRoute('dashboard.team.athlete.redox.index'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromModel('athlete'), this.toModel('athlete'), this.use('toLeft'), this.reverse('toRight'));
    this.transition(this.fromRoute('dashboard.team.index'), this.toRoute('dashboard.team.athlete.redox.index'), this.use('toLeft'), this.reverse('toRight'));

    this.transition(this.fromRoute('redox-cases.index'), this.toRoute('redox-cases.redox-case'), this.use('toLeft'), this.reverse('toRight'));

    // add a new redox case
    this.transition(this.fromRoute('redox-cases.index'), this.toRoute('redox-cases.new'), this.use('toDown'), this.reverse('toUp'));

    // view athlete redox test from redox case
    this.transition(this.fromRoute('redox-cases.redox-case.index'), this.toRoute('redox-cases.redox-case.redox-test'), this.use('toLeft'), this.reverse('toRight'));

    // this.transition(
    //   this.fromRoute('dashboard'),
    //   this.toRoute('athlete'),
    //   this.use('explode',
    //     {
    //       matchBy: 'athlete-avatar-image',
    //       use: ['concurrent',
    //               ['scroll-to-top', { duration: settingsDuration, easing: 'ease-in' }],
    //               ['fly-to', { duration: settingsDuration, easing: 'ease-in' }]
    //             ]
    //     },
    //     {
    //       use: ['to-left', { duration: settingsDuration * 0.8, delay: settingsDuration * 0.1}]
    //     }
    //   )
    // );
  };
});