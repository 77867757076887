define('coach-central/routes/admin/athletes/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend({

    currentUser: service(),

    model: function model() {
      return get(this, 'currentUser.user.currentTeam.athletes').then(function (athletes) {
        return athletes.sortBy('firstName', 'lastName');
      });
    }
  });
});