define('coach-central/authenticators/oauth2', ['exports', 'ember-simple-auth/authenticators/oauth2-password-grant', 'coach-central/config/environment'], function (exports, _oauth2PasswordGrant, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.oauth.serverTokenEndpoint,
    serverTokenRevocationEndpoint: _environment.default.oauth.serverTokenRevocationEndpoint,
    refreshAccessTokens: _environment.default.oauth.refreshAccessTokens,
    clientId: _environment.default.oauth.clientId
  });
});