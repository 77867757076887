define('coach-central/components/athlete-redox-graph', ['exports', 'd3-selection', 'd3-axis', 'd3-scale', 'd3-time', 'd3-brush', 'd3-zoom', 'd3-array', 'd3-shape', 'd3-ease', 'moment', 'npm:d3-tip'], function (exports, _d3Selection, _d3Axis, _d3Scale, _d3Time, _d3Brush, _d3Zoom, _d3Array, _d3Shape, _d3Ease, _moment, _npmD3Tip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      computed = Ember.computed,
      $ = Ember.$,
      Component = Ember.Component,
      run = Ember.run,
      on = Ember.on,
      observer = Ember.observer,
      service = Ember.inject.service;
  exports.default = Component.extend({
    /*-------------------------------- Services --------------------------------*/
    currentUser: service(),

    /*-------------------------- Graph Variables -------------------------------*/
    //BOOLEANS
    cdtLoading: false,
    isIncludeExcludeEventSetup: false,
    isGraphOpen: true,

    showOSi: computed.alias('currentUser.showOSi'), // OSi is stress/defence ratio
    showRedox: computed.alias('currentUser.showRedox'),
    showThreshold: computed.alias('currentUser.showThreshold'),
    showCRP: computed.alias('currentUser.showCRP'),
    showCK: computed.alias('currentUser.showCK'), //

    //FUNCTIONS
    tip: (0, _npmD3Tip.default)(),
    timeoutTimer: null,

    //NUMBERS
    currentTestId: null,
    displayTooltipTimer: 500, // milli-seconds
    transitionDuration: 300, // milli-seconds
    quickTransitionDuration: 100,
    amberZone: 0.1,
    wndwHeight: window.innerHeight,
    margins: { top: 100, right: 20, bottom: 45, left: 20 },
    maxHeight: 400,
    minHeight: 140,
    ctxHeight: 40,
    textOverlapThreshold: 35,
    crpTextOverlapThreshold: 45,
    showThresholdCount: 0,

    //STRINGS
    update: 'all', //can be 'all', 'redoxTests', 'CDTs'
    openCloseIcon: "angle-double-up",
    toastMessage: null,

    initResizeObserver: on("init", function () {
      var _this2 = this;

      $(window).on('resize.redoxNamespace', function () {
        return _this2.resizeHandler();
      });
    }),

    OSiObserver: observer("showOSi", function () {
      this.rescaleYAxis();
      this.displayCtxText();
      this.showAttributePoints();
      this.updateToggles();

      var showOSi = get(this, 'showOSi');
      showOSi === true ? this.showOSiElements() : this.removeOSiElements();
    }),

    showOSiElements: function showOSiElements() {
      (0, _d3Selection.select)('#OSi-line').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#OSi-point').attr('opacity', 1);
    },
    removeOSiElements: function removeOSiElements() {
      (0, _d3Selection.select)('#OSi-line').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#OSi-point').attr('opacity', 0);
    },


    //mimic for redox
    redoxObserver: observer("showRedox", function () {
      this.rescaleYAxis();
      this.displayCtxText();
      this.showAttributePoints();
      this.updateToggles();

      //make more presentable
      var d3RedoxData = get(this, 'd3RedoxData');
      var currentTest = d3RedoxData.findBy('id', get(this, 'currentTest.id'));
      this.updateCurrentTest(currentTest);
      this.highlightCurrentTest(currentTest);

      var showRedox = get(this, 'showRedox');
      showRedox === true ? this.showRedoxElements() : this.removeRedoxElements();
    }),

    showRedoxElements: function showRedoxElements() {
      (0, _d3Selection.selectAll)('#stress-point').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#defence-point').attr('opacity', 1);

      (0, _d3Selection.select)('#stress-line').attr('opacity', 1);
      (0, _d3Selection.select)('#defence-line').attr('opacity', 1);

      (0, _d3Selection.select)('#stress-line-adjacent').attr('opacity', 1);
      (0, _d3Selection.select)('#defence-line-adjacent').attr('opacity', 1);

      set(this, 'showThreshold', true);
    },
    removeRedoxElements: function removeRedoxElements() {
      (0, _d3Selection.selectAll)('#stress-point').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#defence-point').attr('opacity', 0);

      (0, _d3Selection.select)('#stress-line').attr('opacity', 0);
      (0, _d3Selection.select)('#defence-line').attr('opacity', 0);

      (0, _d3Selection.select)('#stress-line-adjacent').attr('opacity', 0);
      (0, _d3Selection.select)('#defence-line-adjacent').attr('opacity', 0);
    },


    showRedoxThresholdToggle: computed('showThresholdCount', function () {
      return get(this, 'showThresholdCount') > 2 ? true : false;
    }),

    //mimic for redox thresholds
    thresholdObserver: observer("showThreshold", function () {
      var showThreshold = get(this, 'showThreshold');
      showThreshold === true ? this.showThresholdElements() : this.removeThresholdElements();
    }),

    showThresholdElements: function showThresholdElements() {
      (0, _d3Selection.select)('#stress-line-adjacent').attr('opacity', 1);
      (0, _d3Selection.select)('#defence-line-adjacent').attr('opacity', 1);
    },
    removeThresholdElements: function removeThresholdElements() {
      (0, _d3Selection.select)('#stress-line-adjacent').attr('opacity', 0);
      (0, _d3Selection.select)('#defence-line-adjacent').attr('opacity', 0);
    },


    //mimiced for crp, repeated code
    CRPObserver: observer("showCRP", function () {
      this.rescaleYAxis();
      this.displayCtxText();
      this.showAttributePoints();
      this.updateToggles();

      //make more presentable
      var d3RedoxData = get(this, 'd3RedoxData');
      var currentTest = d3RedoxData.findBy('id', get(this, 'currentTest.id'));
      this.updateCurrentTest(currentTest);
      this.highlightCurrentTest(currentTest);

      var showCRP = get(this, 'showCRP');
      showCRP === true ? this.showCRPElements() : this.removeCRPElements();
    }),

    showCRPElements: function showCRPElements() {
      (0, _d3Selection.select)('#crp-line').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#crp-point').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#crp-point-icon').attr('opacity', 1);
    },
    removeCRPElements: function removeCRPElements() {
      (0, _d3Selection.select)('#crp-line').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#crp-point').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#crp-point-icon').attr('opacity', 0);
    },


    //mimiced for ck, repeated code
    CKObserver: observer("showCK", function () {
      this.rescaleYAxis();
      this.displayCtxText();
      this.showAttributePoints();
      this.updateToggles();

      //make more presentable
      var d3RedoxData = get(this, 'd3RedoxData');
      var currentTest = d3RedoxData.findBy('id', get(this, 'currentTest.id'));
      this.updateCurrentTest(currentTest);
      this.highlightCurrentTest(currentTest);

      var showCK = get(this, 'showCK');
      showCK === true ? this.showCKElements() : this.removeCKElements();
    }),

    showCKElements: function showCKElements() {
      (0, _d3Selection.select)('#ck-line').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#ck-point').attr('opacity', 1);
      (0, _d3Selection.selectAll)('#ck-point-icon').attr('opacity', 1);
    },
    removeCKElements: function removeCKElements() {
      (0, _d3Selection.select)('#ck-line').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#ck-point').attr('opacity', 0);
      (0, _d3Selection.selectAll)('#ck-point-icon').attr('opacity', 0);
    },
    resizeHandler: function resizeHandler() {
      this.setGraphVariables();
      var width = get(this, 'width');
      var height = get(this, 'height');

      (0, _d3Selection.select)('#redox-svg').remove();
      (0, _d3Selection.select)('#svg-container').append('svg').attr('id', 'redox-svg').attr('width', width).attr('height', height);

      get(this, 'hasRedoxTests') === true ? this.drawWithTests() : this.drawWithoutTests();
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      get(this, 'hasRedoxTests') === true ? this.drawWithTests() : this.drawWithoutTests();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var update = get(this, 'update');
      get(this, 'updateViaDateBtnBool') ? this.updateGraph('redoxTestsDateBtn') : this.updateGraph(update);
    },
    restructureData: function restructureData() {
      var hasRedoxTests = get(this, 'hasRedoxTests');
      var d3RedoxData = Ember.A();
      var d3RedoxLineData = Ember.A();
      var d3CrpLineData = Ember.A();
      var d3CkLineData = Ember.A();
      var showThresholdCount = 0;
      if (hasRedoxTests) {
        var unstructureData = get(this, 'data');
        unstructureData.forEach(function (d) {

          var osi = isFinite(d.get('stress') / d.get('defence')) ? (d.get('stress') / d.get('defence')).toFixed(2) : 0;
          // Only graph the tests that have been completed
          // TODO: should there be some isComplete attribute
          // if(d.get("isComplete")) {
          d3RedoxData.pushObject(Ember.Object.create({
            "id": d.get('id'),
            "date": d.get('date'),
            "defence": d.get('defence'),
            "defenceStatus": d.get('defenceStatus'),
            "includeInCdt": d.get('includeInCdt'),
            "defenceThreshold": d.get('defenceThreshold'),
            "stress": d.get('stress'),
            "stressThreshold": d.get('stressThreshold'),
            "OSi": osi,
            "stressStatus": d.get('stressStatus'),
            "timeKey": d.get('date').getTime(),
            "crp": d.get('crp'),
            "crpStatus": d.get('crpStatus'),
            "crpThreshold": d.get('crpThreshold'),
            "ck": d.get('ck'),
            "ckStatus": d.get('ckStatus'),
            "ckThreshold": d.get('ckThreshold'),
            "surveyComplete": d.get('redoxHealthSurvey.isComplete') //health survey
          }));

          //lines interfer with each other
          if (d.get('stress') || d.get('defence')) {
            d3RedoxLineData.pushObject(Ember.Object.create({
              "id": d.get('id'),
              "date": d.get('date'),
              "defence": d.get('defence'),
              "defenceStatus": d.get('defenceStatus'),
              "includeInCdt": d.get('includeInCdt'),
              "defenceThreshold": d.get('defenceThreshold'),
              "stress": d.get('stress'),
              "stressThreshold": d.get('stressThreshold'),
              "OSi": osi,
              "stressStatus": d.get('stressStatus'),
              "timeKey": d.get('date').getTime()
            }));
          }

          if (d.get('crp')) {
            d3CrpLineData.pushObject(Ember.Object.create({
              "id": d.get('id'),
              "date": d.get('date'),
              "crp": d.get('crp'),
              "crpStatus": d.get('crpStatus'),
              "crpThreshold": d.get('crpThreshold'),
              "timeKey": d.get('date').getTime()
            }));
          }

          if (d.get('ck')) {
            d3CkLineData.pushObject(Ember.Object.create({
              "id": d.get('id'),
              "date": d.get('date'),
              "ck": d.get('ck'),
              "ckStatus": d.get('ckStatus'),
              "ckThreshold": d.get('ckThreshold'),
              "timeKey": d.get('date').getTime()
            }));
          }

          if ((d.get('stress') || d.get('defence')) && d.get('includeInCdt')) {
            showThresholdCount++;
          }

          //  }
        });
      } else {
        d3RedoxData.pushObject(Ember.Object.create({ // dummy object for graph if theres no tests
          "id": -1,
          "date": new Date(),
          "defence": null,
          "includeInCdt": false,
          "defenceThreshold": null,
          "stress": null,
          "stressThreshold": null,
          "timeKey": new Date().getTime(),
          "crp": null,
          "ck": null,
          "crpThreshold": null
        }));
      }

      // //inclusion of crp caused break in stress / defence lines
      // reason have 3 arrays - is best approach?
      // Sort by date or else the lines go all over the place
      d3RedoxData.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });
      d3RedoxLineData.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });
      d3CrpLineData.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });
      d3CkLineData.sort(function (a, b) {
        return a.date.getTime() - b.date.getTime();
      });

      //i set this twice, once in controller??
      var latestCrpResult = d3CrpLineData[d3CrpLineData.length - 1];
      var latestRedoxResult = d3RedoxLineData[d3RedoxLineData.length - 1];
      var latestCkResult = d3CkLineData[d3CkLineData.length - 1];

      set(this, 'showThresholdCount', showThresholdCount);
      set(this, 'latestRedoxResult', latestRedoxResult);
      set(this, 'latestCrpResult', latestCrpResult);
      set(this, 'latestCkResult', latestCkResult);
      set(this, 'd3RedoxData', d3RedoxData);
      set(this, 'd3RedoxLineData', d3RedoxLineData);
      set(this, 'd3CrpLineData', d3CrpLineData);
      set(this, 'd3CkLineData', d3CkLineData);
    },
    drawWithTests: function drawWithTests() {
      this.initialDraw();

      this.drawXAxisCircles();
      //this.positionWeeklyCircles();
      this.positionLargeCircles();
      //this.positionShadesAreas();
      this.positionStressDefencePlots();
      this.positionStressDefencePoints();
      this.positionCRPPlots();
      this.positionCRPPoints();
      this.positionCKPlots();
      this.positionCKPoints();
      this.showAttributePoints();
      this.setZoom();
      this.setCenter();
      this.transitionCenter();
      this.setInitialHighlightedTest();
      this.checkCRPOnly();
      this.checkCKOnly();
    },
    drawWithoutTests: function drawWithoutTests() {
      this.initialDraw();

      this.setZoom();
      this.setDefaultCenter();
      this.transitionCenter();
    },


    //this is called regardless if the athlete has redoxTests or not on initial render!
    initialDraw: function initialDraw() {
      this.restructureData();
      this.setGraphVariables();
      this.setupAxes();
      this.setupLines();
      this.drawSVG();
      this.setupTooltip();
      this.setupBrush();
      this.setupZoom();
      this.setupClippaths();
      this.setupLinearGradients();
      this.drawXAxis();
      this.drawYAxis();
      this.drawCkAxis();
      this.drawAllGroups();
    },
    checkCRPOnly: function checkCRPOnly() {
      if (get(this, 'latestCrpResult') && !get(this, 'latestRedoxResult') && !get(this, 'latestCkResult')) {
        set(this, 'showCRP', true);
      }
    },
    checkCKOnly: function checkCKOnly() {
      if (get(this, 'latestCkResult') && !get(this, 'latestRedoxResult') && !get(this, 'latestCrpResult')) {
        set(this, 'showCK', true);
      }
    },


    /*------------------------- Setup Graph Functions --------------------------*/
    setGraphVariables: function setGraphVariables() {
      var d3RedoxData = get(this, 'd3RedoxData');

      //DIMENSIONS
      var widthSubtract = get(this, 'wndwWidth') ? 100 : 200; //handle initial draw
      set(this, 'wndwWidth', $('#graph_details').width() - widthSubtract); //TODO edit this for better fitting);
      set(this, 'mainChartBorder', 0);
      set(this, 'openHeight', Math.max(get(this, 'wndwHeight') / 8, get(this, 'maxHeight')));
      set(this, 'closedHeight', get(this, 'minHeight'));
      set(this, 'width', get(this, 'wndwWidth') - 2 * get(this, 'mainChartBorder') - get(this, 'margins.left') - get(this, 'margins.right'));
      set(this, 'height', get(this, 'openHeight') - get(this, 'margins.top') - get(this, 'margins.bottom'));
      set(this, 'xl', get(this, 'width') / 2);
      set(this, 'xr', get(this, 'width') - 200);

      // Start the graph x months before and after tests
      set(this, 'graphStartDate', (0, _moment.default)(d3RedoxData.get('firstObject').date).subtract(4, 'months'));
      set(this, 'graphEndDate', (0, _moment.default)(d3RedoxData.get('lastObject').date).add(4, 'months'));
    },
    setupAxes: function setupAxes() {
      var xScale = (0, _d3Scale.scaleTime)();
      var yScale = (0, _d3Scale.scaleLinear)();
      var ckScale = (0, _d3Scale.scaleLinear)();
      var xContext = (0, _d3Scale.scaleTime)();

      set(this, 'xScale', xScale.range([0, get(this, 'width')]).domain([get(this, 'graphStartDate'), get(this, 'graphEndDate')]));

      set(this, 'yScale', yScale.range([get(this, 'height'), 0]).domain([0, 4]));

      set(this, 'ckScale', ckScale.range([get(this, 'height'), 0]).domain([0, 800]));

      set(this, 'xContext', xContext.range([0, get(this, 'width')]).domain([get(this, 'graphStartDate'), get(this, 'graphEndDate')]));

      set(this, 'xAxis', (0, _d3Axis.axisBottom)(get(this, 'xScale')).tickValues([]));

      set(this, 'yAxis', (0, _d3Axis.axisLeft)(get(this, 'yScale')).tickArguments([4, "s"]).tickSize(-get(this, 'width'), 0, 0).tickPadding([-16]));

      set(this, 'ckAxis', (0, _d3Axis.axisLeft)(get(this, 'ckScale')).tickFormat(function () {
        return [];
      }));
    },
    setupLines: function setupLines() {
      var xScale = get(this, 'xScale');
      var yScale = get(this, 'yScale');
      var ckScale = get(this, 'ckScale');
      var amberZone = get(this, 'amberZone');

      set(this, 'stressLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d) {
        return d.stress > 0.01;
      }).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return yScale(d.stress);
      }));

      set(this, 'stressAdjLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d, i, arr) {
        if (arr.length === 3) {
          return i !== 0;
        }
        return i > 1;
      }).x(function (d, i, arr) {
        if (arr.length === 3) {
          // special case to show line if 3 entries
          if (i === 1) {
            return xScale(new Date(arr[2].date.getTime() - 86400000 * 1.5)); // one & half day
          } else if (i === 2) {
            return xScale(new Date(arr[2].date.getTime() + 86400000 * 1.5));
          }
        }
        return xScale(d.date);
      }).y(function (d, i, arr) {
        if (arr.length === 3 && i === 1) {
          return yScale(arr[2].stressThreshold);
        }
        return yScale(d.stressThreshold);
      }));

      set(this, 'stressAmberArea', (0, _d3Shape.area)().curve(_d3Shape.curveMonotoneX).defined(function (d, i) {
        return i != 0;
      }).x(function (d) {
        return xScale(d.date);
      }).y1(function (d) {
        var tenPercent = (d.stressThreshold - d.defenceThreshold) * amberZone;
        return yScale(d.stressThreshold - tenPercent);
      }).y0(function (d) {
        return yScale(d.stressThreshold);
      }));

      set(this, 'defenceLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d) {
        return d.defence > 0.01;
      }).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return yScale(d.defence);
      }));

      set(this, 'defenceAdjLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d, i, arr) {
        if (arr.length === 3) {
          return i !== 0;
        }
        return i > 1;
      }).x(function (d, i, arr) {
        if (arr.length === 3) {
          if (i === 1) {
            return xScale(new Date(arr[2].date.getTime() - 86400000 * 1.5));
          } else if (i === 2) {
            return xScale(new Date(arr[2].date.getTime() + 86400000 * 1.5));
          }
        }
        return xScale(d.date);
      }).y(function (d, i, arr) {
        if (arr.length === 3 && i === 1) {
          return yScale(arr[2].defenceThreshold);
        }
        return yScale(d.defenceThreshold);
      }));

      set(this, 'OSiLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d) {
        return d.stress > 0.01 && d.defence > 0.01;
      }).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return yScale(d.OSi);
      }));

      set(this, 'defenceAmberArea', (0, _d3Shape.area)().curve(_d3Shape.curveMonotoneX).defined(function (d, i) {
        return i != 0;
      }).x(function (d) {
        return xScale(d.date);
      }).y0(function (d) {
        var tenPercent = (d.stressThreshold - d.defenceThreshold) * amberZone;
        return yScale(d.defenceThreshold + tenPercent);
      }).y1(function (d) {
        return yScale(d.defenceThreshold);
      }));

      set(this, 'crpLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d) {
        return d.crp > 0.01;
      }).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return yScale(d.crp);
      }));

      set(this, 'ckLine', (0, _d3Shape.line)().curve(_d3Shape.curveMonotoneX).defined(function (d) {
        return d.ck > 0.01;
      }).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return ckScale(d.ck);
      }));
    },
    setupTooltip: function setupTooltip() {
      var _this3 = this;

      get(this, 'tip').attr('id', 'd3-tip').attr('class', 'd3-tip').offset(function (d) {
        var isDefenceTip = get(_this3, 'tooltipText') === "Defence";
        if (isDefenceTip) {
          return d.defence < d.stress ? [10, 0] : [-10, 0];
        }
        return d.stress > d.defence ? [-10, 0] : [10, 0];
      }).direction(function (d) {
        var isDefenceTip = get(_this3, 'tooltipText') === "Defence";
        if (isDefenceTip) {
          return d.defence < d.stress ? "s" : "n";
        }
        return d.stress > d.defence ? "n" : "s";
      }).html(function (d) {
        // SET COLOR OF TIP DYNAMICALLY
        // tip.style('background', function() {
        //     let isDefenceTip = get(_this, 'tooltipText') === "Defence";
        //     return isDefenceTip === true ? 'rgba(99, 157, 195, 0.8)' : 'rgba(181, 114, 220, 0.8)';
        // })
        var cdtLoading = get(_this3, 'cdtLoading');

        if (cdtLoading) {
          return '<i class="cdt-loader fa-spinner fa fa-2x fa-circle-o-notch fa-spin"></i>';
        }

        var includeOrExcludeTxt = get(_this3, 'tooltipIncExc'); // include or exclude

        var tooltipDisplayText = '';

        if (get(_this3, 'showRedox')) {
          if (d.stress) {
            tooltipDisplayText = '<strong>Stress:</strong><span> ' + d.stress + ' </span>';
          }
          if (d.defence) {
            if (d.stress) tooltipDisplayText += '<br>';
            tooltipDisplayText += '<strong>Defense:</strong><span> ' + d.defence + ' </span>';
          }
        }

        if (get(_this3, 'showOSi') && (d.stress || d.defence) && d.OSi) {
          tooltipDisplayText += '<br><strong>OSi:</strong><span> ' + d.OSi + ' </span>';
        }

        //show crp
        if (get(_this3, 'showCRP') && d.crp) {
          if (tooltipDisplayText) {
            tooltipDisplayText += '<br>';
          }
          tooltipDisplayText += '<strong>CRP:</strong><span> ' + d.crp + ' </span>';
        }

        if (get(_this3, 'showCK') && d.ck) {
          if (tooltipDisplayText) {
            tooltipDisplayText += '<br>';
          }
          tooltipDisplayText += '<strong>CK:</strong><span> ' + d.ck + ' </span>';
        }

        if (get(_this3, 'showRedox') && (d.stress || d.defence)) {
          tooltipDisplayText += '<br><button class="tooltip-button" id="inc-exc-btn">' + includeOrExcludeTxt + ' Redox</button>';
        }

        return tooltipDisplayText;
      });

      run.next(function () {
        // allows time for d3-tip div to be created
        // Keep the tooltip showing while hover over the tooltip
        $('.d3-tip').on('mouseover.redoxNamespace', function () {
          run.cancel(get(_this3, 'timeoutTimer'));
        });

        $('.d3-tip').on('mouseout.redoxNamespace', function () {
          _this3.removeTipLater();
        });
      });
    },
    setupIncludeExcludeClickEvent: function setupIncludeExcludeClickEvent() {
      var _this4 = this;

      var isEventSetup = get(this, 'isIncludeExcludeEventSetup');

      if (isEventSetup) {
        return;
      } else {
        $('#inc-exc-btn').on('click.redoxNamespace', function () {
          set(_this4, 'update', 'CDTs');
          _this4.send('toggleIncludeExclude');
        });
      }
    },
    setupClippaths: function setupClippaths() {
      var redoxsvg = get(this, 'redoxsvg');
      var width = get(this, 'width');
      var height = get(this, 'height');
      var ctxHeight = get(this, 'ctxHeight');
      var margins = get(this, 'margins');

      redoxsvg.append("defs").append("clipPath").attr("id", "yaxisclip").append("rect").attr("id", "yaxiscliprect").attr("x", 15).attr("width", width - 15).attr("height", height + 55); //15

      redoxsvg.append("defs").append("clipPath").attr("id", "ckaxisclip").append("rect").attr("id", "ckaxiscliprect").attr("x", 15).attr("width", width - 10).attr("height", height + 45); //15


      redoxsvg.append("defs").append("clipPath").attr("id", "ctxclip").append("rect").attr("id", "ctxcliprect").attr("width", width).attr("height", ctxHeight);

      redoxsvg.append("defs").append("clipPath").attr("id", "rdxclip").append("rect").attr("id", "rdxcliprect").attr("width", width).attr("height", height + margins.top);

      redoxsvg.append("defs").append("clipPath").attr("id", "rdxdangerclip").append("rect").attr("id", "rdxcliprect").attr("width", width).attr("height", height).attr("rx", 15).attr("ry", 15);
    },
    setupLinearGradients: function setupLinearGradients() {
      var redoxsvg = get(this, 'redoxsvg');

      var grad1 = redoxsvg.append("defs").append("linearGradient").attr("id", "greenbottom").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad1.append("stop").attr("offset", "50%").style("stop-color", "#41d691");
      grad1.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);

      var grad2 = redoxsvg.append("defs").append("linearGradient").attr("id", "amberbottom").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad2.append("stop").attr("offset", "50%").style("stop-color", "#ff9933");
      grad2.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);

      var grad3 = redoxsvg.append("defs").append("linearGradient").attr("id", "redbottom").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad3.append("stop").attr("offset", "50%").style("stop-color", "#ef3340");
      grad3.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);

      var grad4 = redoxsvg.append("defs").append("linearGradient").attr("id", "undefbottom").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad4.append("stop").attr("offset", "50%").style("stop-color", "#eae7e5");
      grad4.append("stop").attr("offset", "50%").style("stop-color", "#eae7e5").style("stop-opacity", 0);

      var grad5 = redoxsvg.append("defs").append("linearGradient").attr("id", "greentop").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad5.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);
      grad5.append("stop").attr("offset", "50%").style("stop-color", "#41d691");

      var grad6 = redoxsvg.append("defs").append("linearGradient").attr("id", "ambertop").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad6.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);
      grad6.append("stop").attr("offset", "50%").style("stop-color", "#ff9933");

      var grad7 = redoxsvg.append("defs").append("linearGradient").attr("id", "redtop").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad7.append("stop").attr("offset", "50%").style("stop-color", "white").style("stop-opacity", 0);
      grad7.append("stop").attr("offset", "50%").style("stop-color", "#ef3340");

      var grad8 = redoxsvg.append("defs").append("linearGradient").attr("id", "undeftop").attr("x1", "0%").attr("x2", "0%").attr("y1", "100%").attr("y2", "0%");
      grad8.append("stop").attr("offset", "50%").style("stop-color", "#eae7e5").style("stop-opacity", 0);
      grad8.append("stop").attr("offset", "50%").style("stop-color", "#eae7e5");
    },
    setupBrush: function setupBrush() {
      var ctxHeight = get(this, 'ctxHeight');
      var width = get(this, 'width');

      var brush = (0, _d3Brush.brushX)().extent([[0, 0], [width, ctxHeight]]).on("brush.redoxNamespace end.redoxNamespace", this.brushed.bind(this));

      set(this, 'brush', brush);
    },
    setupZoom: function setupZoom() {
      var width = get(this, 'width');
      var height = get(this, 'height');

      var zoomVar = (0, _d3Zoom.zoom)().scaleExtent([2, 300]).translateExtent([[0, 0], [width, height]]).on("zoom.redoxNamespace", this.zoomed.bind(this)).on("end.redoxNamespace", this.zoomEndFunction.bind(this));

      set(this, 'zoomVar', zoomVar);
    },


    /*--------------------------- Draw Once Functions ----------------------------*/
    drawSVG: function drawSVG() {
      var wndwWidth = get(this, 'wndwWidth');
      var mainChartBorder = get(this, 'mainChartBorder');
      var openHeight = get(this, 'openHeight');
      var margins = get(this, 'margins');
      var tooltip = get(this, 'tip');

      // Create the main SVG for the graph
      var redoxsvg = (0, _d3Selection.select)("#redox-svg").attr("width", wndwWidth - 2 * mainChartBorder).attr("height", openHeight).call(tooltip);

      var redoxFocus = redoxsvg.append("g").attr("class", "redox-focus").attr("transform", "translate(" + margins.left + "," + margins.top + ")");

      var redoxctx = redoxsvg.append("g").attr("class", "redoxctx").attr("transform", "translate(" + margins.left + "," + "0" + ")");

      var context = redoxsvg.append("g").attr("class", "context-area").attr("id", "context-area").attr("transform", "translate(" + margins.left + "," + (openHeight - 25) + ")");

      set(this, 'redoxsvg', redoxsvg);
      set(this, 'redoxctx', redoxctx);
      set(this, 'redoxFocus', redoxFocus);
      set(this, 'context', context);
    },
    drawXAxis: function drawXAxis() {
      var context = get(this, 'context');
      var width = get(this, 'width');
      var xAxis = get(this, 'xAxis');
      var brush = get(this, 'brush');
      var xContext = get(this, 'xContext');
      var xl = get(this, 'xl');

      //This is a dummy rectangle that stops the axis from being transparent when you close the graph.
      context.append("rect").attr("class", "x-axis-rect").attr("width", width).attr("height", 40);

      //This is the date labels at the bottom
      context.append("g").attr("class", "axis axis--x").attr("transform", "translate(0," + 20 + ")").call(xAxis);

      context.append("g").attr("class", "small-circle-group").attr("transform", "translate(0," + 0 + ")");

      //This is the red and grey highlight at the bottom
      context.append("g").attr("transform", "translate(0," + 0 + ")").attr("class", "brush").call(brush).call(brush.move, xContext.range());

      //These are rectangles to the left and right on the bottom
      context.select(".brush").append("rect").attr("class", "left-bookend").attr("width", xl).attr("height", 20);
      context.select(".brush").append("rect").attr("class", "right-bookend").attr("width", width).attr("height", 20);
    },
    drawXAxisCircles: function drawXAxisCircles() {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xContext = get(this, 'xContext');

      //This is the grey circles at the bottom
      (0, _d3Selection.select)('.small-circle-group').selectAll('.small-date-circle').data(d3RedoxData).enter().append("circle").attr("class", "small-date-circle").attr("id", function (d) {
        return "small-date-circle" + d.timeKey;
      }).attr("r", 6).attr("cx", function (d) {
        return xContext(d.date);
      }).attr("cy", 10).style("fill", "grey").style("stroke", "grey").style("stroke-width", "2px");
    },
    drawYAxis: function drawYAxis() {
      var redoxFocus = get(this, 'redoxFocus');
      var yAxis = get(this, 'yAxis');

      //as we are redrawing y axis
      (0, _d3Selection.select)('.y-axis').remove();

      redoxFocus.append("g").attr("class", "y-axis").call(yAxis).attr("transform", "translate(-5)");

      redoxFocus.selectAll(".y-axis").selectAll(".tick text").attr("transform", "translate(-5,5)");
    },
    drawCkAxis: function drawCkAxis() {
      var showCK = get(this, 'showCK');
      var redoxFocus = get(this, 'redoxFocus');
      var ckAxis = get(this, 'ckAxis');
      var width = get(this, 'width');

      (0, _d3Selection.select)('.ck-axis').remove();

      // redoxFocus.append("g").attr("class", "ck-axis").call(ckAxis);

      redoxFocus.append("g").attr("class", "ck-axis").call(ckAxis).attr("transform", "translate(" + (width - 20) + ")");
    },


    // These elements are what the circles/lines will be attached to in the future
    // These are only drawn once per render of the graph.
    drawAllGroups: function drawAllGroups() {
      var redoxctx = get(this, 'redoxctx');
      var redoxFocus = get(this, 'redoxFocus');
      var width = get(this, 'width');
      var height = get(this, 'height');
      var zoomVar = get(this, 'zoomVar');
      var showOSi = get(this, 'showOSi');
      var OSiOpacity = showOSi ? 1 : 0;

      var showRedox = get(this, 'showRedox');
      var RedoxOpacity = showRedox ? 1 : 0;

      var showCRP = get(this, 'showCRP');
      var CRPOpacity = showCRP ? 1 : 0;

      var showCK = get(this, 'showCK');
      var CKOpacity = showCK ? 1 : 0;

      //TOP
      redoxctx.append("g").attr("class", "large-circle-group"); //30
      redoxFocus.append("g").attr('class', 'vert-line-group').attr("transform", "translate(0," + -55 + ")");

      //MIDDLE
      redoxFocus.append("path").attr("class", "defence-line").attr('id', 'defence-line').attr('opacity', RedoxOpacity);
      redoxFocus.append("path").attr("class", "defence-line-adjacent").attr("id", "defence-line-adjacent").attr('opacity', RedoxOpacity);
      redoxFocus.append("path").attr("class", "stress-line").attr("id", "stress-line").attr('opacity', RedoxOpacity);
      redoxFocus.append("path").attr("class", "stress-line-adjacent").attr("id", "stress-line-adjacent").attr('opacity', RedoxOpacity);
      redoxFocus.append("path").attr("class", "OSi-line").attr("id", "OSi-line").attr('opacity', OSiOpacity);
      redoxFocus.append("path").attr("class", "crp-line").attr('id', 'crp-line').attr('opacity', CRPOpacity);
      redoxFocus.append("path").attr("class", "ck-line").attr('id', 'ck-line').attr('opacity', CKOpacity);

      // redoxFocus.append("path").attr("class", "safe-zone").attr("id", "safe-zone");
      // redoxFocus.append("path").attr("class", "amber-zone").attr("id", "defence-amber-zone");
      // redoxFocus.append("path").attr("class", "amber-zone").attr("id", "stress-amber-zone");

      //this line must be after the zones and lines but before the stress/defence points
      redoxFocus.append("rect").attr("class", "zoom").attr("width", width).attr("height", height).call(zoomVar);

      redoxFocus.append("g").attr("class", "stress-points-group");
      redoxFocus.append("g").attr("class", "defence-points-group");
      redoxFocus.append("g").attr("class", "OSi-points-group");
      redoxFocus.append("g").attr("class", "crp-points-group");
      redoxFocus.append("g").attr("class", "ck-points-group");
    },


    //also rescales ck axis
    rescaleYAxis: function rescaleYAxis(removeTransitionBoolean) {
      //  let xScale = get(this, 'xScale');
      var ckScale = get(this, 'ckScale');
      var yScale = get(this, 'yScale');
      var d3RedoxData = get(this, 'd3RedoxData');

      var showRedox = get(this, 'showRedox');
      var showCRP = get(this, 'showCRP');
      var showCK = get(this, 'showCK');
      var showOSi = get(this, 'showOSi');
      var xContext = get(this, 'xContext');

      var leftBookEnd = get(this, 'leftBookEnd');
      var rightBookEnd = get(this, 'rightBookEnd');

      var ckTickDif = 100;
      var maxValue = 4;
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = d3RedoxData[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var d = _step.value;

          if (xContext(d.date) > leftBookEnd && xContext(d.date) < rightBookEnd) {
            if (showRedox) {
              maxValue = d.stress > maxValue ? d.stress : maxValue;
              maxValue = d.defence > maxValue ? d.defence : maxValue;
            }
            if (showCRP) {
              maxValue = d.crp > maxValue ? d.crp : maxValue;
            }
            if (showOSi) {
              var osi = d.OSi ? parseFloat(d.OSi) : 0;
              maxValue = osi > maxValue ? osi : maxValue;
            }

            if (showCK) {
              //set dif between ticks
              var newCkTickDif = Math.floor(d.ck / 1000) * 100 + 100;
              ckTickDif = ckTickDif < newCkTickDif ? newCkTickDif : ckTickDif;
              //let ckTick = Math.ceil(d.ck/100);
              var ckTick = Math.ceil(d.ck / ckTickDif);

              maxValue = ckTick > maxValue ? ckTick : maxValue;
            }
          }
        }

        //function displayCtxText
        //if too crowded only show some text
        //maybe select exactly which of these i need to improve performance - dont draw more than needed
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (parseFloat(maxValue) !== parseFloat(get(this, 'maxValueY'))) {
        maxValue = maxValue < 20 ? maxValue + 0.5 : maxValue + 1.5;
        //set yScale max to new highest value
        set(this, 'yScale', yScale.range([get(this, 'height'), 0]).domain([0, maxValue * (ckTickDif / 100)]));

        set(this, 'ckScale', ckScale.range([get(this, 'height'), 0])
        //.domain([0 , maxValue*100]));
        .domain([0, maxValue * ckTickDif]));

        var tickArguments = maxValue > 6 ? 6 : maxValue;
        set(this, 'yAxis', (0, _d3Axis.axisLeft)(yScale).tickArguments([tickArguments, "s"]).tickSize(-get(this, 'width'), 0, 0).tickPadding([-16]));

        if (showCK) {
          set(this, 'ckAxis', (0, _d3Axis.axisLeft)(ckScale)
          //.tickArguments([tickArguments , "s"])
          .tickArguments([tickArguments, "s"]).tickSize(-get(this, 'width'), 0, 0).tickPadding([-16]));
        } else {
          set(this, 'ckAxis', (0, _d3Axis.axisLeft)(ckScale).tickFormat(function () {
            return [];
          }));
        }

        //update features of graph
        this.updateYAxis();
        this.updateCkAxis();
        this.positionLargeCircles(removeTransitionBoolean);
        this.positionCKPlots(removeTransitionBoolean);
        this.positionCKPoints(removeTransitionBoolean);
        this.positionStressDefencePlots(removeTransitionBoolean);
        this.positionStressDefencePoints(removeTransitionBoolean);
        this.positionCRPPlots(removeTransitionBoolean);
        this.positionCRPPoints(removeTransitionBoolean);

        set(this, 'maxValueY', maxValue);
      }
    },
    displayCtxText: function displayCtxText() {
      var xScale = get(this, 'xScale');
      var d3RedoxData = get(this, 'd3RedoxData');
      var showRedox = get(this, 'showRedox');
      var showCRP = get(this, 'showCRP');
      var showCK = get(this, 'showCK');
      var textOverlapThreshold = get(this, 'textOverlapThreshold');
      var crpTextOverlapThreshold = get(this, 'crpTextOverlapThreshold');

      (0, _d3Selection.selectAll)(".large-circle-date").style("display", function (d, i) {
        //do not show dates if they overlap
        // RED, CRP, CK
        if (showRedox && showCRP && showCK && (d.crp || d.stress || d.defence || d.surveyComplete || d.ck)) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //RED
        } else if (showRedox && !showCRP && !showCK && (d.stress || d.defence || d.surveyComplete)) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //CRP
        } else if (!showRedox && showCRP && !showCK && d.crp) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //ck
        } else if (!showRedox && !showCRP && showCK && d.ck) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //RED CRP
        } else if (showRedox && showCRP && !showCK && (d.crp || d.stress || d.defence || d.surveyComplete)) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //red ck
        } else if (showRedox && !showCRP && showCK && (d.ck || d.stress || d.defence || d.surveyComplete)) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
          //ck crp
        } else if (!showRedox && showCRP && showCK && (d.ck || d.crp)) {
          return d3RedoxData[i + 1] && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < textOverlapThreshold ? 'none' : 'block';
        } else {
          return 'none';
        }
      });

      //maybe this should be using crpline
      (0, _d3Selection.selectAll)('.large-circle-crp-label').style('display', function (d, i) {
        return d3RedoxData[i + 1] && d3RedoxData[i + 1].crp && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < crpTextOverlapThreshold || !d.crp || !showCRP ? 'none' : 'block';
      });

      (0, _d3Selection.selectAll)('.large-circle-ck-label').style('display', function (d, i) {
        return d3RedoxData[i + 1] && d3RedoxData[i + 1].ck && xScale(d3RedoxData[i + 1].date) - xScale(d3RedoxData[i].date) < crpTextOverlapThreshold || !d.ck || !showCK ? 'none' : 'block';
      });
    },
    showAttributePoints: function showAttributePoints() {
      var showRedox = get(this, 'showRedox');
      var showCRP = get(this, 'showCRP');
      var showOSi = get(this, 'showOSi');
      var showCK = get(this, 'showCK');
      var _this = this;

      (0, _d3Selection.select)('.crp-points-group').selectAll('#crp-point').on("mouseover.redoxNamespace", function (d) {
        return showCRP ? _this.displayTip(d, "CRP", this) : null;
      });

      (0, _d3Selection.select)('.ck-points-group').selectAll('#ck-point').on("mouseover.redoxNamespace", function (d) {
        return showCK ? _this.displayTip(d, "CK", this) : null;
      });

      (0, _d3Selection.select)('.stress-points-group').selectAll('#stress-point').on("mouseover.redoxNamespace", function (d) {
        return showRedox ? _this.displayTip(d, "Stress", this) : null;
      });

      (0, _d3Selection.select)('.defence-points-group').selectAll('#defence-point').on("mouseover.redoxNamespace", function (d) {
        return showRedox ? _this.displayTip(d, "Defence", this) : null;
      });

      (0, _d3Selection.select)('.osi-points-group').selectAll('#osi-point').on("mouseover.redoxNamespace", function (d) {
        return showOSi ? _this.displayTip(d, "OSi", this) : null;
      });

      //display instead of opacity to prevent clicking
      (0, _d3Selection.selectAll)(".large-circle-ring").style("display", function (d) {
        return showRedox && (d.stress || d.defence || d.surveyComplete) ? 'block' : 'none';
      });

      (0, _d3Selection.selectAll)(".large-circle-stress").style("display", function (d) {
        return showRedox && (d.stress || d.defence || d.surveyComplete) ? 'block' : 'none';
      });

      (0, _d3Selection.selectAll)(".large-circle-defence").style("display", function (d) {
        return showRedox && (d.stress || d.defence || d.surveyComplete) ? 'block' : 'none';
      });

      (0, _d3Selection.selectAll)(".large-circle-crp").style("display", function (d) {
        return d.crp && showCRP ? 'block' : 'none';
      });

      (0, _d3Selection.selectAll)(".large-circle-ck").style("display", function (d) {
        return d.ck && showCK ? 'block' : 'none';
      });
    },


    /*------------------------ Update Graph Functions --------------------------*/
    updateGraph: function updateGraph(update) {
      switch (update) {
        case 'redoxTests':
          this.updateHighlightRedoxTest();
          break;
        case 'redoxTestsDateBtn':
          this.centerIfOffScreen();
          this.updateHighlightRedoxTest();
          break;
        case 'CDTs':
          this.updateCDTs();
          break;
        default:
          this.updateAllGraph();
      }
    },
    updateHighlightRedoxTest: function updateHighlightRedoxTest() {
      this.restructureData();
      this.resetHightlightedTest();
      set(this, 'update', 'all');
    },
    updateCDTs: function updateCDTs() {
      this.restructureData();
      this.updateXAxis();
      this.positionLargeCircles();
      this.positionStressDefencePlots();
      //this.positionShadesAreas();
      this.positionStressDefencePoints();
      this.positionCRPPlots();
      this.positionCRPPoints();
      this.positionCKPlots();
      this.positionCKPoints();
      this.showToastMsg();
      this.removeTipNow();
      this.removeCDTSpinner();
      set(this, 'update', 'all');
    },
    updateAllGraph: function updateAllGraph() {
      //console.log('run on load');
      this.restructureData();
      this.updateXScale();
      this.setZoom();
      this.updateXAxis();
      this.positionLargeCircles();
      this.positionStressDefencePlots();
      //this.positionShadesAreas(); //not used
      this.positionStressDefencePoints();
      this.positionCRPPlots();
      this.positionCRPPoints();
      this.positionCKPoints();
      this.positionCKPlots();
      //this.positionWeeklyCircles();
      if (get(this, 'hasRedoxTests')) {
        this.resetHightlightedTest();
        this.repositionCenter();
      } else {
        this.removeExcessSVGs();
      }
    },
    updateXScale: function updateXScale() {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xScale = get(this, 'xScale');
      var xContext = get(this, 'xContext');
      var t = get(this, 'transitionDuration');

      set(this, 'graphStartDate', (0, _moment.default)(d3RedoxData.get('firstObject').date).subtract(4, 'months'));
      set(this, 'graphEndDate', (0, _moment.default)(d3RedoxData.get('lastObject').date).add(4, 'months'));

      xScale.domain([get(this, 'graphStartDate'), get(this, 'graphEndDate')]);
      xContext.domain([get(this, 'graphStartDate'), get(this, 'graphEndDate')]);

      var xAxis = get(this, 'xAxis');
      (0, _d3Selection.select)(".axis--x").transition().duration(t).call(xAxis);
    },
    updateXAxis: function updateXAxis() {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xContext = get(this, 'xContext');
      var t = get(this, 'transitionDuration');

      //ADD NEW
      (0, _d3Selection.select)('.small-circle-group').selectAll('.small-date-circle').data(d3RedoxData).enter().append('circle').attr("class", "small-date-circle").attr("r", 6).attr("cy", 10).style("fill", "grey").style("stroke", "grey").style("stroke-width", "2px");

      //REMOVE EXTRAS
      (0, _d3Selection.selectAll)('.small-date-circle').data(d3RedoxData).exit().remove();

      //TRANSITION TO NEW POSITION
      (0, _d3Selection.selectAll)('.small-date-circle').attr("id", function (d) {
        return "small-date-circle" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xContext(d.date);
      });
    },
    updateYAxis: function updateYAxis() {
      var redoxFocus = get(this, 'redoxFocus');
      var yAxis = get(this, 'yAxis');
      var t = get(this, 'transitionDuration');

      (0, _d3Selection.select)('.y-axis').transition().duration(t).call(yAxis);

      //redoxFocus.selectAll(".tick").each(function(d) {if(d===0) {this.remove();}});
      redoxFocus.selectAll(".y-axis").selectAll(".tick text") // select all the text elements for the xaxis
      .attr("transform", function () {
        return "translate(-5,6)";
      });
    },
    updateCkAxis: function updateCkAxis() {
      var redoxFocus = get(this, 'redoxFocus');
      var ckAxis = get(this, 'ckAxis');
      var t = get(this, 'transitionDuration');
      var showCK = get(this, 'showCK');
      var ckScale = get(this, 'ckScale');

      (0, _d3Selection.select)('.ck-axis').transition().duration(t).call(ckAxis);

      //redoxFocus.selectAll(".tick").each(function(d) {if(d===0) {this.remove();}});
      redoxFocus.selectAll(".ck-axis").selectAll(".tick text") // select all the text elements for the xaxis
      .attr("transform", function () {
        return "translate(5, 6)";
      });
    },


    // this removes the unwanted svgs left when you navigate from a athlete with
    // redoxTests to a athlete without redoxTests. this happens because a dummy data
    // point is being used to populate the empty graph which causes problems when you
    // .exit.remove() on the selection.

    removeExcessSVGs: function removeExcessSVGs() {
      (0, _d3Selection.selectAll)('circle').remove();
      (0, _d3Selection.selectAll)('.vertical-line').remove();
      (0, _d3Selection.selectAll)('.large-circle-date').remove();
      (0, _d3Selection.selectAll)('.large-circle-crp-label').remove();
      (0, _d3Selection.selectAll)('.large-circle-ck-label').remove();
      //selectAll('.large-circle-crp-label-line2').remove();
    },
    resetHightlightedTest: function resetHightlightedTest() {
      //make more presentable
      var d3RedoxData = get(this, 'd3RedoxData');
      var currentTestId = get(this, 'currentTest.id');
      var currentTest = d3RedoxData.findBy('id', currentTestId);
      //this.updateCurrentTest(currentTest);
      this.highlightCurrentTest(currentTest);
    },


    // Called when graph is zoomed, dragged, moved with buttons!
    updateDraggedGraph: function updateDraggedGraph() {
      var _this5 = this;

      if (!get(this, 'hasRedoxTests')) {
        return;
      }

      var xScale = get(this, 'xScale');
      var yScale = get(this, 'yScale');
      var ckScale = get(this, 'ckScale');
      var margins = get(this, 'margins');
      var d3CrpLineData = get(this, 'd3CrpLineData');
      var d3CkLineData = get(this, 'd3CkLineData');
      var d3RedoxLineData = get(this, 'd3RedoxLineData');
      var d3RedoxData = get(this, 'd3RedoxData');
      var showCK = get(this, 'showCK');
      var showCRP = get(this, 'showCRP');

      var textOverlapThreshold = get(this, 'textOverlapThreshold');

      var redoxFocus = (0, _d3Selection.select)(".redox-focus");
      var redoxctx = (0, _d3Selection.select)('.redoxctx');
      var redoxsvg = (0, _d3Selection.select)("#redox-svg");
      var context = (0, _d3Selection.select)(".context");

      var stressThresholdData = d3RedoxLineData.filter(function (d) {
        return d.stressThreshold !== null;
      });
      var defenceThresholdData = d3RedoxLineData.filter(function (d) {
        return d.defenceThreshold !== null;
      });
      //let safeAreaData = d3RedoxLineData.filter((d) => d.defenceThreshold !== null || d.stressThreshold !== null);

      redoxFocus.selectAll(".vertical-line").attr("x1", function (d) {
        return xScale(d.date);
      }).attr("x2", function (d) {
        return xScale(d.date);
      });

      redoxFocus.selectAll("#defence-line").datum(d3RedoxLineData).attr("d", get(this, 'defenceLine'));
      redoxFocus.selectAll("#stress-line").datum(d3RedoxLineData).attr("d", get(this, 'stressLine'));
      redoxFocus.selectAll("#OSi-line").datum(d3RedoxLineData).attr("d", get(this, 'OSiLine'));
      redoxFocus.selectAll("#defence-line-adjacent").datum(defenceThresholdData).attr("d", get(this, 'defenceAdjLine'));
      redoxFocus.selectAll("#stress-line-adjacent").datum(stressThresholdData).attr("d", get(this, 'stressAdjLine'));
      redoxFocus.selectAll("#crp-line").datum(d3CrpLineData).attr("d", get(this, 'crpLine'));
      redoxFocus.selectAll("#ck-line").datum(d3CkLineData).attr("d", get(this, 'ckLine'));

      //redoxFocus.selectAll("#defence-amber-zone").datum(defenceThresholdData).attr("d", get(this, 'defenceAmberArea'));
      //redoxFocus.selectAll("#stress-amber-zone").datum(stressThresholdData).attr("d", get(this, 'stressAmberArea'));
      //redoxFocus.selectAll("#safe-zone").datum(safeAreaData).attr("d", get(this, 'safeArea'));


      redoxFocus.selectAll("#defence-point").attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        return yScale(d.defence);
      }); //.attr("display", function(d) { return (d.defence === null || d.defence === 0) ? "none" : null });
      redoxFocus.selectAll("#stress-point").attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        return yScale(d.stress);
      }); //.attr("display", function(d) { return (d.stress === null || d.stress === 0) ? "none" : null });
      redoxFocus.selectAll("#OSi-point").attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        if (!d.stress || !d.defence) {
          return 0;
        }return yScale(d.OSi);
      }); //.attr("display", function(d) { return (!d.stress || !d.defence) ? "none" : null });

      // let currentBottom = yScale.domain()[0];
      // let currentTop = yScale.domain()[1];

      //crp
      redoxFocus.selectAll("#crp-point").attr("x", function (d) {
        return xScale(d.date) - 6;
      }).attr("y", function (d) {
        return yScale(d.crp) - 6;
      });
      //.attr("display", function(d) { return (d.crp === null || d.crp === 0) ? "none" : null });

      redoxFocus.selectAll("#ck-point").attr("x", function (d) {
        return xScale(d.date) - 6;
      }).attr("y", function (d) {
        return ckScale(d.ck) - 6;
      }).attr("transform", function (d) {
        var x = xScale(d.date);
        var y = ckScale(d.ck);
        return "rotate(45 " + x + " " + y + ")";
      });

      redoxctx.selectAll(".large-circle-crp").attr("cx", function (d) {
        return xScale(d.date);
      });
      redoxctx.selectAll(".large-circle-ck").attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function () {
        return showCRP ? 78 : 56;
      });
      redoxctx.selectAll(".large-circle-stress").attr("cx", function (d) {
        return xScale(d.date);
      });
      redoxctx.selectAll(".large-circle-defence").attr("cx", function (d) {
        return xScale(d.date);
      });
      redoxctx.selectAll(".large-circle-ring").attr("cx", function (d) {
        return xScale(d.date);
      });

      redoxctx.selectAll(".large-circle-date").attr("x", function (d, i) {
        return xScale(d.date);
      });

      redoxctx.selectAll(".large-circle-crp-label").attr("x", function (d) {
        return xScale(d.date);
      });
      redoxctx.selectAll(".large-circle-ck-label").attr("x", function (d) {
        return xScale(d.date);
      }).attr("y", function () {
        return showCRP ? 85 : 63;
      });

      redoxsvg.attr("height", function () {
        return get(_this5, 'isGraphOpen') ? get(_this5, 'openHeight') : get(_this5, 'closedHeight');
      });
      context.attr("transform", function () {
        return get(_this5, 'isGraphOpen') ? "translate(" + margins.left + "," + (get(_this5, 'openHeight') - 40) + ")" : "translate(" + margins.left + "," + 100 + ")";
      });
    },
    highlightCurrentTest: function highlightCurrentTest(d) {
      var showRedox = get(this, 'showRedox');
      var showCRP = get(this, 'showCRP');
      var showCK = get(this, 'showCK');

      if (!showRedox && !showCRP && !showCK) {
        d = null; //remove line
      }

      (0, _d3Selection.selectAll)(".vertical-line").attr("y2", 0).style("stroke", "#e6e6e6").style("stroke-width", "0px");
      (0, _d3Selection.selectAll)(".large-circle-ring").style("stroke", "white").style("stroke-width", "0px");
      (0, _d3Selection.selectAll)(".large-circle-defence").attr("r", 12).style("stroke-width", "0px");
      (0, _d3Selection.selectAll)(".large-circle-stress").attr("r", 12).style("stroke-width", "0px");
      (0, _d3Selection.selectAll)(".large-circle-crp").attr("r", 10).style("stroke-width", "2px");
      (0, _d3Selection.selectAll)(".large-circle-ck").attr("r", 10).style("stroke-width", "2px");
      (0, _d3Selection.selectAll)(".large-circle-date").style("font-size", 8);
      (0, _d3Selection.selectAll)(".small-date-circle").attr("r", 6).style("fill", "grey").style("stroke", "grey").style("stroke-width", "2px");

      (0, _d3Selection.selectAll)(".large-circle-crp-label").style("font-size", 8).style("fill", "grey"); //.style("font-weight", "normal");;
      //selectAll(".large-circle-crp-label-line2").style("font-size", 9);
      (0, _d3Selection.selectAll)(".large-circle-ck-label").style("font-size", 9).style("fill", "grey");

      if (d) {
        //let lineY2 = showCRP && d.crp ? 33 : 0;
        (0, _d3Selection.select)("#vert" + d.timeKey).attr("y2", function (d) {
          // if( ( (showCK && d.ck) && !(showCRP && d.crp)) || (!(showCK && d.ck) && (showCRP && d.crp) ) ){
          //   return 20;
          // }
          // return (showCRP && d.crp) && (showCK && d.ck) ? 42 : 0;
          return 0;
        }) //25
        .style("stroke", "#8a8282").style("stroke-width", "1px");

        (0, _d3Selection.select)("#circular-ring" + d.timeKey).style("stroke", "#8a8282").style("stroke-width", function (d) {
          return showRedox && (d.stress || d.defence || d.surveyComplete) ? '2px' : '0px';
        });

        (0, _d3Selection.select)("#large-circle-defence" + d.timeKey).attr("r", 15);
        (0, _d3Selection.select)("#large-circle-stress" + d.timeKey).attr("r", 15);

        (0, _d3Selection.selectAll)("#large-circle-date" + d.timeKey).style("font-size", 12);

        (0, _d3Selection.select)("#small-date-circle" + d.timeKey).attr("r", 7.5).style("fill", "white").style("stroke", "black").style("stroke-width", "4px");

        (0, _d3Selection.selectAll)("#large-circle-crp" + d.timeKey).style("stroke-width", "2px");

        (0, _d3Selection.selectAll)("#large-circle-ck" + d.timeKey).style("stroke-width", "2px");

        (0, _d3Selection.selectAll)("#large-circle-crp-label" + d.timeKey).style("fill", "black");

        (0, _d3Selection.selectAll)("#large-circle-ck-label" + d.timeKey).style("fill", "black");
        //selectAll("#large-circle-crp-label-line2"+ d.timeKey).style("font-size", 12);
      }
    },


    /*------------------------ Positioning Functions ---------------------------*/
    //positionWeeklyCircles() {
    // let redoxctx = get(this, 'redoxctx')
    // let weeksarray = get(this, 'weeksarray');
    // let xScale = get(this, 'xScale');
    //
    // select('.weekly-circle-group').remove();
    //
    // // insert instead of append to put the weekly circles behind the large circles
    // redoxctx.insert("g", ":first-child")
    // .attr("class", "weekly-circle-group")
    // .selectAll(".weekly-circle")
    // .data(weeksarray)
    // .enter()
    // .append("circle")
    // .attr("class", "weekly-circle")
    // .attr("r", 5)
    // .attr("cx", function(d) { return xScale(d); })
    // .attr("cy", 45);
    //},

    //maybe seperate method for crp
    positionCRPPoints: function positionCRPPoints(removeTransitionBoolean) {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xScale = get(this, 'xScale');
      var yScale = get(this, 'yScale');
      var t = get(this, 'transitionDuration');
      var _this = this;

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }

      //crp visible
      var showCRP = get(this, 'showCRP');
      var CRPOpacity = showCRP ? 1 : 0;

      // let currentBottom = yScale.domain()[0];
      // let currentTop = yScale.domain()[1];

      var redoxFocus = get(this, 'redoxFocus');

      (0, _d3Selection.select)('.crp-points-group').selectAll('#crp-point').data(d3RedoxData).enter().append('rect').attr('width', 12).attr('height', 12).attr("class", "crp-point").attr("opacity", CRPOpacity).attr("class", function (d) {
        return "crp-point" + d.timeKey;
      }).attr("id", "crp-point").on("mouseover.redoxNamespace", function (d) {
        return showCRP ? _this.displayTip(d, "CRP", this) : null;
      }).on("mouseleave.redoxNamespace", function () {
        _this.removeTipLater(this);
      });

      //REMOVE EXTRAS
      (0, _d3Selection.selectAll)('#crp-point').data(d3RedoxData).exit().remove();

      //TRANSITION TO NEW POSITION
      (0, _d3Selection.selectAll)('#crp-point').transition().duration(t).attr("x", function (d) {
        return xScale(d.date) - 6;
      }).attr("y", function (d) {
        return yScale(d.crp) - 6;
      }).attr("rx", 3).attr("ry", 3).attr("display", function (d) {
        return d.crp ? 'block' : 'none';
      }).style("fill", "#00BFFF") //"#4A90E2")
      .style('stroke-width', '2px') //function(d) {return d.crp > 1 ? '2px' : '1px';})
      .style('stroke', function (d) {
        return _this.getCRPCircleColor(d);
      });
    },
    positionCKPoints: function positionCKPoints(removeTransitionBoolean) {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xScale = get(this, 'xScale');
      var ckScale = get(this, 'ckScale');
      var t = get(this, 'transitionDuration');
      var _this = this;

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }

      //ck visible
      var showCK = get(this, 'showCK');
      var CKOpacity = showCK ? 1 : 0;

      (0, _d3Selection.select)('.ck-points-group').selectAll('#ck-point').data(d3RedoxData).enter().append('rect').attr('width', 12).attr('height', 12).attr("class", "ck-point").attr("opacity", CKOpacity).attr("class", function (d) {
        return "ck-point" + d.timeKey;
      }).attr("id", "ck-point").on("mouseover.redoxNamespace", function (d) {
        return showCK ? _this.displayTip(d, "Ck", this) : null;
      }).on("mouseleave.redoxNamespace", function () {
        _this.removeTipLater(this);
      });

      //REMOVE EXTRAS
      (0, _d3Selection.selectAll)('#ck-point').data(d3RedoxData).exit().remove();

      //TRANSITION TO NEW POSITION
      (0, _d3Selection.selectAll)('#ck-point').transition().duration(t).attr("transform", function (d) {
        var x = xScale(d.date);
        var y = ckScale(d.ck);
        return "rotate(45 " + x + " " + y + ")";
      }).attr("x", function (d) {
        return xScale(d.date) - 6;
      }).attr("y", function (d) {
        return ckScale(d.ck) - 6;
      }).attr("rx", 3).attr("ry", 3).attr("display", function (d) {
        return d.ck ? 'block' : 'none';
      }).style("fill", "#20a2b0") //"#4A90E2")
      .style('stroke-width', '2px').style('stroke', function (d) {
        return _this.getCKCircleColor(d);
      });
    },
    positionStressDefencePoints: function positionStressDefencePoints(removeTransitionBoolean) {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xScale = get(this, 'xScale');
      var yScale = get(this, 'yScale');
      var showOSi = get(this, 'showOSi');
      var OSiOpacity = showOSi ? 1 : 0;
      var t = get(this, 'transitionDuration');
      var _this = this;

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }

      var showRedox = get(this, 'showRedox');
      var RedoxOpacity = showRedox ? 1 : 0;

      //ADD NEW
      (0, _d3Selection.select)('.stress-points-group').selectAll('#stress-point').data(d3RedoxData).enter().append('circle').attr('r', 10).attr("opacity", RedoxOpacity).attr("class", "stress-point").attr("class", function (d) {
        return "stress-point" + d.timeKey;
      }).attr("id", "stress-point").on("mouseover.redoxNamespace", function (d) {
        return showRedox ? _this.displayTip(d, "Stress", this) : null;
      }).on("mouseleave.redoxNamespace", function () {
        _this.removeTipLater(this);
      });

      (0, _d3Selection.select)('.defence-points-group').selectAll('#defence-point').data(d3RedoxData).enter().append('circle').attr("class", "defence-point").attr("class", function (d) {
        return "defence-point" + d.timeKey;
      }).attr("id", "defence-point").attr("r", 10).attr("opacity", RedoxOpacity).on("mouseover.redoxNamespace", function (d) {
        return showRedox ? _this.displayTip(d, "Defence", this) : null;
      }).on("mouseleave.redoxNamespace", function () {
        _this.removeTipLater(this);
      });

      (0, _d3Selection.select)('.OSi-points-group').selectAll('#OSi-point').data(d3RedoxData).enter().append('circle').attr("class", "OSi-point").attr("class", function (d) {
        return "OSi-point" + d.timeKey;
      }).attr("id", "OSi-point").attr("r", 5).attr("opacity", OSiOpacity).on("mouseover.redoxNamespace", function (d) {
        return showOSi ? _this.displayTip(d, "OSi", this) : null;
      }).on("mouseleave.redoxNamespace", function () {
        _this.removeTipLater(this);
      });

      //REMOVE EXTRAS
      (0, _d3Selection.selectAll)('#stress-point').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('#defence-point').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('#OSi-point').data(d3RedoxData).exit().remove();

      //TRANSITION TO NEW POSITION
      //https://stackoverflow.com/questions/10826180/in-d3-js-skip-append-for-null-data
      (0, _d3Selection.selectAll)('#stress-point').transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        return yScale(d.stress);
      }).attr("display", function (d) {
        return d.stress === null || d.stress === 0 ? "none" : 'block';
      }).style("fill", this.getStressPointColor.bind(this));

      (0, _d3Selection.selectAll)('#defence-point').transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        return yScale(d.defence);
      }).attr("display", function (d) {
        return d.defence === null || d.defence === 0 ? "none" : 'block';
      }).style("fill", this.getDefencePointColor.bind(this));

      (0, _d3Selection.selectAll)('#OSi-point').transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function (d) {
        if (!d.stress || !d.defence) {
          return 0;
        }return yScale(d.OSi);
      }).style("display", function (d) {
        return !d.defence || !d.stress ? "none" : 'block';
      }).style("fill", "gray");
    },
    positionLargeCircles: function positionLargeCircles(removeTransitionBoolean) {
      var d3RedoxData = get(this, 'd3RedoxData');
      var xScale = get(this, 'xScale');
      var t = get(this, 'transitionDuration');
      var height = get(this, 'height');
      var textOverlapThreshold = get(this, 'textOverlapThreshold');
      var crpTextOverlapThreshold = get(this, 'crpTextOverlapThreshold');
      var _this = this;

      var showRedox = get(this, 'showRedox');
      var showCRP = get(this, 'showCRP');
      var showCK = get(this, 'showCK');

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }

      //ADD NEW
      (0, _d3Selection.select)('.vert-line-group').selectAll('vertical-line').data(d3RedoxData).enter().append('line').attr("class", "vertical-line").attr("y1", height + 65).attr("y2", 0);

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-date').data(d3RedoxData).enter().append("text").attr("class", "large-circle-date").attr("y", 10);

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-crp').data(d3RedoxData).enter().append("circle").attr("class", "large-circle-crp").attr("r", 10).attr("cy", 56).on("click.redoxNamespace", this.circleTopClick.bind(this));

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-ck').data(d3RedoxData).enter().append("circle").attr("class", "large-circle-ck").attr("r", 10).attr("cy", function () {
        return showCRP ? 78 : 56;
      }).on("click.redoxNamespace", this.circleTopClick.bind(this));

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-stress').data(d3RedoxData).enter().append("circle").attr("class", "large-circle-stress").attr("r", 10).attr("cy", 30);

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-defence').data(d3RedoxData).enter().append("circle").attr("class", "large-circle-defence").attr("r", 10).attr("cy", 30).on("click.redoxNamespace", this.circleTopClick.bind(this));

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-ring').data(d3RedoxData).enter().append('circle').attr("class", "large-circle-ring").attr("r", 14).attr("cy", 30);

      //add crp text
      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-crp-label').data(d3RedoxData).enter().append("text").attr("class", "large-circle-crp-label").attr("y", 63).on("click.redoxNamespace", this.circleTopClick.bind(this));

      (0, _d3Selection.select)('.large-circle-group').selectAll('.large-circle-ck-label').data(d3RedoxData).enter().append("text").attr("class", "large-circle-ck-label").attr("y", function () {
        return showCRP ? 85 : 63;
      }).on("click.redoxNamespace", this.circleTopClick.bind(this));

      //REMOVE EXTRAS
      (0, _d3Selection.selectAll)('.large-circle-crp-label').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-ck-label').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-date').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-crp').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-ck').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-stress').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-defence').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.large-circle-ring').data(d3RedoxData).exit().remove();
      (0, _d3Selection.selectAll)('.vertical-line').data(d3RedoxData).exit().remove();

      (0, _d3Selection.selectAll)('.vertical-line').attr("id", function (d) {
        return "vert" + d.timeKey;
      }).transition().duration(t).attr("x1", function (d) {
        return xScale(d.date);
      }).attr("x2", function (d) {
        return xScale(d.date);
      });

      //for crp
      (0, _d3Selection.selectAll)('.large-circle-crp-label').attr("id", function (d) {
        return "large-circle-crp-label" + d.timeKey;
      }).transition().duration(t).attr("x", function (d) {
        return xScale(d.date);
      }).attr("dy", "-0.5em").style("text-anchor", "middle").style('cursor', 'pointer').text("CRP");

      //ck
      (0, _d3Selection.selectAll)('.large-circle-ck-label').attr("id", function (d) {
        return "large-circle-ck-label" + d.timeKey;
      }).transition().duration(t).attr("x", function (d) {
        return xScale(d.date);
      }).attr("y", function () {
        return showCRP ? 85 : 63;
      }).attr("dy", "-0.5em").style("text-anchor", "middle").style('cursor', 'pointer').text("CK");

      //TRANSITION TO NEW POSITION

      (0, _d3Selection.selectAll)('.large-circle-date').attr("id", function (d) {
        return "large-circle-date" + d.timeKey;
      }).transition().duration(t).attr("x", function (d) {
        return xScale(d.date);
      }).style("text-anchor", "middle").text(function (d) {
        return _moment.default.utc(d.date).format('DD MMM');
      });

      (0, _d3Selection.selectAll)('.large-circle-crp').attr("id", function (d) {
        return "large-circle-crp" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).style("display", function (d) {
        return d.crp && showCRP ? 'block' : 'none';
      }).style("fill", "white").style("stroke", function (d) {
        return _this.getCRPCircleColor(d);
      }).style("stroke-width", '2px');

      (0, _d3Selection.selectAll)('.large-circle-ck').attr("id", function (d) {
        return "large-circle-ck" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).attr("cy", function () {
        return showCRP ? 78 : 56;
      }).style("display", function (d) {
        return d.ck && showCK ? 'block' : 'none';
      }).style("fill", "white").style("stroke", function (d) {
        return _this.getCKCircleColor(d);
      }).style("stroke-width", '2px');

      (0, _d3Selection.selectAll)('.large-circle-stress').attr("id", function (d) {
        return "large-circle-stress" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).style("display", function (d) {
        return showRedox && (d.stress || d.defence || d.surveyComplete) ? 'block' : 'none';
      }).style("fill", this.getLargeStressColor.bind(this));

      (0, _d3Selection.selectAll)('.large-circle-defence').attr("id", function (d) {
        return "large-circle-defence" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      }).style("display", function (d) {
        return showRedox && (d.stress || d.defence || d.surveyComplete) ? 'block' : 'none';
      }).style("fill", this.getLargeDefenceColor.bind(this));

      (0, _d3Selection.selectAll)('.large-circle-ring').attr("id", function (d) {
        return "circular-ring" + d.timeKey;
      }).transition().duration(t).attr("cx", function (d) {
        return xScale(d.date);
      });
    },


    //not used
    //positionShadesAreas() {
    // let d3RedoxData = get(this, 'd3RedoxData');
    // let stressAmberArea = get(this, 'stressAmberArea');
    // let defenceAmberArea = get(this, 'defenceAmberArea');
    // let safeArea = get(this, 'safeArea');
    // let t = get(this, 'transitionDuration');
    //
    // let stressThresholdData = d3RedoxData.filter((d) => d.stressThreshold !== null);
    // let defenceThresholdData = d3RedoxData.filter((d) => d.defenceThreshold !== null);
    // let safeAreaData = d3RedoxData.filter((d) => d.defenceThreshold !== null || d.stressThreshold !== null);
    //
    // //TRANSITION TO NEW POSITION
    // select('#safe-zone').datum(safeAreaData).transition().duration(t).attr("d", safeArea);
    // select('#defence-amber-zone').datum(defenceThresholdData).transition().duration(t).attr("d", defenceAmberArea);
    // select('#stress-amber-zone').datum(stressThresholdData).transition().duration(t).attr("d", stressAmberArea);
    //},

    positionStressDefencePlots: function positionStressDefencePlots(removeTransitionBoolean) {
      var d3RedoxLineData = get(this, 'd3RedoxLineData');
      var defenceLine = get(this, 'defenceLine');
      var defenceAdjLine = get(this, 'defenceAdjLine');
      var stressLine = get(this, 'stressLine');
      var stressAdjLine = get(this, 'stressAdjLine');
      var OSiLine = get(this, 'OSiLine');
      var t = get(this, 'transitionDuration');

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }

      var stressThresholdData = d3RedoxLineData.filter(function (d) {
        return d.stressThreshold !== null;
      });
      var defenceThresholdData = d3RedoxLineData.filter(function (d) {
        return d.defenceThreshold !== null;
      });

      //TRANSITION TO NEW POSITION
      (0, _d3Selection.select)('.defence-line').datum(d3RedoxLineData).transition().duration(t).attr("d", defenceLine);
      (0, _d3Selection.select)('.defence-line-adjacent').datum(defenceThresholdData).transition().duration(t).attr("d", defenceAdjLine);
      (0, _d3Selection.select)('.stress-line').datum(d3RedoxLineData).transition().duration(t).attr("d", stressLine);
      (0, _d3Selection.select)('.stress-line-adjacent').datum(stressThresholdData).transition().duration(t).attr("d", stressAdjLine);
      (0, _d3Selection.select)('.OSi-line').datum(d3RedoxLineData).transition().duration(t).attr("d", OSiLine);
    },
    positionCRPPlots: function positionCRPPlots(removeTransitionBoolean) {
      var d3CrpLineData = get(this, 'd3CrpLineData');
      var crpLine = get(this, 'crpLine');
      //let showCRP = get(this, 'showCRP');
      var t = get(this, 'transitionDuration');

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }
      //TRANSITION TO NEW POSITION
      (0, _d3Selection.select)('.crp-line').datum(d3CrpLineData).transition().duration(t).attr("d", crpLine);
    },
    positionCKPlots: function positionCKPlots(removeTransitionBoolean) {
      var d3CkLineData = get(this, 'd3CkLineData');
      var ckLine = get(this, 'ckLine');
      //let showCK = get(this, 'showCK');
      var t = get(this, 'transitionDuration');

      if (removeTransitionBoolean) {
        t = get(this, 'quickTransitionDuration');
      }
      //TRANSITION TO NEW POSITION
      (0, _d3Selection.select)('.ck-line').datum(d3CkLineData).transition().duration(t).attr("d", ckLine);
    },
    setZoom: function setZoom() {
      var width = get(this, 'width');
      var zoomVar = get(this, 'zoomVar');
      var xl = get(this, 'xl');

      (0, _d3Selection.select)(".zoom").call(zoomVar.transform, _d3Zoom.zoomIdentity.scale(width / (width / 2)).translate(-xl, 0));
    },
    setDefaultCenter: function setDefaultCenter() {
      // Only called when there are no redox tests.
      var xScale = get(this, 'xScale');
      var xContext = get(this, 'xContext');
      var graphStartDate = get(this, 'graphStartDate');
      var graphEndDate = get(this, 'graphEndDate');

      var currentleft = xScale.domain()[0].getTime();
      var currentright = xScale.domain()[1].getTime();
      var halfwidth = (currentright - currentleft) / 2;
      var newleftdate = new Date(new Date().getTime() - halfwidth);
      var newrightdate = new Date(new Date().getTime() + halfwidth);
      xContext.domain([graphStartDate, graphEndDate]);
      xScale.domain(xContext.domain());
      set(this, 'xl', xScale(newleftdate.getTime()));
      set(this, 'xr', xScale(newrightdate.getTime()));
    },
    setCenter: function setCenter() {
      var xScale = get(this, 'xScale');
      var d3RedoxData = get(this, 'd3RedoxData');
      var xContext = get(this, 'xContext');
      var currentTestId = get(this, 'currentTest.id');
      var graphStartDate = get(this, 'graphStartDate');
      var graphEndDate = get(this, 'graphEndDate');

      var currentleft = xScale.domain()[0].getTime();
      var currentright = xScale.domain()[1].getTime();
      var halfwidth = (currentright - currentleft) / 2;
      var newleftdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey - halfwidth);
      var newrightdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey + halfwidth);

      xContext.domain([graphStartDate, graphEndDate]);
      xScale.domain(xContext.domain());
      set(this, 'xl', xScale(newleftdate.getTime()));
      set(this, 'xr', xScale(newrightdate.getTime()));
    },
    transitionCenter: function transitionCenter() {
      var redoxsvg = get(this, 'redoxsvg');
      var zoomVar = get(this, 'zoomVar');
      var width = get(this, 'width');
      var xl = get(this, 'xl');
      var xr = get(this, 'xr');
      xr = xr > width ? width - 10 : xr;

      redoxsvg.select(".zoom").transition().ease(_d3Ease.easeCubic).duration(700).call(zoomVar.transform, _d3Zoom.zoomIdentity.scale(width / (xr - xl)).translate(-xl, 0));
    },
    setInitialHighlightedTest: function setInitialHighlightedTest() {
      var d3RedoxData = get(this, 'd3RedoxData');
      var currentTest = d3RedoxData.findBy('id', get(this, 'currentTest.id'));
      this.updateCurrentTest(currentTest);
      this.highlightCurrentTest(currentTest);
    },
    centerIfOffScreen: function centerIfOffScreen() {
      var xScale = get(this, 'xScale');
      var redoxsvg = get(this, 'redoxsvg');
      var d3RedoxData = get(this, 'd3RedoxData');
      var graphStartDate = get(this, 'graphStartDate');
      var graphEndDate = get(this, 'graphEndDate');
      var xContext = get(this, 'xContext');
      var width = get(this, 'width');
      var zoomVar = get(this, 'zoomVar');
      var currentTestId = get(this, 'currentTest.id');

      if (!currentTestId) {
        return;
      }

      var currentPosition = new Date(d3RedoxData.findBy('id', currentTestId).timeKey);
      var xc = xScale(currentPosition.getTime());

      var currentleft = xScale.domain()[0].getTime();
      var currentright = xScale.domain()[1].getTime();
      var halfwidth = (currentright - currentleft) / 2;

      var minXScale = xScale(xScale.domain()[0].getTime());
      var maxXScale = xScale(xScale.domain()[1].getTime());

      if (xc < minXScale || xc > maxXScale) {

        var newleftdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey - halfwidth);
        var newrightdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey + halfwidth);

        xContext.domain([graphStartDate, graphEndDate]);
        xScale.domain(xContext.domain());

        var xl = xScale(newleftdate.getTime()) >= 0 ? xScale(newleftdate.getTime()) : 0;
        var xr = xScale(newrightdate.getTime()) <= width ? xScale(newrightdate.getTime()) : width;

        set(this, 'xl', xl);
        set(this, 'xr', xr);

        redoxsvg.select(".zoom").transition().ease(_d3Ease.easeCubic).duration(800).call(zoomVar.transform, _d3Zoom.zoomIdentity.scale(width / (xr - xl)).translate(-xl, 0));
      }
    },
    repositionCenter: function repositionCenter() {
      var xScale = get(this, 'xScale');
      var redoxsvg = get(this, 'redoxsvg');
      var d3RedoxData = get(this, 'd3RedoxData');
      var graphStartDate = get(this, 'graphStartDate');
      var graphEndDate = get(this, 'graphEndDate');
      var xContext = get(this, 'xContext');
      var width = get(this, 'width');
      var zoomVar = get(this, 'zoomVar');
      var currentTestId = get(this, 'currentTest.id');

      var currentleft = xScale.domain()[0].getTime();
      var currentright = xScale.domain()[1].getTime();
      var halfwidth = (currentright - currentleft) / 2;

      var newleftdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey - halfwidth);
      var newrightdate = new Date(d3RedoxData.findBy('id', currentTestId).timeKey + halfwidth);

      xContext.domain([graphStartDate, graphEndDate]);
      xScale.domain(xContext.domain());

      var xl = xScale(newleftdate.getTime()) >= 0 ? xScale(newleftdate.getTime()) : 0;
      var xr = xScale(newrightdate.getTime()) <= width ? xScale(newrightdate.getTime()) : width;

      set(this, 'xl', xl);
      set(this, 'xr', xr);

      redoxsvg.select(".zoom").transition().ease(_d3Ease.easeCubic).duration(700).call(zoomVar.transform, _d3Zoom.zoomIdentity.scale(width / (xr - xl)).translate(-xl, 0));

      this.highlightCurrentTest(d3RedoxData.findBy('id', currentTestId));
    },


    /*---------------------------- Event Functions -----------------------------*/
    actions: {
      toggleIncludeExclude: function toggleIncludeExclude() {
        this.showCDTSpinner();
        this.setTooltipText();
        this.patchIncludeExclude(get(this, 'latestPoint.id')); // call action on controller to persist changes
      },
      toggleGraph: function toggleGraph() {
        this.removeTipNow();
        this.openCloseGraph();
      }
    },

    brushed: function brushed() {
      var xContext = get(this, 'xContext');
      var xScale = get(this, 'xScale');
      var redoxFocus = get(this, 'redoxFocus');
      var redoxsvg = get(this, 'redoxsvg');
      var xAxis = get(this, 'xAxis');
      var width = get(this, 'width');
      var context = get(this, 'context');
      var zoomVar = get(this, 'zoomVar');

      this.removeTipNow();
      var s = _d3Selection.event.selection || xContext.range();

      var leftBookEnd = s[0] >= 3 ? s[0] : 3;
      var rightBookEnd = s[1] <= width ? s[1] : width;

      if (_d3Selection.event.sourceEvent && _d3Selection.event.sourceEvent.type === "zoom") {
        if (!get(this, 'isDestroyed')) {
          // needed to pass athlete-redox-graph tests, set will be called on destroyed object otherwise
          set(this, 'xl', leftBookEnd);
          set(this, 'xr', rightBookEnd);
        }
        return;
      }

      xScale.domain(s.map(xContext.invert, xContext));

      this.updateDraggedGraph();
      redoxFocus.select(".axis--x").call(xAxis);

      redoxsvg.select(".zoom").call(zoomVar.transform, _d3Zoom.zoomIdentity.scale(width / (rightBookEnd - leftBookEnd)).translate(-leftBookEnd, 0));

      context.select(".left-bookend").attr("width", leftBookEnd - 3);

      context.select(".right-bookend").attr("x", rightBookEnd + 3);

      if (_d3Selection.event.type === 'end') {
        set(this, 'leftBookEnd', leftBookEnd);
        set(this, 'rightBookEnd', rightBookEnd);
        this.rescaleYAxis(true);
        this.displayCtxText();
      }
    },
    zoomed: function zoomed() {
      if (this.isDestroyed) {
        return;
      }

      var xContext = get(this, 'xContext');
      var xScale = get(this, 'xScale');
      var xAxis = get(this, 'xAxis');
      var redoxFocus = get(this, 'redoxFocus');
      var context = get(this, 'context');
      var brush = get(this, 'brush');
      var width = get(this, 'width');

      if (_d3Selection.event.sourceEvent && _d3Selection.event.sourceEvent.type === "brush") {
        return;
      }
      var t = _d3Selection.event.transform;
      xScale.domain(t.rescaleX(xContext).domain());
      var n = t.rescaleX(xContext).domain();

      this.updateDraggedGraph();
      redoxFocus.select(".axis--x").call(xAxis);

      context.select(".brush").call(brush.move, xScale.range().map(t.invertX, t));

      var leftBookEnd = xContext(n[0]) >= 3 ? xContext(n[0]) : 3;
      var rightBookEnd = xContext(n[1]) <= width ? xContext(n[1]) : width;

      context.select(".left-bookend").attr("width", leftBookEnd - 3);
      context.select(".right-bookend").attr("x", rightBookEnd + 3);

      set(this, 'leftBookEnd', leftBookEnd);
      set(this, 'rightBookEnd', rightBookEnd);

      this.displayCtxText();
    },
    zoomEndFunction: function zoomEndFunction() {
      if (this.isDestroyed) {
        return;
      }
      if (_d3Selection.event.sourceEvent && _d3Selection.event.sourceEvent.type === "brush") {
        return;
      }
      this.rescaleYAxis(true);
    },
    circleTopClick: function circleTopClick(d) {
      if (get(this, 'cdtLoading')) {
        return;
      }

      set(this, 'update', 'redoxTests');
      this.updateCurrentTest(d); // call controller
    },
    displayTip: function displayTip(d, text, element) {
      run.cancel(get(this, 'timeoutTimer'));

      this.setTooltipIncExc(d.includeInCdt);

      set(this, 'tooltipData', d);
      set(this, 'tooltipText', text);
      set(this, 'latestPoint', d);

      var args = [].slice.call(arguments);
      args.push(element);

      get(this, 'tip').show.apply(element, args);

      this.setupIncludeExcludeClickEvent();
    },
    removeTipNow: function removeTipNow() {
      get(this, 'tip').hide();
    },
    removeTipLater: function removeTipLater(element) {
      var _this6 = this;

      run.cancel(get(this, 'timeoutTimer'));

      var args = [].slice.call(arguments);
      args.push(element);

      var timeoutTimer = run.later(function () {
        get(_this6, 'tip').hide.apply(element, args);
      }, get(this, 'displayTooltipTimer'));

      set(this, 'timeoutTimer', timeoutTimer);
    },
    openCloseGraph: function openCloseGraph() {
      var _this7 = this;

      var margins = get(this, 'margins');
      var redoxsvg = (0, _d3Selection.select)("#redox-svg");
      var context = (0, _d3Selection.select)(".context-area");
      var t = get(this, 'transitionDuration') + 200;

      if (get(this, 'isGraphOpen')) {
        redoxsvg.transition(_d3Ease.easeCubic).duration(t).attr("height", get(this, 'closedHeight'));
        context.transition(_d3Ease.easeBounce).duration(t).attr("transform", "translate(" + margins.left + "," + 100 + ")");
        set(this, 'openCloseIcon', 'angle-double-down');
        this.toggleProperty('isGraphOpen');
      } else {
        redoxsvg.transition(_d3Ease.easeCubic).duration(t).attr("height", get(this, 'openHeight'));
        context.transition(_d3Ease.easeBounce).duration(t).attr("transform", "translate(" + margins.left + "," + (get(this, 'openHeight') - 40) + ")");
        set(this, 'openCloseIcon', 'angle-double-up');
        Ember.run.later(function () {
          _this7.toggleProperty('isGraphOpen');
        }, t / 2);
      }
    },
    btnZoomGraph: function btnZoomGraph(changeBottom, changeTop) {
      var yScale = get(this, 'yScale');
      var ckScale = get(this, 'ckScale');
      var yAxis = get(this, 'yAxis');
      var ckAxis = get(this, 'ckAxis');
      var redoxFocus = (0, _d3Selection.select)(".redox-focus");

      var currentBottom = yScale.domain()[0];
      var currentTop = yScale.domain()[1];
      var currentCkBottom = ckScale.domain()[0];
      var currentCkTop = ckScale.domain()[1];
      var newBottom = currentBottom + changeBottom;
      var newTop = currentTop + changeTop;
      var newCkBottom = currentCkBottom + changeBottom;
      var newCkTop = currentCkTop + changeTop;
      yScale.domain([newBottom, newTop]);
      ckScale.domain([newCkBottom, newCkTop]);

      redoxFocus.select(".y-axis").call(yAxis);
      redoxFocus.select(".ck-axis").call(ckAxis);
      this.updateDraggedGraph();
    },
    moveGraph: function moveGraph(moveDist) {
      var yScale = get(this, 'yScale');
      var ckScale = get(this, 'ckScale');
      var yAxis = get(this, 'yAxis');
      var ckAxis = get(this, 'ckAxis');

      var redoxFocus = (0, _d3Selection.select)(".redox-focus");

      var currentbottom = yScale.domain()[0];
      var currenttop = yScale.domain()[1];
      var currentCkBottom = ckScale.domain()[0];
      var currentCkTop = ckScale.domain()[1];

      var newbottom = currentbottom + moveDist;
      var newtop = currenttop + moveDist;
      var newCkBottom = currentCkBottom + moveDist;
      var newCkTop = currentCkTop + moveDist;

      yScale.domain([newbottom, newtop]);
      ckScale.domain([newCkBottom, newCkTop]);

      redoxFocus.select("ck-axis").call(ckAxis);
      redoxFocus.select(".y-axis").call(yAxis);
      this.updateDraggedGraph();
    },


    /*--------------------------- Helper Functions -----------------------------*/

    hasRedoxTests: computed('data', function () {
      if (!get(this, 'data') || get(this, 'data.length') === 0) {
        return false;
      }
      return true;
    }),

    showCDTSpinner: function showCDTSpinner() {
      set(this, 'cdtLoading', true);
      this.changeCirclePointer('no-drop');
      $(".d3-tip").html('<i class="cdt-loader fa-spinner fa fa-2x fa-circle-o-notch fa-spin"></i>');
    },
    removeCDTSpinner: function removeCDTSpinner() {
      set(this, 'cdtLoading', false);
      this.changeCirclePointer('pointer');
    },
    changeCirclePointer: function changeCirclePointer(cursorToShow) {
      $('.large-circle-defence').css('cursor', cursorToShow);
    },
    getLargeDefenceColor: function getLargeDefenceColor(d) {
      var color = d.defenceStatus || "";
      color = color.toLowerCase();
      if (color === "green") {
        return "url(#greenbottom)";
      } else if (color === "amber") {
        return "url(#amberbottom)";
      } else if (color === "red") {
        return "url(#redbottom)";
      } else {
        return "url(#undefbottom)";
      }
    },
    getLargeStressColor: function getLargeStressColor(d) {
      var color = d.stressStatus || "";
      color = color.toLowerCase();
      if (color === "green") {
        return "url(#greentop)";
      } else if (color === "amber") {
        return "url(#ambertop)";
      } else if (color === "red") {
        return "url(#redtop)";
      } else {
        return "url(#undeftop)";
      }
    },


    //crp COLOR
    //these two are different - are now the same - remove one

    // getCRPPointColor(d) {
    //   if(d.crp > 3 ){
    //       return '#ef3340';
    //   }else if(d.crp > 1 ){
    //       return '#ff9933';
    //   }else{
    //       return '#41d691';
    //   }
    // },

    getCRPCircleColor: function getCRPCircleColor(d) {
      if (d.crp > 3) {
        return '#ef3340';
      } else if (d.crp > 1) {
        return '#ff9933';
      } else {
        return '#41d691';
      }
    },
    getCKCircleColor: function getCKCircleColor(d) {
      if (d.ck > 3000) {
        return '#a80000';
      } else if (d.ck > 700 || d.ck < 20) {
        return '#ef3340';
      } else if (d.ck > 400) {
        return '#ff9933';
      } else {
        return '#41d691';
      }
    },
    getDefencePointColor: function getDefencePointColor(d) {
      var color = d.defenceStatus || "";
      color = color.toLowerCase();
      if (!d.includeInCdt) {
        return "#c8c9c7";
      }
      if (color === "green") {
        return "#41d691";
      } else if (color === "amber") {
        return "#ff9933";
      } else if (color === "red") {
        return "#ef3340";
      } else {
        return "#c8c9c7";
      }
    },
    getStressPointColor: function getStressPointColor(d) {
      var color = d.stressStatus || "";
      color = color.toLowerCase();
      if (!d.includeInCdt) {
        return "#c8c9c7";
      }
      if (color === "green") {
        return "#41d691";
      } else if (color === "amber") {
        return "#ff9933";
      } else if (color === "red") {
        return "#ef3340";
      } else {
        return "#c8c9c7";
      }
    },
    showToastMsg: function showToastMsg() {
      var toastMessage = get(this, 'toastMessage');
      this.sendAction('showApplicationToast', toastMessage);
    },
    setTooltipText: function setTooltipText() {
      var includeInCdt = get(this, 'latestPoint.includeInCdt');
      if (includeInCdt) {
        set(this, 'tooltipIncExc', 'Exclude');
      } else {
        set(this, 'tooltipIncExc', 'Include');
      }
    },
    setTooltipIncExc: function setTooltipIncExc(includeInCdt) {
      if (includeInCdt) {
        set(this, 'toastMessage', 'Result excluded');
        set(this, 'tooltipIncExc', 'Exclude');
      } else {
        set(this, 'toastMessage', 'Result included');
        set(this, 'tooltipIncExc', 'Include');
      }
    },


    /*---------------------- Destroy Component Functions------------------------*/
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.cancelTimers();
      this.removeEventListeners();
      $('.d3-tip').remove(); //when using side scroll - could remain on page transitioned to
    },
    removeEventListeners: function removeEventListeners() {
      $('#inc-exc-btn').off('.redoxNamespace');
      $('.d3-tip').off('.redoxNamespace');
      $(window).off('.redoxNamespace');
    },
    cancelTimers: function cancelTimers() {
      run.cancel(get(this, 'timeoutTimer'));
    }
  });
});