define('coach-central/routes/dashboard/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    currentUser: service(),

    redirect: function redirect() {
      this._super.apply(this, arguments);
      this.transitionTo('dashboard.team', get(this, 'currentUser.user.currentTeam'));
    }
  });
});