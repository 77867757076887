define('coach-central/controllers/admin/users/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;
  exports.default = Controller.extend({

    currentUser: service(),

    isSaving: false,

    // isMe: computed('model.user.id',function() {
    //   return get(this, 'model.user.id') === get(this, 'currentUser.user.id');
    // }),

    actions: {
      submit: function submit(changeset) {
        var _this = this;

        // if this user's current team is not included in his selected Teams
        // then we need to make one of the selected teams the current team.
        // If its the logged in user changing themselves

        // let teams = get(this,'model.user').hasMany('teams').ids();
        // console.log('teams',teams);
        // let currentTeam = get(this,'model.user').belongsTo('currentTeam').id();
        // console.log('team',currentTeam);

        return changeset.get('teams').then(function (teams) {
          //changeset.get('currentTeam').then(team =>{
          //let isCurrentTeamIncluded = teams.includes(team);
          // if (!isCurrentTeamIncluded) {
          //   if (get(this,'isMe')) {
          //     this.send('showApplicationToast', 'You cannot remove yourself from your current team.');
          //     return true;
          //   }
          //   set(changeset, 'currentTeam', changeset.get('teams.firstObject'));
          // }

          // remove any teams not associated with current client
          var clientTeams = get(_this, 'model.teams');
          var filteredTeams = teams.filter(function (team) {
            return clientTeams.includes(team) ? true : false;
          });
          set(changeset, 'teams', filteredTeams);
          set(changeset, 'currentTeam', null);
          set(_this, 'isSaving', true);

          return changeset.save().then(function () {
            set(_this, 'isSaving', false);
            _this.send('showApplicationToast', 'Update successful');
            _this.transitionToRoute('admin.users.index');
          }).catch(function () {
            set(_this, 'isSaving', false);
            get(_this, 'model.user.errors').forEach(function (_ref) {
              var attribute = _ref.attribute,
                  message = _ref.message;

              changeset.pushErrors(attribute, message);
            });
          });
          //});
        });
      },
      rollback: function rollback(changeset) {
        changeset.rollback();
        this.send('showApplicationToast', "Changes since last save have been undone.");
        return true;
      },
      cancel: function cancel() /*model*/{
        this.send('showApplicationToast', "Edit has been cancelled.");
        this.transitionToRoute('admin.users.index');
      }
    }
  });
});