define('coach-central/routes/admin/users/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      Route = Ember.Route,
      service = Ember.inject.service;
  exports.default = Route.extend({

    currentUser: service(),

    beforeModel: function beforeModel(transition) {
      if (!get(this, 'currentUser.isUserAdmin')) {
        transition.abort();
      }
    },
    model: function model() {
      var _this = this;

      //let store = get(this, 'store');
      return get(this, 'currentUser.user.currentTeam.client').then(function (client) {
        // previously we just did return get(client,'teams'); here
        // but now that we are looking up teams via client, a newly created team
        // is not found in list because it has no reference back to client
        // so I am hard reloading everything
        return get(_this, 'store').findRecord('client', client.id, { include: 'teams' }).then(function (client) {
          return get(client, 'teams').then(function () {
            return get(_this, 'currentUser.user.currentTeam.users').then(function (users) {
              return users.sortBy('firstName', 'lastName');
            });
          });
        });
      });
    }
  });
});