define('coach-central/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      RSVP = Ember.RSVP;
  exports.default = Ember.Service.extend({
    session: service(),
    store: service(),
    user: null,
    teamGroup: 'All',
    showOSi: false,
    showCRP: false,
    showCK: false,
    showRedox: true,
    showThreshold: true,

    load: function load() {
      var _this = this;

      return new RSVP.Promise(function (resolve, reject) {
        var userId = get(_this, 'session.data.authenticated.user_id');
        if (!isEmpty(userId)) {
          var role = get(_this, 'session.data.authenticated.role') || 'none';
          var isClientAdmin = role === "ADMIN" || role === "PERFORMANCE" || role === "SUPER" ? true : false;

          // break down admin into lower granularity
          var isUserAdmin = isClientAdmin && (role === "ADMIN" || role === "SUPER") ? true : false;
          var isTeamAdmin = isClientAdmin && (role === "ADMIN" || role === "SUPER") ? true : false;
          set(_this, 'isUserAdmin', isUserAdmin);
          set(_this, 'isTeamAdmin', isTeamAdmin);
          set(_this, 'role', role);
          set(_this, 'isClientAdmin', isClientAdmin);

          get(_this, 'store').findRecord('user', userId, { include: 'current-team,teams', reload: true }).then(function (user) {
            set(_this, 'user', user);
            resolve(user);
          }, reject);
        } else {
          resolve();
        }
      });
    }
  });
});