define('coach-central/routes/dashboard/team/athlete/redox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service,
      Route = Ember.Route;
  exports.default = Route.extend({
    currentUser: service(),

    model: function model() {
      return this.modelFor('dashboard.team.athlete').get('redoxTests');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var sortedModel = model.sortBy('date').slice().reverse();
      var lastTest = sortedModel.get('firstObject');

      var latestRedoxTest = sortedModel.find(function (d) {
        return d.get('stress') || d.get('defence') || d.get('redoxHealthSurvey.isComplete');
      });
      var latestCrpTest = sortedModel.find(function (d) {
        return d.get('crp');
      });
      var latestCkTest = sortedModel.find(function (d) {
        return d.get('ck');
      });

      //should just do it once in model??
      var athlete = this.modelFor('dashboard.team.athlete');
      controller.setProperties({
        'currentTest': lastTest,
        'fullName': athlete.get('fullName'),
        'latestRedoxResult': latestRedoxTest,
        'latestCrpResult': latestCrpTest,
        'latestCkResult': latestCkTest,
        'updateViaDateBtnBool': false
      });
    },


    // only redirect if trying to get to the redox route
    redirect: function redirect(model, transition) {
      var currentUser = get(this, 'currentUser');
      var showRedox = currentUser.get('showRedox');
      var showCRP = currentUser.get('showCRP');
      var showCK = currentUser.get('showCK');

      //this is from clicking through comment - toggle on correct view
      if (transition.queryParams.test_id) {
        var _redoxTest = get(this, 'store').peekRecord('redox-test', transition.queryParams.test_id);
        if (_redoxTest.get('stress') || _redoxTest.get('defence')) {
          set(this, 'currentUser.showRedox', true);
        }
        if (_redoxTest.get('crp')) {
          set(this, 'currentUser.showCRP', true);
        }
        if (_redoxTest.get('ck')) {
          set(this, 'currentUser.showCK', true);
        }
        this.transitionTo('dashboard.team.athlete.redox.test', transition.queryParams.test_id);
        return;
      }

      var athleteHasRedoxTests = model.length === 0 ? false : true;

      //make sure this is right place to handle this
      //additional logic to handle whether redox or crp are toggled on
      //if no redox and is crp show default to crp view
      var sortedModel = model.sortBy('date').reverse();
      var redoxTest = void 0,
          redoxTestId = void 0;
      if (showRedox && !showCRP) {
        redoxTest = sortedModel.find(function (d) {
          return d.get('stress') || d.get('defence') || d.get('redoxHealthSurvey.isComplete');
        });
        if (redoxTest) {
          redoxTestId = redoxTest.get('id');
        } else {
          athleteHasRedoxTests = false;
        }
      } else if (!showRedox && showCRP) {
        redoxTest = sortedModel.find(function (d) {
          return d.get('crp');
        });
        if (redoxTest) {
          redoxTestId = redoxTest.get('id');
        } else {
          athleteHasRedoxTests = false;
        }
      } else if (!showRedox && !showCRP) {
        athleteHasRedoxTests = false;
      } else {
        redoxTest = sortedModel.find(function (d) {
          return d.get('stress') || d.get('defence') || d.get('crp') || d.get('redoxHealthSurvey.isComplete');
        });
        if (redoxTest) {
          redoxTestId = redoxTest.get('id');
        } else {
          athleteHasRedoxTests = false;
        }
      }

      if (athleteHasRedoxTests) {
        this.transitionTo('dashboard.team.athlete.redox.test', redoxTestId);
      } else {
        this.transitionTo('dashboard.team.athlete.redox.no-tests');
      }
    }
  });
});