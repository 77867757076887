define('coach-central/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany,
      belongsTo = _emberData.default.belongsTo,
      Model = _emberData.default.Model;
  var computed = Ember.computed;
  exports.default = Model.extend({
    //ATTRS
    firstName: attr('string'),
    lastName: attr('string'),
    displayName: attr('string'),
    email: attr('string'),
    secondaryEmail: attr('string'), // currently unused
    avatar: attr('string'),
    isVendor: attr('boolean'),
    currentClient: attr('string'),

    //RELATIONSHIPS
    userSettings: belongsTo('userSetting'),
    comments: hasMany('comment'),
    role: belongsTo('role'),
    teams: hasMany('team', { inverse: 'users' }),
    currentTeam: belongsTo('team'),

    //COMPUTED
    fullName: computed('firstName', 'lastName', function () {
      var firstName = this.get('firstName');
      var lastName = this.get('lastName');

      return firstName + ' ' + lastName;
    })
  });
});