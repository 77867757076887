define('coach-central/mirage/factories/redox-risk-group', ['exports', 'ember-cli-mirage', 'coach-central/mirage/utils'], function (exports, _emberCliMirage, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.Factory.extend({
    name: function name(i) {
      var names = ['Priority', 'Monitor', 'Good profile', 'Untested'];
      return names[i];
    },
    priority: function priority(i) {
      return i + 1;
    },
    athleteCount: function athleteCount() {
      return (0, _utils.randomInt)(2, 3);
    }
  });
});