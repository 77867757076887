define("coach-central/validators/slider-value", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = validateSliderValue;
  function validateSliderValue() /* options = {} */{
    return function () /*key, newValue, oldValue, changes, content*/{
      return true;
    };
  }
});