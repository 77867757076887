define('coach-central/components/summary-card', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = Ember.Component.extend({
    selectedBasicTab: 0, // tab-index of nutritional advice

    selectedInfo: computed('summaryInfo', function () {
      var summaryInfo = get(this, 'summaryInfo');
      return summaryInfo[0];
    }),

    summaryInfo: computed('crpResults', 'ckResults', function () {
      var ckResults = get(this, 'ckResults');
      var crpResults = get(this, 'crpResults');
      var ckInfo = ckResults ? ckResults : "No summary available";
      var crpInfo = crpResults ? crpResults : "No summary available";
      var redoxInfo = [{ title: "Muscle Damage", info: ckInfo }, { title: "Muscle Inflammation", info: crpInfo }];

      return redoxInfo;
    }),

    hasCrp: computed('model', function () {
      var model = get(this, 'model');
      return model.get('crp');
    }),

    hasCk: computed('model', function () {
      var model = get(this, 'model');
      return model.get('ck');
    }),

    crpResults: computed('hasCrp', function () {
      var model = get(this, 'model');
      var crpStatus = model.get('crpStatus');
      if (crpStatus === 'GREEN') {
        return ['Inflammation is low or absent'];
      } else if (crpStatus === 'AMBER') {
        return ['Inflammation is moderate'];
      } else if (crpStatus === 'RED') {
        return ['Evidence of increased inflammation'];
      }
    }),

    ckResults: computed('hasCrp', function () {
      var model = get(this, 'model');
      var ckStatus = model.get('ckStatus');
      if (ckStatus === 'GREEN') {
        return ['CK value is normal. This suggests muscle damage is mild or absent.'];
      } else if (ckStatus === 'AMBER') {
        return ['Evidence of some moderate muscle damage. This is to be expected in athletes, particularly following eccentric muscle loading, contact and/or trauma. Cross reference with past CK data.'];
      } else if (ckStatus === 'RED+') {
        return ['CK values above 3,000 U/L are unusual and a clinical review is recommended. Evidence of significant muscle damage, likely caused by eccentric muscle loading, contact and/or trauma. Prioritize recovery to reduce CK. '];
      } else if (ckStatus === 'RED') {
        return ['Evidence of significant muscle damage, likely caused by eccentric muscle loading, contact and/or trauma. Prioritize recovery to reduce CK. Cross reference with past CK data. Consider a clinical review.'];
      }
    })
  });
});