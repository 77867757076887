define('coach-central/controllers/error', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller,
      service = Ember.inject.service,
      computed = Ember.computed;
  exports.default = Controller.extend({
    memoryStorage: service(), //inject a storage service
    /*
     * These computed properties will help to handle a special case,
     * when there is no error status and message (usually when internet
     * connection is gone)
     */
    status: computed('model', function () {
      if (this.model !== undefined && this.model.errors !== undefined && this.model.errors.length > 0 && this.model.errors[0].status !== undefined) {
        return this.model.errors[0].status;
      } else {
        return 0;
      }
    }),
    message: computed('model', function () {
      if (this.model !== undefined && this.model.errors !== undefined && this.model.errors.length > 0) {
        for (var i = 0; i < this.model.errors.length; i++) {
          if (this.model.errors[i].detail !== undefined && this.model.errors[i].detail !== '') {
            return this.model.errors[i].detail;
          }
        }
      }
      return undefined;
    }),

    actions: {
      retry: function retry() {
        //get a failed transition from storage
        var transition = this.get('memoryStorage').getAndRemove('failedTransition');
        if (transition !== undefined) {
          transition.retry(); //retry transition
        }
      }
    }
  });
});