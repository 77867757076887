define('coach-central/routes/dashboard/team/athlete/gps', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('athlete').athlete;
    }
  });
});