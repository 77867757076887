define('coach-central/validations/health-survey', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    energyLevel: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    muscleSoreness: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({ gt: 0 })],
    eaten: [(0, _validators.validatePresence)(true)],
    exercised: [(0, _validators.validatePresence)(true)]
  };
});