define('coach-central/routes/admin/teams/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route,
      get = Ember.get,
      RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var store = get(this, 'store');

      // used to allow requred attribute in template to work for select box
      var sport = store.createRecord('sport');

      return RSVP.hash({
        team: store.createRecord('team', { sport: sport }),
        sports: store.findAll('sport')
      });
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (this.controller.get('model.team.isNew') && !confirm('Are you sure you want to abandon progress?')) {
          transition.abort();
        } else {
          if (this.controller.get('model.team.isNew') && !this.controller.get('model.team.isSaving')) {
            this.controller.get('model.team').deleteRecord();
          }
          // Bubble the `willTransition` action so that
          // parent routes can decide whether or not to abort.
          return true;
        }
      }
    }
  });
});